import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from 'lodash';
const initialState = {
  storeOrderList: [],
  singleOrderDetails: {},
  pageNoStoreOrder: 0,
  hasMoreStoreOrder: true,
};

export const storeOrderSlice = createSlice({
  name: "storeOrder",
  initialState,
  reducers: {
    getStoreOrder() { },
    setStoreOrdersList(state, action) {
      return Object.assign({}, state, {
        storeOrderList:
          state.pageNoStoreOrder > 0
            ? [...state.storeOrderList, ...action.payload]
            : isEmpty(action.payload)
              ? []
              : action.payload,
        hasMoreStoreOrder: action.payload.length < 15 ? false : true,
      });
    },
    getStoreOrderDetails() { },
    setStoreOrderDetails(state, action) {
      return Object.assign({}, state, {
        singleOrderDetails: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setPagenoStoreOrder(state, action) {
      return Object.assign({}, state, {
        pageNoStoreOrder: action.payload,
      });
    }
  },
});

export const {
  getStoreOrder,
  setStoreOrdersList,
  getStoreOrderDetails,
  setStoreOrderDetails,
  setPagenoStoreOrder
} = storeOrderSlice.actions;

export default storeOrderSlice.reducer;
