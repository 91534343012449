import React, { useEffect, useState } from "react";
import {
  ContinueButton,
  GeneralDataModal,
  HeadingTypography,
  ListTable,
  SubLabelTypography,
} from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./styles";
import NewCoupons from "./NewCoupons";
import { useDispatch, useSelector } from "react-redux";
import {
  getCouponsList,
  setPagenoCoupons,
} from "../../app/reducers/couponSlice";
import moment from "moment";

function Coupons(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const couponsList = useSelector((state) => state.couponReducer.couponsList);
  const couponSaved = useSelector((state) => state.couponReducer.couponSaved);
  const pageNo = useSelector((state) => state.couponReducer.pageNoCoupons);
  const hasMore = useSelector((state) => state.couponReducer.hasMoreCoupons);

  const [generalDialog, setGeneralDialog] = useState(false);
  const [newCoupon, setNewCoupon] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [couponsList]);

  useEffect(() => {
    dispatch(getCouponsList(0));
  }, [couponSaved]);

  const handleNewCoupon = () => {
    setNewCoupon(true);
  };

  const handleCouponsDialogClose = () => {
    setNewCoupon(false);
  };
  const handleViewClick = (e, data) => {
    setSelectedCoupon(data.data);
    setGeneralDialog(true);
  };
  const handleCloseGeneralDialog = () => {
    setGeneralDialog(false);
  };

  let tableData = [];

  couponsList?.map((coupon) => {
    const couponData = {
      coupon: coupon?.couponCode,
      discount: coupon?.discountType,
      discountAmount: coupon?.discountValue,
      orderAmount: coupon?.minOrderAmount + " to " + coupon?.maxOrderAmount,
      validityRange:
        moment(coupon?.startDate).format("ll") +
        " to " +
        moment(coupon?.endDate).format("ll"),
      data: coupon,
    };
    tableData.push(couponData);
    return couponData;
  });

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoCoupons(pageNo + 1));
    dispatch(getCouponsList(pageNo + 1));
  };

  const BodyComp = () => {
    return (
      <div style={{ width: "320px" }}>
        <div
          className={classes.alignStraightBetween}
          style={{ marginBottom: "12px" }}
        >
          <HeadingTypography>Coupon Details</HeadingTypography>
          <div style={{ cursor: "pointer" }} onClick={handleCloseGeneralDialog}>
            <CloseIcon sx={{ fontSize: "12px", color: "#827e7e" }} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <HeadingTypography
            weight={500}
            size={"14px"}
            sx={{ marginRight: "15px" }}
          >
            Coupon Code
          </HeadingTypography>
          <div style={{ marginRight: "15px" }}> - </div>
          <SubLabelTypography sx={{ marginRight: "15px" }}>
            {selectedCoupon?.couponCode}
          </SubLabelTypography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <HeadingTypography
            weight={500}
            size={"14px"}
            sx={{ marginRight: "15px" }}
          >
            Discount Type
          </HeadingTypography>
          <div style={{ marginRight: "15px" }}> - </div>
          <SubLabelTypography sx={{ marginRight: "15px" }}>
            {selectedCoupon?.discountType}
          </SubLabelTypography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <HeadingTypography
            weight={500}
            size={"14px"}
            sx={{ marginRight: "15px" }}
          >
            Discount Value
          </HeadingTypography>
          <div style={{ marginRight: "15px" }}> - </div>
          <SubLabelTypography sx={{ marginRight: "15px" }}>
            {selectedCoupon?.discountValue}
          </SubLabelTypography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <HeadingTypography
            weight={500}
            size={"14px"}
            sx={{ marginRight: "15px" }}
          >
            Order Amount(&#8377;)
          </HeadingTypography>
          <div style={{ marginRight: "15px" }}> - </div>
          <SubLabelTypography sx={{ marginRight: "15px" }}>
            {selectedCoupon?.minOrderAmount +
              "to" +
              selectedCoupon?.maxOrderAmount}
          </SubLabelTypography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <HeadingTypography
            weight={500}
            size={"14px"}
            sx={{ marginRight: "15px" }}
          >
            ValidityRange
          </HeadingTypography>
          <div style={{ marginRight: "15px" }}> - </div>
          <SubLabelTypography sx={{ marginRight: "15px" }}>
            {moment(selectedCoupon?.startDate).format("ll") +
              " to " +
              moment(selectedCoupon?.endDate).format("ll")}
          </SubLabelTypography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <HeadingTypography
            weight={500}
            size={"14px"}
            sx={{ marginRight: "15px" }}
          >
            Description
          </HeadingTypography>
          <div style={{ marginRight: "15px" }}> - </div>
          <SubLabelTypography sx={{ marginRight: "15px" }}>
            {selectedCoupon?.description}
          </SubLabelTypography>
        </div>
      </div>
    );
  };
  return (
    <div className={classes.root}>
      <div
        className={classes.alignStraightBetween}
        style={{ marginBottom: "4px" }}
      >
        <HeadingTypography>Coupons</HeadingTypography>
        <div style={{ padding: "0px 0px 5px 0px" }}>
          <ContinueButton onClick={handleNewCoupon}>New Coupons</ContinueButton>
        </div>
      </div>
      <ListTable
        dataList={tableData}
        handleViewClick={handleViewClick}
        isLoading={loading}
        handleLoadmore={handleLoadmore}
        hasMore={hasMore}
        // handleUpdateStatus={handleUpdateStatus}
        Header={[
          "Coupon Code",
          "Discount Type",
          "Discount Value",
          "Order Amount Range",
          "Validity Date Range",
          "",
        ]}
        tableData={[
          { type: ["TEXT"], name: "coupon" },
          { type: ["TEXT"], name: "discount" },
          { type: ["TEXT"], name: "discountAmount" },
          { type: ["TEXT"], name: "orderAmount" },
          { type: ["TEXT"], name: "validityRange" },
          { type: ["COUPONS"], name: "" },
        ]}
      />
      <GeneralDataModal
        generalDialog={generalDialog}
        paperWidth={classes.paperWidth}
        bodyComp={<BodyComp />}
      />
      <GeneralDataModal
        generalDialog={newCoupon}
        paperWidth={classes.paperWidth}
        bodyComp={
          <NewCoupons handleCouponsDialogClose={handleCouponsDialogClose} />
        }
      />
    </div>
  );
}

export default Coupons;
