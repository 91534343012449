import React, { useEffect, useState } from "react";
import {
  ContinueButton,
  GeneralDataModal,
  ListTable,
  HeadingTypography,
} from "../../../components";
import { useStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import NewEmployee from "./NewEmployee";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeList,
  getStoreEmployeeDetails,
  setPagenoEmployee,
  clearStoreEmployee,
} from "../../../app/reducers/employeeSlice";
import _ from "lodash";
import ViewEmployee from "./ViewEmployee";

const Employees = (props) => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();

  const employeeList = useSelector(
    (state) => state.employeeReducer.employeeList
  );
  const employeeStoreDetails = useSelector(
    (state) => state.employeeReducer.employeeStoreDetails
  );
  const employeeSaved = useSelector(
    (state) => state.employeeReducer.employeeSaved
  );
  const employeeEdited = useSelector(
    (state) => state.employeeReducer.employeeEdited
  );
  const pageNo = useSelector((state) => state.employeeReducer.pageNoEmployee);
  const hasMore = useSelector((state) => state.employeeReducer.hasMoreEmployee);

  const [generalDialog, setGeneralDialog] = useState(false);
  const [openNewEmployee, setOpenNewEmployee] = useState(false);
  const [viewEmployee, setViewEmployee] = useState(false);
  const [rowData, setRowData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [employeeList]);

  useEffect(() => {
    if (!_.isEmpty(employeeSaved)) {
      dispatch(clearStoreEmployee());
      dispatch(getEmployeeList(0));
    }
  }, [employeeSaved]);

  useEffect(() => {
    if (!_.isEmpty(employeeEdited)) {
      dispatch(clearStoreEmployee());
      dispatch(getEmployeeList(0));
    }
  }, [employeeEdited]);

  useEffect(() => {
    dispatch(clearStoreEmployee());
    dispatch(getEmployeeList(0));
  }, []);

  const handleNewPartner = () => {
    setOpenNewEmployee(true);
  };

  const handleBack = () => {
    setOpenNewEmployee(false);
    setViewEmployee(false);
  };

  const handlePartnerStore = (e, data) => {
    const obj = {
      id: data.data?._id,
      type: data.data?.userType,
    };
    dispatch(getStoreEmployeeDetails(obj));
    setGeneralDialog(true);
  };

  const handleCloseGeneralDialog = () => {
    setGeneralDialog(false);
  };

  const handleStatusClick = (e, data) => {
    setViewEmployee(true);
    setRowData(data.data);
  };

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoEmployee(pageNo + 1));
    dispatch(getEmployeeList(pageNo + 1));
  };

  let tableData = [];

  employeeList?.map((employee) => {
    const employeeData = {
      id: employee?._id,
      name: employee?.name,
      mobile: employee?.phoneNumber,
      email: employee?.email,
      role: employee?.userType,
      data: employee,
    };
    tableData.push(employeeData);
    return employeeData;
  });

  let secondaryTable = [];

  const data = _.isEmpty(employeeStoreDetails)
    ? []
    : employeeStoreDetails?.map((data) => {
        const esDetails = {
          id: data?.storeId,
          name: data?.name,
          district: data?.district,
          city: data?.city,
          pincode: data?.pincode,
        };
        secondaryTable.push(esDetails);
        return esDetails;
      });

  const BodyComp = () => {
    return (
      <div style={{ width: "600px" }}>
        <div
          className={classes.alignStraightBetween}
          style={{ marginBottom: "12px" }}
        >
          <HeadingTypography>Stores</HeadingTypography>
          <div style={{ cursor: "pointer" }} onClick={handleCloseGeneralDialog}>
            <CloseIcon sx={{ fontSize: "12px", color: "#827e7e" }} />
          </div>
        </div>
        <ListTable
          dataList={secondaryTable}
          Header={["ID", "Name", "District", "City/Town/Village", "Pincode"]}
          tableData={[
            { type: ["TEXT"], name: "id" },
            { type: ["TEXT"], name: "name" },
            { type: ["TEXT"], name: "district" },
            { type: ["TEXT"], name: "city" },
            { type: ["TEXT"], name: "pincode" },
          ]}
        />
      </div>
    );
  };
  return (
    <div className={classes.root}>
      {openNewEmployee ? (
        <NewEmployee handleBack={handleBack} />
      ) : viewEmployee ? (
        <ViewEmployee handleBack={handleBack} row={rowData} />
      ) : (
        <>
          <>
            <div
              className={classes.alignStraightBetween}
              style={{ marginBottom: "4px" }}
            >
              <HeadingTypography>Employees</HeadingTypography>
              <div style={{ padding: "0px 0px 5px 0px" }}>
                <ContinueButton onClick={handleNewPartner}>
                  New Employee
                </ContinueButton>
              </div>
            </div>
            <ListTable
              dataList={tableData}
              handlePartnerStore={handlePartnerStore}
              handleStatusClick={handleStatusClick}
              isLoading={loading}
              handleLoadmore={handleLoadmore}
              hasMore={hasMore}
              Header={[
                "ID",
                "Name",
                "Mobile Number",
                "Email ID",
                "Role",
                "",
                "",
              ]}
              tableData={[
                { type: ["TEXT"], name: "id" },
                { type: ["TEXT"], name: "name" },
                { type: ["TEXT"], name: "mobile" },
                { type: ["TEXT"], name: "email" },
                { type: ["TEXT"], name: "role" },
                { type: ["PARTNER_STORE"], name: "" },
                { type: ["VIEW_NEW"], name: "" },
              ]}
            />
          </>
          <GeneralDataModal
            generalDialog={generalDialog}
            paperWidth={classes.paperWidth}
            bodyComp={<BodyComp />}
          />
        </>
      )}
    </div>
  );
};

export default Employees;
