import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { RoutePath } from "./routes";
import { NotFound } from "../features/notFound";
import {
  Stores,
  NewStoreStepper,
  Partners,
  NewPartners,
  Employees,
  Vendors,
  NewVendors,
  Indents,
  StorePartner,
  BusinessPartner,
  Coupons,
  PosTerminal,
  CashDeposits,
  AdminLogin,
  StoreOrders,
  StoreOrderDetails,
  Upi
} from "../features";
import { withDrawer } from "../common/hocs";
import { B2binvoice } from "../features/Invoices";
import Invoiceorder from "../features/Invoices/b2b Invoices/invoiceorder";

const RouterApp = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <Routes>
      <Route exact path={RoutePath.stores} element={<Stores />} />
      <Route exact path={RoutePath.newStore} element={<NewStoreStepper />} />
      <Route exact path={RoutePath.partners} element={<Partners />} />
      <Route exact path={RoutePath.employees} element={<Employees />} />
      <Route exact path={RoutePath.login} element={<AdminLogin />} />
      <Route exact path={RoutePath.vendors} element={<Vendors />} />
      <Route exact path={RoutePath.newVendors} element={<NewVendors />} />
      <Route exact path={RoutePath.indents} element={<Indents />} />
      <Route exact path={RoutePath.storePartners} element={<StorePartner />} />
      <Route
        exact
        path={RoutePath.businessPartners}
        element={<BusinessPartner />}
      />
      <Route exact path={RoutePath.upi} element={<Upi/>}/>
      <Route exact path={RoutePath.coupons} element={<Coupons />} />
      <Route exact path={RoutePath.posTerminal} element={<PosTerminal />} />
      <Route exact path={RoutePath.cashDeposits} element={<CashDeposits />} />
      <Route exact path={RoutePath.storeOrder} element={<StoreOrders />} />
      <Route exact path={RoutePath.storeOrderDetails} element={<StoreOrderDetails />} />
      <Route path={RoutePath.err} element={<NotFound />} />
      <Route path={RoutePath.b2binvoice} element={<B2binvoice/>}/>
      <Route path={RoutePath.invoiceorder} element={< Invoiceorder/>} />
    </Routes>
  )
};

export default withDrawer(RouterApp);
