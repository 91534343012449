import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
    setStorePartnerOrders,
    getPayStorePartner,
    setPayStorePartner,
    getStorePartnerList,
    setStorePartnerList
} from "../reducers/storePartnerSlice";

async function fetchStorePartnerListAPI(payload) {
  const userData = await axios
    .get("/client/partner/storepartnerpaymentlist", {
      params: {
        limit: 15,
        page: payload,
        filter: {},
        sort: {
          paid: 1,
          orderDate: -1
        },
        search: ""
      }
    })
    .then((response) => response.data.data);
  return userData;
}

export function* fetchStorePartnerList(action) {
  try {
    const response = yield call(fetchStorePartnerListAPI, action.payload);
    yield put(setStorePartnerList(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function payStorePartnerAPI(payload) {
    const userData = await axios
      .post("/client/partner/paystorepartner", payload)
      .then((response) => response);
    return userData;
  }

  export function* payStorePartner(action) {
    try {
      const { payload } = action;
      const response = yield call(payStorePartnerAPI, payload);
      yield put(setPayStorePartner(response));
    } catch (error) {
      yield put({ type: "ERRORS", payload: error });
    }
  }

async function fetchStorePartnerOrdersAPI(payload) {
    const userData = await axios
      .get("/client/intends/fetchintenddetails", {
        params: {
          intendId: payload
        }
      })
      .then((response) => response.data.data);
    return userData;
  }
  
  export function* fetchStorePartnerOrders(action) {
    try {
      const response = yield call(fetchStorePartnerOrdersAPI, action.payload);
      yield put(setStorePartnerOrders(response));
    } catch (error) {
      yield put({ type: "ERRORS", payload: error });
    }
  }

export function* storePartnerSaga() {
  yield takeLatest(getStorePartnerList.type, fetchStorePartnerList);
  yield takeLatest(getPayStorePartner.type, payStorePartner);
}
