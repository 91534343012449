import React, { useState, useEffect } from "react";
import {
  HeadingTypography,
  ListTable,
  ContinueButton,
  ContinueShoppingButton,
} from "../../../components";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getCashDeposit,
  getConfirmPaymentCash,
  setPagenoCash,
  clearCash
} from "../../../app/reducers/cashDepositSlice";
import moment from "moment";
import { Dialog, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";

const dateArray = ["Mar 24", "Apr 3", "May 27"];
const storeArray = ["922R", "942R", "925R"];

function CashDeposits(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const cashDepositList = useSelector(
    (state) => state.cashDepositReducer.cashDepositList
  );
  const confirmPaymentCash = useSelector(
    (state) => state.cashDepositReducer.confirmPaymentCash
  );
  const pageNo = useSelector((state) => state.cashDepositReducer.pageNoCash);
  const hasMore = useSelector((state) => state.cashDepositReducer.hasMoreCash);

  const [selected, setSelected] = useState({
    store: "922R",
    date: "Mar 24",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [dialogData, setDialogData] = useState("");

  useEffect(() => {
    setLoading(false);
  }, [cashDepositList]);

  useEffect(() => {    
    dispatch(clearCash());
    dispatch(getCashDeposit(0));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(confirmPaymentCash)) {
      setOpenPaymentDialog(false);
      dispatch(clearCash());
      dispatch(getCashDeposit(0));
    }
  }, [confirmPaymentCash]);

  const handleSelected = (val, type) => {
    setSelected({ ...selected, [type]: val });
  };
  const handleViewClick = (e, data) => {
    setOpenPaymentDialog(true);
    setDialogData(data.data);
  };

  const handleSubmit = () => {
    const obj = {
      _id: dialogData?._id,
    };
    dispatch(getConfirmPaymentCash(obj));
  };

  const handleViewReceipt = (e, data) => {
    setOpenDialog(true);
    setImgUrl(data?.data?.receiptImage);
  };

  let tableData = [];

  cashDepositList?.map((cash) => {
    const cashData = {
      storeId: cash?.storeId,
      date: moment(cash?.depositDate).format("ll"),
      transactionDate: moment(cash?.depositDate).format("ll"),
      expectedAmount: Number(cash?.amount).toFixed(2),
      depositAmount: Number(cash?.amount).toFixed(2),
      pendingAmount: Number(cash?.amount).toFixed(2),
      payment: cash?.confirmed ? "Confirmed" : "Confirm Payment",
      data: cash,
    };
    tableData.push(cashData);
    return cashData;
  });

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoCash(pageNo + 1));
    dispatch(getCashDeposit(pageNo + 1));
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.alignStraightBetween}
        style={{ alignItems: "flex-start" }}
      >
        <HeadingTypography sx={{ mb: "2%" }}>Cash Deposits</HeadingTypography>
        <div
          className={classes.alignStraight}
          style={{ alignItems: "flex-start" }}
        >
        </div>
      </div>
      <ListTable
        dataList={tableData}
        handleViewClick={handleViewClick}
        handleViewReceipt={handleViewReceipt}
        isLoading={loading}
        handleLoadmore={handleLoadmore}
        hasMore={hasMore}
        Header={[
          "Store ID",
          "Date",
          "Transaction date",
          "Expected Amount",
          "Deposit Amount",
          "Pending Amount",
          "",
          "",
        ]}
        tableData={[
          { type: ["TEXT"], name: "storeId" },
          { type: ["TEXT"], name: "date" },
          { type: ["TEXT"], name: "transactionDate" },
          { type: ["TEXT"], name: "expectedAmount" },
          { type: ["TEXT"], name: "depositAmount" },
          { type: ["TEXT"], name: "pendingAmount" },
          { type: ["VIEW_RECEIPTS"], name: "" },
          { type: ["PAYMENT"], name: "payment" },
        ]}
      />

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <img src={imgUrl} alt="something wrong" />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openPaymentDialog}
        onClose={() => setOpenPaymentDialog(false)}
      >
        <DialogContent>
          <div style={{ width: "350px", height: "180px" }}>
            <div
              className={classes.alignStraightBetween}
              style={{ marginBottom: "12px" }}
            >
              <HeadingTypography weight={"600"}>
                Confirm Payment
              </HeadingTypography>
              <div
                style={{ cursor: "pointer" }}
                onClick={props?.handleCloseUpdate}
              >
                <CloseIcon sx={{ fontSize: "12px", color: "#827e7e" }} onClick={() => setOpenPaymentDialog(false)} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <HeadingTypography
                weight={"500"}
                size={"13px"}
                sx={{ marginBottom: "10px", marginRight: "5px" }}
              >
                DEPOSIT AMOUNT:
              </HeadingTypography>
              <HeadingTypography
                weight={"700"}
                size={"13px"}
                sx={{ marginBottom: "10px" }}
              >
                {Number(dialogData?.amount).toFixed(2)}
              </HeadingTypography>
            </div>

            <HeadingTypography
              weight={"500"}
              size={"13px"}
              sx={{ marginBottom: "20px" }}
            >
              Full Amount Recieved Successfully ?{" "}
            </HeadingTypography>
            <ContinueShoppingButton
              margin={"0px"}
              onClick={() => setOpenPaymentDialog(false)}
            >
              Cancel
            </ContinueShoppingButton>
            <ContinueButton onClick={handleSubmit}>
              Submit
            </ContinueButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CashDeposits;
