import { createSlice } from "@reduxjs/toolkit"
import { isEmpty } from "lodash";
const initialState = {
    confirmPayment: {},
   upiList: [],
    pageNoUpi: 0,
    hasMoreUpi: true,
}

export const upiSlice = createSlice({
    name: "Upi",
    initialState,
    reducers: {
        getUpi() { },
        setUpi(state, action) {
            return Object.assign({}, state, {
                upiList:
                    state.pageNoUpi > 0
                        ? [...state.upiList, ...action.payload]
                        : isEmpty(action.payload)
                            ? []
                            : action.payload,
                hasMoreUpi: action.payload.length < 15 ? false : true,
            })
        },
        setPagenoUpi(state, action) {
            return Object.assign({}, state, {
                pageNoUpi: action.payload.pageNo,
            });
        },
        setConfirmPaymentUpi(state, action) {
            return Object.assign({}, state, {
                confirmPayment: isEmpty(action.payload) ? {} : action.payload,
            });
        },
        getConfirmPaymentUpi() { },
        clearUpi(state, action) { 
            return Object.assign({}, state, initialState);
        },
    }

});
export const {
    getUpi,
    setUpi,
    getConfirmPaymentUpi,
    setConfirmPaymentUpi,
    clearUpi,
    setPagenoUpi
  } = upiSlice.actions;

export default upiSlice.reducer;