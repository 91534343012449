import React from "react";
import {
  TableCell,
  TextField,
  Typography,
  Checkbox,
  //Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import { CommentIcon } from "../svg/CommentIcon";
import { StyledTableRow } from "./styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { RefreshIcon } from "../svg/RefreshIcon";
import { ChartIcon } from "../svg/ChartIcon";
import { AccountsUserIcon, BarCodeIcon, PartnersStoreIcon, TableAmountIcon } from "../svg";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import TableRupeeIcon from "../../assets/tableRuppee.svg";
import Coupons from "../../assets/coupons.svg"

const useStyles = makeStyles({
  tableBody: {
    fontWeight: "400",
    fontSize: "12px",
    padding: 10,
  },
});

export const ListTableRow = ({
  incrementCount = 0,
  index = null,
  parent_id = "",
  tableData = [],
  condition = () => true,
  Header = [],
  row = {},
  refreshIcon = "",
  assgineeClick = false,
  handleCheckBox = () => null,
  handleTextfield = () => null,
  handleEdit = () => null,
  handleChartIcon = () => null,
  handlePrintJob = () => null,
  handleStatusClick = () => null,
  handleViewClick = () => null,
  handleView = () => null,
  handleOverRide = () => null,
  handleDelete = () => null,
  handleHyperLink = () => null,
  handleSelect = () => null,
  setOpen = () => null,
  state = {},
  tdStyle = {},
  totalCheck = [],
  handleBar = () => null,
  handleUser = () => null,
  handlePartnerStore = () => null,
  handleUpdateStatus = () => null,
  handleViewReceipt = () => null
}) => {
  const classes = useStyles();
  return (
    <StyledTableRow
      id={`${parent_id}-${index}-table-dataList-TableRow`}
      key={index + 1}
    >
      {tableData.map((val, i) => {
          
        return (
          <TableCell
            id={`${parent_id}-${i}-table-dataList-TableCell`}
            style={{
              paddingLeft: i === 0 ? "25px" : "",
              paddingRight: i === Header.length - 1 ? "25px" : "",
              fontSize: "12px",
              color: "#858585",
              ...tdStyle,
              textAlign: "center",
            }}
            align={val.align ? val.align : "left"}
            className={classes.tableBody}
          >
            
            {val.type.map((type) => {
              if (condition(row, type, val.name)) {
                return getComponentType({
                  index: index + incrementCount,
                  type: type,
                  condition: val.cond,
                  value: val.optionLabel
                    ? row[val.name]?.[val.optionLabel]
                    : row[val.name],
                  error: !!row?.error?.[val.name],
                  placeholder: [val.name],
                  options: val?.options ?? [],
                  icon: val?.icon ?? "",
                  toolTip: val?.toolTip ?? {},
                  state: state,
                  row: row,
                  refreshIcon: refreshIcon,
                  assgineeClick: assgineeClick,
                  totalCheck: totalCheck,
                  handleCheckBox: (e) => {
                    handleCheckBox(e, row, index);
                  },
                  handleEdit: (e) => {
                    handleEdit(e, row, index);
                  },
                  handleView: (e) => {
                    handleView(e, row, index);
                  },
                  handleUser: (e) => {
                    handleUser(e, row, index);
                  },
                  handleBar: (e) => {
                    handleBar(e, row, index);
                  },
                  handlePrintJob: (e) => {
                    handlePrintJob(e, row, index);
                  },
                  handleViewReceipt: (e) => {
                    handleViewReceipt(e, row, index);
                  },
                  handleChartIcon: (e) => {
                    handleChartIcon(e, row, index);
                  },
                  handleStatusClick: (e) => {
                    handleStatusClick(e, row, index);
                  },
                  handleViewClick: (e) => {
                    handleViewClick(e, row, index);
                  },
                  handleOverRide: (e) => {
                    handleOverRide(e, row, index);
                  },
                  handleDelete: (e) => {
                    handleDelete(e, row, index);
                  },
                  handlePartnerStore: (e) => {
                    handlePartnerStore(e, row, index);
                  },
                  handleUpdateStatus: (e) => {
                    handleUpdateStatus(e, row, index);
                  },
                  handleTextfield: (e) => {
                    handleTextfield(e, row, val.name, index);
                  },
                  handleHyperLink: (e) => {
                    handleHyperLink(e, row, index);
                  },
                  handleSelect: (data) => {
                    handleSelect(data, row, val.name, index);
                  },
                  setOpen: (data, index) => {
                    setOpen(data, index);
                  },
                });
              } else {
                return false;
              }
            })}
          </TableCell>
        );
      })}
    </StyledTableRow>
  );
};

const generateStatusBullet = (data) => {
  switch (data) {
    case "PENDING": {
      return "#ffeacd";
    }
    case "PAID": {
      return "#b7e6cf";
    }
    case "Pending": {
      return "#ffeacd";
    }
    case "Fitted": {
      return "#d1efef";
    }
    case "Delivered": {
      return "#b7e6cf7";
    }
    case "Shipped": {
      return "#d9ebff";
    }
    case "Packed": {
      return "#d1efef";
    }
    case "QC": {
      return "#d1efef";
    }
    case "Created": {
      return "#d1efef";
    }
    case "In Progress": {
      return "#ffeacd";
    }
    case "Completed": {
      return "#d9ebff";
    }
    case "Paid": {
      return "#b7e6cf";
    }
    default: {
      return "#d1efef";
    }
  }
};

const statusColor = (data) => {
  switch (data) {
    case "PENDING": {
      return "#ffb95a";
    }
    case "PAID": {
      return "#1daf67";
    }
    case "Pending": {
      return "#ffb95a";
    }
    case "Fitted": {
      return "#03a5a5";
    }
    case "Delivered": {
      return "#1daf67";
    }
    case "Shipped": {
      return "#0077ff";
    }
    case "Packed": {
      return "#03a5a5";
    }
    case "QC": {
      return "#03a5a5";
    }
    case "Created": {
      return "#03a5a5";
    }
    case "In Progress": {
      return "#ffb95a";
    }
    case "Completed": {
      return "#0077ff";
    }
    case "Paid": {
      return "#1daf67";
    }
    default: {
      return "#03a5a5";
    }
  }
};

const getPayment = (data) => {
  switch (data) {
    case "Confirm Payment": {
      return "#ff6f00"
    }
    default: {
      return "#1daf67";
    }
  }
}
const getComponentType = (data) => {
  switch (data.type) {
    case "AMOUNT_VIEW": {
      return (
        <div onClick={data.handleViewClick} style={{ cursor: "pointer" }}>
          <img src={TableRupeeIcon} alt="nodata" />
        </div>
      );
    }
    case "PAYMENT": {
      return (
        <div onClick={data.handleViewClick} style={{ cursor: "pointer" }}>
          <Typography sx={{ color: getPayment(data.value), cursor: "pointer", fontSize: "12px", textAlign: "end" }}> {data.value} </Typography>
        </div>
      );
    }
    case "UPDATE_STATUS": {
      return (
        <div onClick={data.handleUpdateStatus}>
          <Typography sx={{ color: "#ff6f00", cursor: "pointer", fontSize: "12px" }}>Update Payment</Typography>
        </div>
      )
    }
    case "ACCOUNTS": {
      return (
        <div style={{ cursor: "pointer" }} onClick={data.handleUser}>
          <AccountsUserIcon />
        </div>
      );
    }
    case "PARTNER_STORE": {
      return (
        <div style={{ cursor: "pointer" }} onClick={data.handlePartnerStore}>
          <PartnersStoreIcon />
        </div>
      );
    }
    case "TEXT": {
      if (
        data?.condition &&
        data.row[data.condition.key] === data.condition.value
      ) {
        return (
          <React.Fragment>
            <span style={{ paddingLeft: "5px" }}>{data.value}</span>
          </React.Fragment>
        );
      } else {
        return data.value;
      }
    }
    case "AMOUNT_VIEW": {
      return (
        <div onClick={data.handleViewClick} style={{ cursor: "pointer" }}>
          <img src={TableRupeeIcon} />
        </div>
      );
    }
    case "ACCOUNTS": {
      return (
        <div style={{ cursor: "pointer" }} onClick={data.handleUser}>
          <AccountsUserIcon />
        </div>
      );
    }
    case "PARTNER_STORE": {
      return (
        <div style={{ cursor: "pointer" }} onClick={data.handlePartnerStore}>
          <PartnersStoreIcon />
        </div>
      );
    }
    case "TEXT_LINK": {
      return data.assgineeClick ? (
        <div
          onClick={data.handleViewClick}
          style={{ textDecoration: "underline", color: "#03a5a5" }}
        >
          {data.value}
        </div>
      ) : (
        <div style={{ color: "#03a5a5" }}>{data.value}</div>
      );
    }
    case "INCRIMENT": {
      return data.index + 1;
    }
    case "CHECKBOX": {
      return (
        <Checkbox
          id={`table-value-TableSwitch`}
          checked={data.totalCheck.includes(data?.row)}
          onChange={data.handleCheckBox}
          name="checkbox"
        />
      );
    }
    case "EDIT": {
      return (
        <img
          id={`table-EditIcon-img`}
          src={<EditIcon />}
          onClick={data.handleEdit}
          width="12px"
          style={{ marginRight: "10px", cursor: "pointer" }}
          alt="table-Edit"
        />
      );
    }
    case "COMMENTS": {
      return (
        <div style={{ paddingLeft: "25px" }}>
          <CommentIcon />
        </div>
      );
    }
    case "BARCODE": {
      return (
        <div style={{ paddingLeft: "25px" }}>
          <BarCodeIcon />
        </div>
      );
    }
    case "LOGO": {
      return <img src={data?.value} alt="Logo" width="66px" height="66px" />;
    }
    case "TEXTFIELD": {
      return (
        <TextField
          id={`table-${data?.value}-TextField`}
          value={data?.value}
          placeholder={`Enter ${data?.placeholder}`}
          onChange={data.handleTextfield}
          error={data?.error}
        />
      );
    }
    case "STATUS": {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              height: "24px",
              backgroundColor: generateStatusBullet(data?.value),
              display: "inline-block",
              padding: "4px 3px 4px 0px",
              borderRadius: "4px",
            }}
          >
            <Typography
              sx={{
                color: statusColor(data?.value),
                fontSize: "11px",
                fontWeight: 500,
                // lineHeight: 1.14,
                letterSpacing: "0.42px",
                textAlign: "center",
                padding: "2px 2px 0px 4px ",
              }}
            >
              {data?.value.toUpperCase()}
            </Typography>
          </div>
        </div>
      );
    }
    case "ORDER_STATUS": {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "120px",
              height: "24px",
              backgroundColor: generateStatusBullet(data?.value),
              opacity: "15%",
              padding: "4px 3px 4px 0px",
              borderRadius: "4px",
            }}
          ></div>
          <Typography
            onClick={data.handleChartIcon}
            sx={{
              position: "absolute",
              color: generateStatusBullet(data?.value),
              fontSize: "11px",
              fontWeight: 500,
              lineHeight: 1.14,
              letterSpacing: "0.42px",
              textAlign: "left",
              padding: "2px 2px 0px 4px ",
            }}
          >
            {data?.value}
          </Typography>
          {data?.refreshIcon ? (
            <div onClick={data.handleStatusClick}>
              <RefreshIcon width={18} height={18} />
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }
    case "VIEW_NEW": {
      return (
        <VisibilityIcon
          onClick={data.handleStatusClick}
          sx={{ color: "#03a5a5" }}
        />
      );
    }
    case "VIEW": {
      return (
        <VisibilityIcon
          onClick={data.handleViewClick}
          sx={{ color: "#03a5a5" }}
        />
      );
    }
    case "VIEW_ORDERS": {
      return (
        <div onClick={data.handleViewClick} >
          <Typography sx={{ color: "#03a5a5", cursor: "pointer", fontSize: "12px" }}>View Orders</Typography>
        </div>
      );
    }
    case "VIEW_RECEIPTS": {
      return (
        <div onClick={data.handleViewReceipt} >
          <Typography sx={{ color: "#03a5a5", cursor: "pointer", fontSize: "12px" }}>View Receipts</Typography>
        </div>
      );
    }
    case "COUPONS": {
      return (
        <div onClick={data.handleViewClick} >
          <img src={Coupons} alt="coupons" />
        </div>
      );
    }
    case "VIEW_TEXT": {
      return (
        <div
          style={{ display: "flex", alignItems: "center", color: "#03a5a5" }}
          onClick={data.handleViewClick}
        >
          {data?.value}
        </div>
      );
    }
    case "CHART_ICON": {
      return (
        <div onClick={data.handlePrintJob}>
          <ChartIcon sx={{ color: "#03a5a5" }} />
        </div>
      );
    }
    case "BAR_CODE": {
      return (
        <div style={{ cursor: "pointer" }} onClick={data.handleBar}>
          <QrCodeScannerIcon sx={{ color: "#03a5a5" }} />
        </div>
      );
    }

    default: {
      return data.value;
    }
  }
};
