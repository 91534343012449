import { BackIcon, ListTable } from "../../../components";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line no-undef
const useStyles = makeStyles({
    root: {
        padding: "90px 24px 40px 26px",
        overflow: "hidden",
    },
});
const Invoiceorder = () => {
    const classes = useStyles();
    const history = useNavigate();

    const handleBack = () => {
        history("/invoice");
    };
    return (
        <>
            <div  className={classes.root}>
                <div onClick={handleBack}>
                    <BackIcon />
                </div>
                <ListTable
                    maxHeight={'calc(100vh - 450px)'}
                    Header={[
                        "Store ID",
                        "Order ID",
                        "Job Order ID",
                        "Frame Info",
                        "Lens Type",
                        "Order Amount",
                        "Current Assignee",
                        "Status",
                        "",
                    ]}
                    tableData={[
                        { type: ["TEXT"], name: "storeId" },
                        { type: ["TEXT"], name: "orderId" },
                        { type: ["TEXT"], name: "jobOrderId" },
                        { type: ["TEXT"], name: "frameInfo" },
                        { type: ["TEXT"], name: "lensType" },
                        { type: ["TEXT"], name: "amount" },
                        { type: ["TEXT_LINK"], name: "currentAssignee" },
                        { type: ["ORDER_STATUS"], name: "status" },
                        { type: ["CHART_ICON"], name: "" },
                    ]}
                />
            </div>
        </>
    )
}
export default Invoiceorder;