import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
    getTerminal,
    setTerminal,
    getConfirmPayment,
    setConfirmPayment
} from "../reducers/posTerminalSlice";

async function fetchTerminalAPI(payload) {
  const userData = await axios
    .get('/client/payment/posterminal', {
      params: {
        limit: 15,
        page: payload.pageNo,
        payload: {},
        paymentType: payload.paymentType,
        store: payload.store,
        date: payload.date,
        startDate: payload.startDate,
        endDate: payload.endDate,
        storeId: payload.storeId,
        filter: {},
        sort: { 
          transactionDate: -1,
          orderId: -1
        },
        search: {   }
      }
    })
    .then((response) => response.data.data);
  return userData;
}

export function* fetchTerminal(action) {
  try {
    const response = yield call(fetchTerminalAPI, action.payload);
    yield put(setTerminal(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function saveCashAPI(payload) {
  const userData = await axios
    .post('/client/payment/confirmpayment', payload)
    .then((response) => response.data.data);
  return userData;
}

export function* saveCash(action) {
  try {
    const { payload } = action;
    const response = yield call(saveCashAPI, payload);
    yield put(setConfirmPayment(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* posTerminalSaga() {
  yield takeLatest(getTerminal.type, fetchTerminal);
  yield takeLatest(getConfirmPayment.type, saveCash);
}
