export const RoutePath = {
  stores: "/stores",
  newStore: '/new-store',
  partners:'/partners',
  newPartners: '/new-partners',
  employees:'/employees',
  newEmployees:'/new-employees',
  vendors:'/vendors',
  b2binvoice:"/invoice",
  newVendors:'/new-vendors',
  err: "/404",
  indents: "/indents",
  storePartners: '/store-partners',
  businessPartners:"/business-partners",
  coupons:"/coupons",
  posTerminal:"/pos-terminal",
  upi:"/upi",
  invoiceorder:"/invoiceorder",
  storeOrder:"/store-orders",
  storeOrderDetails:"/store-order/:orderId",
  cashDeposits:"/cash-deposits",
  login:"/"
};
