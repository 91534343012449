import {
    Autocomplete,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
  } from "@mui/material";
  import React, { useState } from "react";
  import { useNavigate } from "react-router-dom";
  import {
    ContinueButton,
    HeadingTypography,
    SubLabelTypography,
    SuccessModal,
  } from "../../../components";
  import { useStyles } from "./styles";
  
  const NewVendors = (props) => {
    const classes = useStyles();
    const history = useNavigate();
    const [state, setState] = useState({});
    const [success, setSuccess] = useState(false);
    const handleChange = (e, type, name) => {
      if (type === "text") {
        setState({ ...state, [name]: e.target.value });
      } else {
        setState({ ...state, [name]: { label: type.label, value: type.value } });
      }
    };
    const handleSubmit = () => {
      setSuccess(true);
    };
    const handleSuccessClose = () => {
      history('/vendors')
    };
    return (
      <div className={classes.root}>
        <HeadingTypography
          color={"#03a5a5"}
          size={"18px"}
          margin={"0px 0px 16px"}
        >
          NEW VENDOR
        </HeadingTypography>
  
        <Grid container alignItems="center" width={"80%"}>
          <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
            <div>
              <SubLabelTypography
                color={"#2b2b2b"}
                size={"14px"}
                sx={{ pb: "8px" }}
              >
                NAME
              </SubLabelTypography>
              <TextField
                fullWidth
                type="text"
                placeholder="Type Here"
                variant="outlined"
                onChange={(e) => handleChange(e, "text", "name")}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
            <div>
              <SubLabelTypography
                color={"#2b2b2b"}
                size={"14px"}
                sx={{ pb: "8px" }}
              >
                MOBILE NUMBER
              </SubLabelTypography>
              <TextField
                fullWidth
                type="text"
                placeholder="Type Here"
                variant="outlined"
                onChange={(e) => handleChange(e, "text", "mobile")}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
            <div>
              <SubLabelTypography
                color={"#2b2b2b"}
                size={"14px"}
                sx={{ pb: "8px" }}
              >
                EMAIL
              </SubLabelTypography>
              <TextField
                fullWidth
                type="text"
                placeholder="example@gmail.com"
                variant="outlined"
                onChange={(e) => handleChange(e, "text", "email")}
              />
            </div>
          </Grid>
        </Grid>
  
        <Grid container alignItems="center" width={"80%"}>
          <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
            <div>
              <SubLabelTypography
                color={"#2b2b2b"}
                size={"14px"}
                sx={{ pb: "8px" }}
              >
                STATE
              </SubLabelTypography>
              <TextField
                fullWidth
                type="text"
                placeholder="Type Here"
                variant="outlined"
                onChange={(e) => handleChange(e, "text", "state")}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
            <div>
              <SubLabelTypography
                color={"#2b2b2b"}
                size={"14px"}
                sx={{ pb: "8px" }}
              >
                CITY
              </SubLabelTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[
                  { label: "Business Partner", value: "Business Partner" },
                  { label: "Store Partner", value: "Store Partner" },
                ]}
                getOptionLabel={(option) => option.value}
                placeholder="Select"
                onChange={(event, newValue) => {
                  handleChange(event, newValue, "city");
                }}
                renderInput={(params) => (
                  <TextField fullWidth {...params} placeholder="Select" />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
            <div>
              <SubLabelTypography
                color={"#2b2b2b"}
                size={"14px"}
                sx={{ pb: "8px" }}
              >
                ADDRESS
              </SubLabelTypography>
              <TextField
                fullWidth
                type="text"
                placeholder="Type Here"
                variant="outlined"
                onChange={(e) => handleChange(e, "text", "address")}
              />
            </div>
          </Grid>
        </Grid>
  
        <Grid container alignItems="center" width={"80%"}>
          <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
            <div>
              <SubLabelTypography
                color={"#2b2b2b"}
                size={"14px"}
                sx={{ pb: "8px" }}
              >
                PAN
              </SubLabelTypography>
              <TextField
                fullWidth
                type="text"
                placeholder=""
                variant="outlined"
                onChange={(e) => handleChange(e, "text", "pan")}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
            <div>
              <SubLabelTypography
                color={"#2b2b2b"}
                size={"14px"}
                sx={{ pb: "8px" }}
              >
                TAN
              </SubLabelTypography>
              <TextField
                fullWidth
                type="text"
                placeholder=""
                variant="outlined"
                onChange={(e) => handleChange(e, "text", "tan")}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
            <div>
              <SubLabelTypography
                color={"#2b2b2b"}
                size={"14px"}
                sx={{ pb: "8px" }}
              >
                INVOICE TYPE
              </SubLabelTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={[
                  { label: "Automatic Invoice", value: "Automatic Invoice" },
                  { label: "Manual Invoice", value: "Manual Invoice" },
                ]}
                getOptionLabel={(option) => option.value}
                placeholder="Select"
                onChange={(event, newValue) => {
                  handleChange(event, newValue, "invoiceType");
                }}
                renderInput={(params) => (
                  <TextField fullWidth {...params} placeholder="Select" />
                )}
              />
            </div>
          </Grid>
        </Grid>
  
        <div className={classes.alignStraight} >
          <SubLabelTypography
            color={"#2b2b2b"}
            size={"14px"}
            sx={{ pb: "0px", mr: "20px" }}
          >
            Partner Have GSTIN?
          </SubLabelTypography>
          <FormControl>
            <RadioGroup
              row
              onChange={(e) => handleChange(e, "text", "GSTIN")}
              value={state?.GSTIN}
            >
              <FormControlLabel
                sx={{ mr: "40px" }}
                value="Yes"
                control={
                  <Radio size="small" classes={{ checked: classes?.radio }} />
                }
                label="Yes"
              />
              <FormControlLabel
                sx={{ mr: "40px" }}
                value="No"
                control={
                  <Radio size="small" classes={{ checked: classes?.radio }} />
                }
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </div>
        {state?.GSTIN === "Yes" && 
            <Grid container alignItems="center" width={"80%"}>
                <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
                    <div>
                    <TextField
                        fullWidth
                        type="text"
                        placeholder="Type Here"
                        variant="outlined"
                        onChange={(e) => handleChange(e, "text", "GSTINum")}
                    />
                    </div>
                </Grid>
            </Grid>
        }
        <ContinueButton marginLeft={"0px"} sx={{mt:"20px"}} onClick={handleSubmit}>
          Submit
        </ContinueButton>
        <SuccessModal
          success={success}
          successHeader={"Vendor Added Successfully"}
          succesSubHeader={"Vijay/47347Bf"}
          button={"New Vendor"}
          handleSuccessClose={handleSuccessClose}
        ></SuccessModal>
      </div>
    );
  };
  
  export default NewVendors;
  