import React from "react";

export const SmsIcon = (props) => {
  return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.199"
        height="19.199"
        viewBox="0 0 19.199 19.199"
      >
        <g data-name="vuesax/outline/sms">
          <path
            d="M12.6 14.8h-8c-2.92 0-4.6-1.68-4.6-4.6V4.6C0 1.68 1.68 0 4.6 0h8c2.92 0 4.6 1.68 4.6 4.6v5.6c0 2.919-1.681 4.6-4.6 4.6zm-8-13.6c-2.288 0-3.4 1.112-3.4 3.4v5.6c0 2.288 1.112 3.4 3.4 3.4h8c2.288 0 3.4-1.112 3.4-3.4V4.6c0-2.288-1.112-3.4-3.4-3.4z"
            transform="translate(1 2.2)"
            fill="#827e7e"
          />
          <path
            data-name="Vector"
            d="M4.6 3.7a2.974 2.974 0 0 1-1.87-.632l-2.5-2A.6.6 0 0 1 .97.133l2.5 2a1.909 1.909 0 0 0 2.248 0l2.5-2a.59.59 0 0 1 .84.1.59.59 0 0 1-.1.84l-2.5 2A2.936 2.936 0 0 1 4.6 3.7z"
            transform="translate(4.997 6.595)"
            fill="#827e7e"
          />
        </g>
      </svg>
  );
};
