import React, { useState } from "react";
import {
  BackIcon,
  GeneralDataModal,
  HeadingTypography,
  ListTable,
  OrderRuppeeIcon,
  SubLabelTypography,
} from "../../../components";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

function BusinessPartnerOrders(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [generalDialog, setGeneralDialog] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selected, setSelected] = useState("All");
  const [productData, setProductData] = useState([]);
  const [cardData, setCardData] = useState({});

  let tableData = [];

  props?.businessPartnerOrder?.map((data) => {
    const storePartner = {
      storeId: data?.storeId,
      orderId: data?.orderId + "/" + moment(data?._created_at).format("ll"),
      amount: data?.inclGst,
      earnings: data?.earnings,
      data: data,
    };
    tableData.push(storePartner);
    return storePartner;
  });

  let secondaryTable = [];

  productData?.map((data) => {
    const storePartner = {
      product: data?.name,
      hsn: data?.HSNCode || "-",
      taxableValue: data?.price,
      gstPercent: data?.GST,
      gstValue: data?.totalGst,
      totalAmount: data?.inclGst,
      data: data,
    };
    secondaryTable.push(storePartner);
    return storePartner;
  });

  const handleBack = () => {
    props?.handleOrdersClose();
  };

  const handleViewClick = (e, data) => {
    setGeneralDialog(true);
    setCardData(data.data);
    setProductData(data.data.products);
  };

  const handleGeneralDialogClose = () => {
    setGeneralDialog(false);
  };

  const handleSelected = (val) => {
    setSelected(val);
    setDropDownOpen(!dropDownOpen);
  };

  const BodyComp = () => {
    return (
      <div>
        <div
          className={classes.alignStraightBetween}
          style={{ marginBottom: "12px" }}
        >
          <HeadingTypography>Payment Details</HeadingTypography>
          <div style={{ cursor: "pointer" }} onClick={handleGeneralDialogClose}>
            <CloseIcon sx={{ fontSize: "12px", color: "#827e7e" }} />
          </div>
        </div>
        <div className={classes.alignStraight} style={{ marginBottom: "18px" }}>
          <div
            className={classes.alignStraight}
            style={{ marginRight: "20px" }}
          >
            <SubLabelTypography margin={"0px 6px 0px 0px"}>
              ORDER ID/DATE:
            </SubLabelTypography>
            <HeadingTypography size={"13px"}>
              {cardData?.orderId +
                "/" +
                moment(cardData?._created_at).format("ll")}
            </HeadingTypography>
          </div>
          <div
            className={classes.alignStraight}
            style={{ marginRight: "20px" }}
          >
            <SubLabelTypography margin={"0px 6px 0px 0px"}>
              STORE ID:
            </SubLabelTypography>
            <HeadingTypography size={"13px"}>
              {cardData?.storeId}
            </HeadingTypography>
          </div>
        </div>
        <ListTable
          dataList={secondaryTable}
          handleViewClick={handleViewClick}
          Header={[
            "Product",
            "HSN Code",
            "TAXABLE VALUE",
            "GST(%)",
            "GST(\u20B9)",
            "Total Amount",
          ]}
          tableData={[
            { type: ["TEXT"], name: "product" },
            { type: ["TEXT"], name: "hsn" },
            { type: ["TEXT"], name: "taxableValue" },
            { type: ["TEXT"], name: "gstPercent" },
            { type: ["TEXT"], name: "gstValue" },
            { type: ["TEXT"], name: "totalAmount" },
          ]}
        />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div onClick={handleBack} style={{ cursor: "pointer" }}>
        <BackIcon />
      </div>
      <div className={classes.alignStraight}>
        <div className={classes.borderBox}>
          <div
            className={classes.alignStraightBetween}
            style={{ align: "flex-start" }}
          >
            <SubLabelTypography>PARTNER ID/NAME</SubLabelTypography>
            <OrderRuppeeIcon />
          </div>
          <HeadingTypography size={"13px"}>
            {props?.details?.partnerId + "/" + props?.details?.partnerId}
          </HeadingTypography>
        </div>

        <div className={classes.borderBox}>
          <div
            className={classes.alignStraightBetween}
            style={{ align: "flex-start" }}
          >
            <SubLabelTypography>TOTAL AMOUNT</SubLabelTypography>
            <OrderRuppeeIcon />
          </div>
          <HeadingTypography size={"13px"}>{props?.details.inclGst || "-"}</HeadingTypography>
        </div>

        <div className={classes.borderBox}>
          <div
            className={classes.alignStraightBetween}
            style={{ align: "flex-start" }}
          >
            <SubLabelTypography>EARNINGS</SubLabelTypography>
            <OrderRuppeeIcon />
          </div>
          <HeadingTypography size={"13px"}>
            {props?.details?.earnings}
          </HeadingTypography>
        </div>

        <div className={classes.borderBox}>
          <div
            className={classes.alignStraightBetween}
            style={{ align: "flex-start" }}
          >
            <SubLabelTypography>TDS</SubLabelTypography>
            <OrderRuppeeIcon />
          </div>
          <HeadingTypography size={"13px"}>
            {props?.details?.tds}
          </HeadingTypography>
        </div>

        <div className={classes.borderBox}>
          <div
            className={classes.alignStraightBetween}
            style={{ align: "flex-start" }}
          >
            <SubLabelTypography>GST</SubLabelTypography>
            <OrderRuppeeIcon />
          </div>
          <HeadingTypography size={"13px"}>{props?.details?.totalGst || "-"}</HeadingTypography>
        </div>

        <div className={classes.borderBox}>
          <div
            className={classes.alignStraightBetween}
            style={{ align: "flex-start" }}
          >
            <SubLabelTypography>TOTAL PAYABLE</SubLabelTypography>
            <OrderRuppeeIcon />
          </div>
          <HeadingTypography size={"13px"}>
            {props?.details?.totalPayables}
          </HeadingTypography>
        </div>
      </div>
      <div className={classes.alignStraightBetween}>
        <div style={{ fontSize: 16, color: "#000", fontWeight: 600 }}>
          Orders
        </div>

        <div
          style={{
            fontSize: 13,
            color: "#000",
            fontWeight: 600,
            paddingRight: "10px",
          }}
        >
          Total Orders: {props?.details?.totalOrders}
        </div>
      </div>
      <ListTable
        dataList={tableData}
        handleViewClick={handleViewClick}
        Header={["Store ID", "Order ID/Date", "Order Amount", "Earnings", ""]}
        tableData={[
          { type: ["TEXT"], name: "storeId" },
          { type: ["TEXT"], name: "orderId" },
          { type: ["TEXT"], name: "amount" },
          { type: ["TEXT"], name: "earnings" },
          { type: ["AMOUNT_VIEW"], name: "" },
        ]}
      />
      <GeneralDataModal
        generalDialog={generalDialog}
        handleGeneralDialogClose={handleGeneralDialogClose}
        paperWidth={classes.orderTablePaper}
        bodyComp={<BodyComp />}
      />
    </div>
  );
}

export default BusinessPartnerOrders;
