import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    alignStraightBetween:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between"
    },
    alignStraight:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-start"
    },
    root:{
        padding: "90px 24px 40px 26px",
    },
    paperWidth:{
        maxWidth:"900px"
    },
    radio:{
        color:"#03a5a5 !important"
    },
    borderBox:{
        minWidth: "15%",
        height: "10%",
        borderRadius: "5px",
        backgroundColor: "#fff",
        border: "solid 1px #eaeaea",
        margin: "27px 23px 34px 0px",
        padding: "17px 10px 20px 10px",
    },
    orderTablePaper:{
        maxWidth:"1000px",
        width:"900px"
    }
}) 