import {
    Dialog,
    DialogContent,
    Grid,
    TextField,
  } from "@mui/material";
  import React from "react";
  import { useStyles } from "../../features/Payments/Indents/styles";
  import { ContinueButton, HeadingTypography, SubLabelTypography } from "../wrapperComponents";
  import CloseIcon from "@mui/icons-material/Close";
  import DatePicker from "react-datepicker";
  
  const UpdateStatus = (props) => {
    const classes = useStyles();
    const handleSubmit = () => {
      props?.handleSubmitUpdate()
    }
    return (
      <Dialog open={props.updateStatus} onClose={props?.handleCloseUpdate}>
        <DialogContent>
          <div style={{ width: "400px", height: "500px" }}>
            <div
              className={classes.alignStraightBetween}
              style={{ marginBottom: "12px" }}
            >
              <HeadingTypography weight={"600"}>Update Payment</HeadingTypography>
              <div
                style={{ cursor: "pointer" }}
                onClick={props?.handleCloseUpdate}
              >
                <CloseIcon sx={{ fontSize: "12px", color: "#827e7e" }} />
              </div>
            </div>
            <HeadingTypography weight={"500"} size={"13px"}>PAYABLE AMOUNT: {props?.partnerDetails?.totalPayables}</HeadingTypography>
            <Grid container sx={{ width: "60%",mt:"20px",mb:"20px" }}>
              <Grid item xs={12} sx={{ pb: "1.5%" }}>
                <SubLabelTypography
                  color={"#2b2b2b"}
                  size={"14px"}
                  sx={{ pb: "8px" }}
                >
                  TRANSACTION DATE
                </SubLabelTypography>
                <DatePicker
                  selected={props?.dateValue?.transactionDate}
                  autoComplete="off"
                  onChange={(date) =>
                    props?.handleDateChange(date, "transactionDate")
                  }
                  dateFormat="dd/MM/yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Transaction Date"
                  customInput={
                    <TextField
                      fullWidth
                      type="text"
                      placeholder="Transaction Date"
                      variant="outlined"
                    />
                  }
                />
              </Grid>
            </Grid>
  
            <Grid container >
              <Grid item xs={12} sx={{ mb: "5.5%" }}>
                <SubLabelTypography
                  color={"#2b2b2b"}
                  size={"14px"}
                  sx={{ pb: "8px" }}
                >
                  TRANSACTION ID
                </SubLabelTypography>
                <TextField
                  fullWidth
                  type="text"
                  placeholder="Type Here"
                  variant="outlined"
                  onChange={(e) => props?.handleChange(e, "text", "transactionId")}
                />
              </Grid>
            </Grid>
            <ContinueButton marginLeft={"0px"} onClick={handleSubmit}>Submit</ContinueButton>
          </div>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default UpdateStatus;