import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  BackIcon,
  GeneralDataModal,
  HeadingTypography,
  ListTable,
  OrderRuppeeIcon,
  SubLabelTypography,
} from "../../../components";
import { ContinueButton } from "../../../components";

import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getIndentsOrders } from "../../../app/reducers/indentsSlice";
import moment from "moment";

function IndentOrders(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const intendsOrder = useSelector(
    (state) => state.indentsReducer.intendsOrder
  );

  const [generalDialog, setGeneralDialog] = useState("");
  const [cardData, setCardData] = useState({});
  const [lensData, setLensData] = useState([]);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selected, setSelected] = useState("All");

  let tableData = [];
  let indentSummaryData = [];
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  intendsOrder?.map((data) => {
    data.products.map(product => {
      const tempIndentSummary = {
        product: product?.name,
        hsn: product?.HSNCode || "-",
        taxableValue: product?.costPrice,
        gstPercent: product?.GST,
        gstValue: product?.totalGst,
        totalAmount: product?.inclGstCostPrice,
        data: product,
      };
      indentSummaryData.push(tempIndentSummary);
      return 1
    })

    const storePartner = {
      storeId: data?.storeId,
      orderId:
        data?.orderInfo?.orderId +
        "/" +
        moment(data?.orderInfo?.orderDate).format("ll"),
      jobOrderId: data?.jobOrderId,
      amount: data?.inclGst,
      payables: data?.inclGst,
      data: data,
    };
    tableData.push(storePartner);
    return storePartner;
  });



  let secondarayTable = [];

  lensData.map((data) => {
    const storePartner = {
      product: data?.name,
      hsn: data?.HSNCode || "-",
      taxableValue: data?.costPrice,
      gstPercent: data?.GST,
      gstValue: data?.totalGst,
      totalAmount: data?.inclGstCostPrice,
      data: data,
    };
    secondarayTable.push(storePartner);
    return storePartner;
  });

  const handleIndentSummary = () => {
    setGeneralDialog("Grouped");
  }

  const handleBack = () => {
    props?.handleOrdersClose();
  };

  const handleViewClick = (e, data) => {
    setGeneralDialog("Individual");
    setCardData(data.data);
    setLensData(data?.data?.products);
  };

  const handleGeneralDialogClose = () => {
    setGeneralDialog("");
  };

  const handleSelected = (val) => {
    setSelected(val);
    setDropDownOpen(!dropDownOpen);
  };

  useEffect(() => {
    dispatch(getIndentsOrders(props?.details?._id));
  }, []);

  const BodyComp = () => {
    return (
      <div>
        <div
          className={classes.alignStraightBetween}
          style={{ marginBottom: "12px" }}
        >
          <HeadingTypography>Payment Details</HeadingTypography>
          <div style={{ cursor: "pointer" }} onClick={handleGeneralDialogClose}>
            <CloseIcon sx={{ fontSize: "12px", color: "#827e7e" }} />
          </div>
        </div>
        <div className={classes.alignStraight} style={{ marginBottom: "18px" }}>
          <div
            className={classes.alignStraight}
            style={{ marginRight: "20px" }}
          >
            <SubLabelTypography margin={"0px 6px 0px 0px"}>
              ORDER ID/DATE:
            </SubLabelTypography>
            <HeadingTypography size={"13px"}>
              {cardData?.orderInfo?.orderId}/{moment(cardData?.orderInfo?.orderDate).format('ll')}
            </HeadingTypography>
          </div>
          <div
            className={classes.alignStraight}
            style={{ marginRight: "20px" }}
          >
            <SubLabelTypography margin={"0px 6px 0px 0px"}>
              JOB ORDER ID:
            </SubLabelTypography>
            <HeadingTypography size={"13px"}>
              {cardData?.jobOrderId}
            </HeadingTypography>
          </div>
          <div
            className={classes.alignStraight}
            style={{ marginRight: "20px" }}
          >
            <SubLabelTypography margin={"0px 6px 0px 0px"}>
              STORE ID:
            </SubLabelTypography>
            <HeadingTypography size={"13px"}>
              {cardData?.storeId}
            </HeadingTypography>
          </div>
        </div>
        <ListTable
          dataList={secondarayTable}
          Header={[
            "Product",
            "HSN Code",
            "TAXABLE VALUE",
            "GST(%)",
            "GST(\u20B9)",
            "Total Amount",
          ]}
          tableData={[
            { type: ["TEXT"], name: "product" },
            { type: ["TEXT"], name: "hsn" },
            { type: ["TEXT"], name: "taxableValue" },
            { type: ["TEXT"], name: "gstPercent" },
            { type: ["TEXT"], name: "gstValue" },
            { type: ["TEXT"], name: "totalAmount" },
          ]}
        />
      </div>
    );
  };

  const BodyCompIndentSummary = () => {
    return (
      <div>
        <div
          className={classes.alignStraightBetween}
          style={{ marginBottom: "12px" }}
        >
          <HeadingTypography>Indent Summary</HeadingTypography>
          <div style={{ cursor: "pointer" }} onClick={handleGeneralDialogClose}>
            <CloseIcon sx={{ fontSize: "12px", color: "#827e7e" }} />
          </div>
        </div>
        <div ref={componentRef} style={{ padding: "5px" }}>
          <div className={classes.alignStraight} style={{ marginBottom: "18px" }}>
            <div
              className={classes.alignStraight}
              style={{ marginRight: "20px" }}
            >
              <SubLabelTypography margin={"0px 6px 0px 0px"}>
                INDENT ID/DATE:
              </SubLabelTypography>
              <HeadingTypography size={"13px"}>
                {props?.details?._id + "/" + moment(props?.details?.intendDate).format("ll")}
              </HeadingTypography>
            </div>
            <div
              className={classes.alignStraight}
              style={{ marginRight: "20px" }}
            >
              <SubLabelTypography margin={"0px 6px 0px 0px"}>
                TOTAL AMOUNT:
              </SubLabelTypography>
              <HeadingTypography size={"13px"}>
                {props?.details?.inclGst}
              </HeadingTypography>
            </div>
            <div
              className={classes.alignStraight}
              style={{ marginRight: "20px" }}
            >
              <SubLabelTypography margin={"0px 6px 0px 0px"}>
                TOTAL COST:
              </SubLabelTypography>
              <HeadingTypography size={"13px"}>
                {props?.details?.subTotal}
              </HeadingTypography>
            </div>
          </div>
          <ListTable
            dataList={indentSummaryData}
            Header={[
              "Product",
              "HSN Code",
              "TAXABLE VALUE",
              "GST(%)",
              "GST(\u20B9)",
              "Total Amount",
            ]}
            maxHeight={false}
            tableData={[
              { type: ["TEXT"], name: "product" },
              { type: ["TEXT"], name: "hsn" },
              { type: ["TEXT"], name: "taxableValue" },
              { type: ["TEXT"], name: "gstPercent" },
              { type: ["TEXT"], name: "gstValue" },
              { type: ["TEXT"], name: "totalAmount" },
            ]}
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <ContinueButton onClick={handlePrint}>Print</ContinueButton>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div onClick={handleBack} style={{ cursor: "pointer" }}>
        <BackIcon />
      </div>
      <div className={classes.alignStraight}>
        <div className={classes.borderBox}>
          <div
            className={classes.alignStraightBetween}
            style={{ align: "flex-start" }}
          >
            <SubLabelTypography>INDENT ID/DATE</SubLabelTypography>
            <OrderRuppeeIcon />
          </div>
          <HeadingTypography size={"13px"}>
            {props?.details?._id + "/" + moment(props?.details?.intendDate).format("ll")}
          </HeadingTypography>
        </div>

        <div className={classes.borderBox}>
          <div
            className={classes.alignStraightBetween}
            style={{ align: "flex-start" }}
          >
            <SubLabelTypography>TOTAL AMOUNT</SubLabelTypography>
            <OrderRuppeeIcon />
          </div>
          <HeadingTypography size={"13px"}>
            {props?.details?.inclGst}</HeadingTypography>
        </div>

        <div className={classes.borderBox}>
          <div
            className={classes.alignStraightBetween}
            style={{ align: "flex-start" }}
          >
            <SubLabelTypography>TOTAL COST</SubLabelTypography>
            <OrderRuppeeIcon />
          </div>
          <HeadingTypography size={"13px"}>
            {props?.details?.subTotal}
          </HeadingTypography>
        </div>

        <div className={classes.borderBox}>
          <div
            className={classes.alignStraightBetween}
            style={{ align: "flex-start" }}
          >
            <SubLabelTypography>GST</SubLabelTypography>
            <OrderRuppeeIcon />
          </div>
          <HeadingTypography size={"13px"}>
            {props?.details?.gst}
          </HeadingTypography>
        </div>

        <div className={classes.borderBox}>
          <div
            className={classes.alignStraightBetween}
            style={{ align: "flex-start" }}
          >
            <SubLabelTypography>TOTAL PAYABLES</SubLabelTypography>
            <OrderRuppeeIcon />
          </div>
          <HeadingTypography size={"13px"}>{props?.details?.inclGst}</HeadingTypography>
        </div>
      </div>
      <div className={classes.alignStraightBetween}>
        <div style={{ fontSize: 16, color: "#000", fontWeight: 600 }}>
          Orders
        </div>
        <div
          style={{
            fontSize: 13,
            color: "#000",
            fontWeight: 600,
            paddingRight: "10px",
          }}
        >
          Total Orders: {props?.details?.totalOrders}
        </div>
        {/* <div>
          <div className={classes.alignStraight}>
            <div style={{ fontSize: 12, color: "#827e7e", marginRight: "6px" }}>
              ORDER STATUS:{" "}
            </div>
            <Typography sx={{ margin: "0px 6px" }}>{selected}</Typography>
            <div onClick={() => setDropDownOpen(!dropDownOpen)}>
              {dropDownOpen ? (
                <ExpandLess sx={{ color: "#827e7e" }} />
              ) : (
                <ExpandMore sx={{ color: "#827e7e" }} />
              )}
            </div>
          </div>
          <Collapse in={dropDownOpen} unmountOnExit>
            <List component="div" disablePadding>
              {["All", "Created", "Shipped"].map((item, i) => (
                <ListItemButton
                  key={i}
                  sx={{ padding: "2px" }}
                  onClick={() => handleSelected(item)}
                >
                  <ListItemText
                    sx={{
                      "&.MuiCollapse-root": {
                        fontSize: 6,
                        color: "#827e7e",
                      },
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {item}
                  </ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </div> */}
      </div>
      <ListTable
        dataList={tableData}
        handleViewClick={handleViewClick}
        Header={[
          "Store ID",
          "Order ID/Date",
          "Job Order ID",
          "Payables",
          "",
        ]}
        tableData={[
          { type: ["TEXT"], name: "storeId" },
          { type: ["TEXT"], name: "orderId" },
          { type: ["TEXT"], name: "jobOrderId" },
          { type: ["TEXT"], name: "payables" },
          { type: ["AMOUNT_VIEW"], name: "" },
        ]}
      />
      <div style={{ marginTop: "10px", float: "right" }}>
        <ContinueButton marginLeft={"0px"} onClick={handleIndentSummary}>Indent Summary</ContinueButton>
      </div>
      {
        generalDialog === "Individual" && (
          <GeneralDataModal
            generalDialog={generalDialog}
            handleGeneralDialogClose={handleGeneralDialogClose}
            paperWidth={classes.orderTablePaper}
            bodyComp={<BodyComp />}
          />
        )
      }

      {
        generalDialog === "Grouped" && (
          <GeneralDataModal
            generalDialog={generalDialog}
            handleGeneralDialogClose={handleGeneralDialogClose}
            paperWidth={classes.orderTablePaper}
            bodyComp={<BodyCompIndentSummary />}
          />
        )
      }

    </div>
  );
}

export default IndentOrders;
