import React from "react";
import { styled } from "@mui/system";

import { Dialog, IconButton, Typography, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ViewDetailTable from "./ViewDetailTable";
import _ from "lodash";

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[200]
      };
      text-align: left;
      padding: 6px;
    }
  
    th {
      background-color: ${
        theme.palette.mode === "dark" ? grey[900] : grey[100]
      };
    }
    `
);

const ViewDetail = (props) => {
  return (
    <Dialog onClose={props?.handleViewClose} open={props?.open}>
      <div style={{ padding: "30px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "12px",
          }}
        >
          <Typography sx={{ fontSize: "16px" }}>View Detail</Typography>
          <IconButton onClick={props?.handleViewClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {_.isEmpty(props.data.prescriptions) && _.isEmpty(props.data.frameSize) ? (
          <div style={{ padding: "40px"}}>No Prescription/frame added </div>
        ) : (
          <>
            {" "}
            <ViewDetailTable
              heading={"Prescription"}
              prescriptionData={props.data.prescriptions}
            />
            <ViewDetailTable
              heading={"Frame"}
              frameData={props.data.frameSize}
            />
          </>
        )}
      </div>
    </Dialog>
  );
};

export default ViewDetail;
