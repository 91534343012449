import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ContinueButton,
  HeadingTypography,
  SubLabelTypography,
  SuccessModal,
  BackIcon,
} from "../../../components";
import { useStyles } from "./styles";
import { State, City } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import {
  getSavePartner,
  setSavePartnerError,
} from "../../../app/reducers/partnerSlice";
import { AlertComp } from "../../../components/Alert";
import _ from "lodash";

const NewPartners = (props) => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();

  const partnerSavedError = useSelector(
    (state) => state.partnerReducer.partnerSavedError
  );
  const partnerSaved = useSelector(
    (state) => state.partnerReducer.partnerSaved
  );

  const [partner, setPartner] = useState({});
  const [success, setSuccess] = useState(false);
  const [showGstin, setShowGstin] = useState(false);
  const [succesSubHeader, setSuccessSubHeader] = useState("");

  const [selectedState, setSelectedState] = useState("");
  const state = State.getStatesOfCountry("IN");
  const cities = City.getCitiesOfState("IN", selectedState);

  useEffect(() => {
    if (!_.isEmpty(partnerSaved)) {
      const subHeader = partner?.name + "/" + partner?.userType;
      setSuccessSubHeader(subHeader);
      setSuccess(true);
    }
  }, [partnerSaved]);

  const handleChange = (event, value, name) => {
    if (name === "state") {
      setSelectedState(value.isoCode);
    }
    if (value) {
      setPartner({ ...partner, [name]: value.name });
    } else {
      setPartner({ ...partner, [name]: event.target.value });
    }
  };
  const handleSubmit = () => {
    dispatch(getSavePartner(partner));
  };
  const handleSuccessClose = () => {
    props?.handleBack();
  };

  const handleAlertClose = () => {
    dispatch(setSavePartnerError(""));
  };

  return (
    <div>
      {partnerSavedError ? (
        <AlertComp
          type="error"
          message={partnerSavedError}
          handleAlertClose={handleAlertClose}
        />
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          aligntItems: "center",
        }}
      >
        <div onClick={props?.handleBack}>
          <BackIcon />
        </div>
        <HeadingTypography
          color={"#03a5a5"}
          size={"18px"}
          margin={"0px 0px 16px"}
          sx={{ padding: "0px 0px 0px 10px" }}
        >
          NEW PARTNER
        </HeadingTypography>
      </div>

      <Grid container alignItems="center" sx={{ width: "80%" }}>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "5%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              PARTNER TYPE
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={[
                { label: "Business Partner", name: "Business Partner" },
                { label: "Store Partner", name: "Store Partner" },
              ]}
              getOptionLabel={(option) => option.name}
              placeholder="Select"
              onChange={(event, newValue) => {
                handleChange(event, newValue, "userType");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container alignItems="center" width={"80%"}>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              NAME
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="Type Here"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "name")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              MOBILE NUMBER
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="Type Here"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
              onChange={(e) => handleChange(e, "", "phoneNumber")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              EMAIL
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="example@gmail.com"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "email")}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container alignItems="center" width={"80%"}>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              STATE
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={state}
              placeholder="Select"
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                handleChange(event, newValue, "state");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              CITY
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={cities}
              getOptionLabel={(option) => option.name}
              placeholder="Select"
              onChange={(event, newValue) => {
                handleChange(event, newValue, "city");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              ADDRESS
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="Type Here"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "address")}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container alignItems="center" width={"80%"}>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              PAN
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder=""
              variant="outlined"
              onChange={(e) => handleChange(e, "", "panNumber")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              TAN
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder=""
              variant="outlined"
              onChange={(e) => handleChange(e, "", "tanNumber")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              INVOICE TYPE
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={[
                { label: "Automatic", name: "Automatic" },
                { label: "Manual", name: "Manual" },
              ]}
              getOptionLabel={(option) => option.name}
              placeholder="Select"
              onChange={(event, newValue) => {
                handleChange(event, newValue, "invoiceType");
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  fullWidth
                  {...params}
                  placeholder="Select"
                />
              )}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container alignItems="center" width={"80%"}>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              PASSWORD
            </SubLabelTypography>
            <TextField
              fullWidth
              size="small"
              type="password"
              placeholder=""
              variant="outlined"
              onChange={(e) => handleChange(e, "", "password")}
            />
          </div>
        </Grid>
      </Grid>

      <div className={classes.alignStraight} style={{ marginBottom: "20px" }}>
        <SubLabelTypography
          color={"#2b2b2b"}
          size={"14px"}
          sx={{ pb: "0px", mr: "20px" }}
        >
          Partner Have GSTIN?
        </SubLabelTypography>
        <FormControl>
          <RadioGroup
            row
            onChange={(e) =>
              e.target.value === "Yes"
                ? setShowGstin(true)
                : setShowGstin(false)
            }
            value={state?.GSTIN}
          >
            <FormControlLabel
              sx={{ mr: "40px" }}
              value="Yes"
              control={
                <Radio size="small" classes={{ checked: classes?.radio }} />
              }
              label="Yes"
            />
            <FormControlLabel
              sx={{ mr: "40px" }}
              value="No"
              control={
                <Radio size="small" classes={{ checked: classes?.radio }} />
              }
              label="No"
            />
          </RadioGroup>
        </FormControl>
        {showGstin && (
          <TextField
            fullWidth
            type="text"
            placeholder=""
            variant="outlined"
            onChange={(e) => handleChange(e, "", "gstNumber")}
          />
        )}
      </div>
      <ContinueButton marginLeft={"0px"} onClick={handleSubmit}>
        Submit
      </ContinueButton>
      <SuccessModal
        success={success}
        successHeader={"Partner Added Successfully"}
        succesSubHeader={succesSubHeader}
        button={"View Partner"}
        handleSuccessClose={handleSuccessClose}
      ></SuccessModal>
    </div>
  );
};

export default NewPartners;
