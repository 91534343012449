import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { useEffect,} from "react";
import {
  ContinueButton,
  HeadingTypography,
  SubLabelTypography,
} from "../../../components";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeDropdown } from "../../../app/reducers/storeSlice";
import _ from "lodash";

const employeeRole = [
  { name: "Optometrist", value: "optometrists"},
  { name: "Sales Executive", value: "salesExecutives"},
  { name: "Field Assistant", value: "fieldAssistants"},
  { name: "Store Manager", value: "storeManagers"}];

const AssignEmployees = (props) => {
  const dispatch = useDispatch();

  const dropdown = useSelector((state) => state.storeReducer.employeeDropdown)

  useEffect(() => {
    dispatch(getEmployeeDropdown())
  }, [dispatch])

  
  const handleChange = (e, newValue, name,i) => {
    props?.handleChange(e, newValue, name, i);
  };

  const handleAddEmployee = () =>{
    props?.handleAddEmployee();
  }
  
  useEffect(() => {

  })
  const handleDelete = (i) =>{
    props?.deleteEmployee(i);
  }
 
  return (
    <div>
      <HeadingTypography
        color={"#03a5a5"}
        size={"18px"}
        margin={"0px 0px 16px"}
      >
        ASSIGN EMPLOYEES
      </HeadingTypography>
      {props?.employeeDetails.map((val, i) => (
        <Grid container alignItems="center">
          <Grid item xs={4} sm={4} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
            <div>
              <SubLabelTypography
                color={"#2b2b2b"}
                size={"14px"}
                sx={{ pb: "8px" }}
              >
                EMPLOYEE NAME/ID
              </SubLabelTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={dropdown}
                getOptionLabel={(option) => option.name}
                value={val?.employeeName}
                placeholder="Select"
                  onChange={(event, newValue) => {
                    handleChange(event, newValue, "employeeName", i);
                  }}
                renderInput={(params) => (
                  <TextField fullWidth {...params} placeholder="Select" />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} sx={{ pr: "5%", pb: "1.5%" }}>
            <div>
              <SubLabelTypography
                color={"#2b2b2b"}
                size={"14px"}
                sx={{ pb: "8px" }}
              >
                ROLE
              </SubLabelTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={employeeRole}
                value={val?.role}
                getOptionLabel={(option) => option.name}
                placeholder="Select"
                  onChange={(event, newValue) => {
                    handleChange(event, newValue, "role", i);
                  }}
                renderInput={(params) => (
                  <TextField fullWidth {...params} placeholder="Select" />
                )}
              />
            </div>
          </Grid>
          {i === (props?.employeeDetails?.length -1) ? 
          <Grid item xs={3}>
            <ContinueButton onClick={handleAddEmployee}>Add Employee</ContinueButton>
          </Grid>
          : <div onClick={()=>handleDelete(i)} style={{cursor:"pointer"}}><DeleteIcon /></div>}
        </Grid>
      ))}
    </div>
  );
};

export default AssignEmployees;
