import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HeadingTypography,
  ListTable,
  UpdateStatus,
} from "../../../components";
import StorePartnerOrders from "./StorePartnerOrders";
import { useStyles } from "./styles";
import {
  getStorePartnerList,
  getPayStorePartner,
  setPagenoStorePartner,
  clearStorePartner
} from "../../../app/reducers/storePartnerSlice";
import moment from "moment";
import _ from "lodash";

function StorePartner(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const storePartnerList = useSelector(
    (state) => state.storePartnerReducer.storePartnerList
  );
  const updatePayment = useSelector(
    (state) => state.storePartnerReducer.updatePayment
  );
  const pageNo = useSelector(
    (state) => state.storePartnerReducer.pageNoStorePartner
  );
  const hasMore = useSelector(
    (state) => state.storePartnerReducer.hasMoreStorePartner
  );

  const [updateStatus, setUpdateStatus] = useState(false);
  const [dateValue, setDateValue] = useState({});
  const [state, setState] = useState({});
  const [order, setOrder] = useState(false);
  const [indentData, setIndentData] = useState([]);
  const [details, setDetails] = useState({});
  const [selectedPartner, setSelectedPartner] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [storePartnerList]);

  useEffect(() => {
    dispatch(clearStorePartner());
    dispatch(getStorePartnerList(0));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(updatePayment)) {
      setUpdateStatus(false);
      dispatch(clearStorePartner());
      dispatch(getStorePartnerList(0));
    }
  }, [updatePayment]);

  let tableData = [];

  storePartnerList?.map((data) => {
    const storePartner = {
      id: data?.partnerId + "/" + data?.partnerName,
      orders: data?.totalOrders,
      orderDate:  moment(data?.orderDate).format("ll"),
      amount: Number(data?.totalPayables).toFixed(2),
      transaction: data?.paid
        ? data?.storePartnerPayment?.transactionId
        : "-",
      status: data?.paid ? "PAID" : "PENDING",
      data: data,
    };
    tableData.push(storePartner);
    return storePartner;
  });

  const handleDateChange = (newValue, name) => {
    const date = new Date(newValue).toISOString();
    setDateValue({ ...dateValue, [name]: newValue });
    setState({ ...state, [name]: date });
  };

  const handleChange = (e, type, name) => {
    if (type === "text") {
      setState({ ...state, [name]: e.target.value });
    } else {
      setState({ ...state, [name]: { label: type.label, value: type.value } });
    }
  };

  const handleSubmitUpdate = () => {
    const obj = {
      partnerId: selectedPartner?.partnerId,
      orderDate: selectedPartner?.orderDate,
      transactionAmount: selectedPartner?.totalPayables,
      ...state,
    };
    dispatch(getPayStorePartner(obj));
  };

  const handleUpdateStatus = (e, data) => {
    setSelectedPartner(data?.data);
    setUpdateStatus(true);
  };

  const handleCloseUpdate = () => {
    setUpdateStatus(false);
  };

  const handleViewClick = (e, data) => {
    setOrder(true);
    setDetails(data.data);
    setIndentData(data.data.orders);
  };

  const handleOrdersClose = () => {
    setOrder(false);
  };

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoStorePartner(pageNo + 1));
    dispatch(getStorePartnerList(pageNo + 1));
  };

  return (
    <>
      {order ? (
        <StorePartnerOrders
          handleOrdersClose={handleOrdersClose}
          storePartnerOrder={indentData}
          details={details}
        />
      ) : (
        <div className={classes.root}>
          <HeadingTypography sx={{ mb: "2%" }}>
            Store Partners Payment
          </HeadingTypography>
          <ListTable
            dataList={tableData}
            handleViewClick={handleViewClick}
            handleUpdateStatus={handleUpdateStatus}
            isLoading={loading}
            handleLoadmore={handleLoadmore}
            hasMore={hasMore}
            Header={[
              "Order Date",
              "Partner ID/Name",
              "Total Orders",
              "Payable Amount",
              "Transaction ID/Date",
              "Status",
              "",
              "",
            ]}
            tableData={[
              { type: ["TEXT"], name: "orderDate"},
              { type: ["TEXT"], name: "id" },
              { type: ["TEXT"], name: "orders" },
              { type: ["TEXT"], name: "amount" },
              { type: ["TEXT"], name: "transaction" },
              { type: ["STATUS"], name: "status" },
              { type: ["VIEW_ORDERS"], name: "" },
              { type: ["UPDATE_STATUS"], name: "" },
            ]}
          />
          <UpdateStatus
            updateStatus={updateStatus}
            handleCloseUpdate={handleCloseUpdate}
            handleDateChange={handleDateChange}
            dateValue={dateValue}
            handleChange={handleChange}
            partnerDetails={selectedPartner}
            handleSubmitUpdate={handleSubmitUpdate}
          />
        </div>
      )}
    </>
  );
}

export default StorePartner;
