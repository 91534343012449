import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
  setPartnerList,
  getPartnerList,
  getSavePartner,
  setSavePartner,
  getStorePartnerDetails,
  setStorePartnerDetails,
  setSavePartnerError,
  getEditPartner,
  getEditPartnerSuccess,
  getEditPartnerError,
} from "../reducers/partnerSlice";

async function fetchPartnersAPI(payload) {
  const userData = await axios
    .get("/client/partner/fetchpartners", {
      params: {
        limit: 15,
        page: payload,
        filter: {},
        sort: { _created_at: -1 },
      },
    })
    .then((response) => response.data.data);
  return userData;
}
export function* fetchPartners(action) {
  try {
    const response = yield call(fetchPartnersAPI, action.payload);
    yield put(setPartnerList(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function savePartnerAPI(payload) {
  const userData = await axios
    .post("/client/partner/createpartner", payload)
    .then((response) => response);
  return userData;
}
export function* savePartner(action) {
  try {
    const { payload } = action;
    const response = yield call(savePartnerAPI, payload);
    yield put(setSavePartner(response.data.data));
  } catch (error) {
    yield put(setSavePartnerError(error.response.data.message));
  }
}

async function editPartnerAPI(payload) {
  const userData = await axios
    .post("/client/partner/updatepartner", payload)
    .then((response) => response);
  return userData;
}
export function* editPartner(action) {
  try {
    const { payload } = action;
    const response = yield call(editPartnerAPI, payload);
    yield put(getEditPartnerSuccess(response.data.data));
  } catch (error) {
    yield put(getEditPartnerError(error.response.data.message));
  }
}

async function fetchStorePartnerDetailsAPI(payload) {
  const userData = await axios
    .get("/client/user/storesbyusertype", {
      params: {
        user_id: payload?.id,
        userType: payload?.type,
      },
    })
    .then((response) => response.data.data);
  return userData;
}
export function* fetchStorePartnerDetails(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchStorePartnerDetailsAPI, payload);
    yield put(setStorePartnerDetails(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* partnerSaga() {
  yield takeLatest(getPartnerList.type, fetchPartners);
  yield takeLatest(getSavePartner.type, savePartner);
  yield takeLatest(getEditPartner.type, editPartner);
  yield takeLatest(getStorePartnerDetails.type, fetchStorePartnerDetails);
}
