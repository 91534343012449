import React, { useState } from "react";
import { HeadingTypography } from "../../../components";
import { useStyles } from "./styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemText,
  Grow
} from "@mui/material";

function CollapseDrop(props) {
  const classes = useStyles();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selected, setSelected] = useState("All");

  const handleSelected = (val, type) => {
    setSelected(val);
    setDropDownOpen(!dropDownOpen);
    props?.handleSelected(val, type)
  };
  const lengthOfData = props?.dropDownData?.length
  return (
    <div style={{ position: "relative" }}>
      <div className={classes.alignStraight}>
        <div style={{ fontSize: 12, color: "#827e7e", marginRight: "6px" }}>
          {props?.header}:{" "}
        </div>
        <HeadingTypography weight={500} size={"13px"} style={{ margin: "0px 6px" }}>{props?.selectedValue}</HeadingTypography>
        <div onClick={() => setDropDownOpen(!dropDownOpen)}>
          {dropDownOpen ? (
            <ExpandLess sx={{ color: "#827e7e",cursor:"pointer" }} />
          ) : (
            <ExpandMore sx={{ color: "#827e7e",cursor:"pointer" }} />
          )}
        </div>
      </div>
      <Grow in={dropDownOpen} className={classes.collapseRoot} style={{ width: props?.dropWidth ?? "auto", overflow: "auto", height: lengthOfData > 10 ? "400px" : "auto" }}>
        <List component="div" disablePadding>
          {(props?.dropDownData)?.map((item, i) => (
            <ListItemButton
              key={i}
              sx={{ padding: "2px" }}
              onClick={() => handleSelected(item, props?.state)}
            >
              <ListItemText
                sx={{
                  "&.MuiCollapse-root": {
                    fontSize: 6,
                    color: "#827e7e",
                  },
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {item}
              </ListItemText>
            </ListItemButton>
          ))}
        </List>
      </Grow>
    </div>
  );
}

export default CollapseDrop;
