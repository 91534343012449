import { Dialog, DialogContent } from '@mui/material';
import React from 'react';

function GeneralDataModal(props) {
    return (
        <Dialog PaperProps={{ classes:{root:props.paperWidth}}} open={props?.generalDialog} onClose={props?.handleGeneralDialogClose}>
            <DialogContent>
                {props.bodyComp}
            </DialogContent>
        </Dialog>
    );
}

export default GeneralDataModal;