import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  indentsList: [],
  intendsOrder: [],
  paymentUpdated: {},
  pageNoIndents: 0,
  hasMoreIndents: true,
};

export const indentsSlice = createSlice({
  name: "indents",
  initialState,
  reducers: {
    getIndentsList() {},
    setIndentsList(state, action) {
      return Object.assign({}, state, {
        indentsList:
          state.pageNoIndents > 0
            ? [...state.indentsList, ...action.payload]
            : isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreIndents: action.payload.length < 15 ? false : true,
      });
    },
    setPagenoIndents(state, action) {
      return Object.assign({}, state, {
        pageNoIndents: action.payload,
      });
    },
    getIndentsOrders() {},
    setIndentsOrders(state, action) {
      return Object.assign({}, state, {
        intendsOrder: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getUpdatePaymentIndent() {},
    setUpdatePaymentIndent(state, action) {
      return Object.assign({}, state, {
        paymentUpdated: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    clearIndents(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  setIndentsList,
  getIndentsList,
  getIndentsOrders,
  setIndentsOrders,
  getUpdatePaymentIndent,
  setUpdatePaymentIndent,
  clearIndents,
  setPagenoIndents
} = indentsSlice.actions;

export default indentsSlice.reducer;
