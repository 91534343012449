import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
  getStoreList,
  setStoreList,
  getEmployeeDropdown,
  setEmployeeDropdown,
  getPartnerDropdown,
  setPartnerDropdown,
  getStoreAdd,
  setStoreAdd,
  getStorePartnerDetails,
  setStorePartnerDetails,
  getStoreEdit,
  getStoreEditSuccess,
  getStoreEditError,
  setStoreAddError
} from "../reducers/storeSlice";

async function fetchStoresAPI(payload) {
  const userData = await axios
    .get("/client/store/fetchstores", {
      params: {
        limit: 15,
        page: payload,
        filter: {},
        sort: { _created_at: -1 },
      },
    })
    .then((response) => response.data.data);
  return userData;
}
export function* fetchStores(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchStoresAPI, payload);
    yield put(setStoreList(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function fetchEmployeeDropdownAPI(payload) {
  const userData = await axios
    .get("/client/user/employeesdropdown", {
      params: {
        limit: 30,
        page: 0,
        filter: {},
        sort: { _created_at: -1 },
      },
    })
    .then((response) => response.data.data);
  return userData;
}
export function* fetchEmployeeDropdown(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchEmployeeDropdownAPI, payload);
    yield put(setEmployeeDropdown(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function fetchPartnerDropdownAPI(payload) {
  const userData = await axios
    .get("/client/partner/partnersdropdown", {
      params: {
        limit: 30,
        page: 0,
        filter: {},
        sort: { _created_at: -1 },
      },
    })
    .then((response) => response.data.data);
  return userData;
}
export function* fetchPartnerDropdown(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchPartnerDropdownAPI, payload);
    yield put(setPartnerDropdown(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function fetchStoreAddAPI(payload) {
  const userData = await axios
    .post("/client/store/createstore", payload)
    .then((response) => response.data.data);
  return userData;
}
export function* fetchStoreAdd(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchStoreAddAPI, payload);
    yield put(setStoreAdd(response));
  } catch (error) {
    yield put(setStoreAddError(error.response.data.message));
  }
}

async function fetchStoreEditAPI(payload) {
  const userData = await axios
    .post("/client/store/updatestore", payload)
    .then((response) => response.data.data);
  return userData;
}
export function* fetchStoreEdit(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchStoreEditAPI, payload);
    yield put(getStoreEditSuccess(response));
  } catch (error) {
    yield put(getStoreEditError(error.response.data.message));
  }
}

async function fetchStorePartnerDetailsAPI(payload) {
  const userData = await axios
    .get("/client/user/storeusersbyid", {
      params: {
        storeId: payload,
      },
    })
    .then((response) => response.data.data);
  return userData;
}
export function* fetchStorePartnerDetails(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchStorePartnerDetailsAPI, payload);
    yield put(setStorePartnerDetails(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* storeSaga() {
  yield takeLatest(getStoreList.type, fetchStores);
  yield takeLatest(getEmployeeDropdown.type, fetchEmployeeDropdown);
  yield takeLatest(getPartnerDropdown.type, fetchPartnerDropdown);
  yield takeLatest(getStoreAdd.type, fetchStoreAdd);
  yield takeLatest(getStoreEdit.type, fetchStoreEdit);
  yield takeLatest(getStorePartnerDetails.type, fetchStorePartnerDetails);
}
