import React from "react";

export const RuppeeIcon = (props) => {
  const width = props?.width ?? "24";
  const height = props?.height ?? "24";
  const color = props?.color ?? "#353535";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g data-name="Group 1849">
        <path
          d="M13 20H7c-5 0-7-2-7-7V7c0-5 2-7 7-7h6c5 0 7 2 7 7v6c0 5-2 7-7 7z"
          transform="translate(2 2)"
        //   style="fill:#fff"
        fill={"#fff"}
          data-name="vuesax/twotone/dollar-square"
        />
        <path
          data-name="Path 2936"
          d="M100.355 0h-6.83v1.357h2.409a2.039 2.039 0 0 1 1.919 1.357h-4.328v1.357h4.328a2.039 2.039 0 0 1-1.919 1.357h-2.409v1.357l3.975 3.977.96-.96-3.016-3.017h.49a3.4 3.4 0 0 0 3.324-2.714h1.1V2.714h-1.1a3.378 3.378 0 0 0-.612-1.357h1.709z"
          transform="translate(-84.525 7)"
        //   
        fill={"#353535"}
        />
      </g>
    </svg>
  );
};
