import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
    setSaveCoupons,
    getSaveCoupons,
    getCouponsList,
    setCouponsList,
} from "../reducers/couponSlice";

async function fetchCouponsAPI(payload) {
  const userData = await axios
    .get('/client/couponcode/fetch', {
      params: {
        limit: 15,
        page: payload,
        filter: {},
        sort: { _created_at: -1 },
        search: { }
      }
    })
    .then((response) => response.data.data);
  return userData;
}

export function* fetchCoupons(action) {
  try {
    const response = yield call(fetchCouponsAPI, action.payload);
    yield put(setCouponsList(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}


async function saveCouponsAPI(payload) {
  const userData = await axios
    .post('/client/couponcode/add', payload)
    .then((response) => response.data.data);
  return userData;
}
export function* saveCoupons(action) {
  try {
    const { payload } = action;
    const response = yield call(saveCouponsAPI, payload);
    yield put(setSaveCoupons(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}


export function* couponSaga() {
  yield takeLatest(getCouponsList.type, fetchCoupons);
  yield takeLatest(getSaveCoupons.type, saveCoupons);
}
