import React from "react";
// import { Sidebar, NavBar } from "../../components";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  content: {
    width: '100%',
    height: 'calc(100vh - 64px)',
    overflow: "auto",
  },
})


const withNavBars = (Component) => (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    // <div >
      
    //   <Sidebar handleDrawerClose={handleDrawerClose} open={open} />

    //   <div style={{backgroundColor:"#f5f5f5", width:"100%", height:"100%"}} className={classes.content}>
    //       <NavBar handleDrawerOpen={handleDrawerOpen} />
    //       <Component {...props}>{props.children}</Component>
    //   </div>
    // </div>
    <div></div>
  );
};

export default withNavBars;
