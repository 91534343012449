import React, { useEffect, useState } from "react";
import {
  ContinueShoppingButton,
  HeadingTypography,
  ListTable,
  ContinueButton,
} from "../../../components";
import {
  Dialog,
  DialogContent,
} from "@mui/material";
import { useStyles } from "./styles";
import { CollapseDrop } from "../Components";
import { useDispatch, useSelector } from "react-redux";
import {
  getTerminal,
  setPagenoTerminal,
  getConfirmPayment,
  clearPOS
} from "../../../app/reducers/posTerminalSlice";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import DatePicker from "react-multi-date-picker"
import axios from "../../../axiosInstance";

const dateArray = [ "All", "Today", "Yesterday", "Custom" ];

function PosTerminal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const terminalList = useSelector(
    (state) => state.posTerminalReducer.terminalList
  );
  const pageNo = useSelector(
    (state) => state.posTerminalReducer.pageNoTerminal
  );
  const hasMore = useSelector(
    (state) => state.posTerminalReducer.hasMoreTerminal
  );
  const confirmPayment = useSelector(
    (state) => state.posTerminalReducer.confirmPayment
  );

  const today = moment();
  const tomorrow = moment().add(1, 'day');
  const yesterday = moment().subtract(1, 'day');

  const [values, setValues] = useState([today, tomorrow]);
  const [storeNameArray, setStoreNameArray] = useState(["All"]);
  const [storeIdArray, setStoreIdArray] = useState(["0"]);
  const [selected, setSelected] = useState({
    pageNo: 0,
    paymentType: "Card",
    store: "All",
    date: dateArray[0],
    startDate: today.format('YYYY-MM-DD'),
    endDate: "",
    storeId: "0",
  });
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [openDatepicker, setOpenDatepicker] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [terminalList]);

  useEffect(() => {
    loadStore();
    // dispatch(clearPOS());
    // dispatch(getTerminal(selected));
  }, []);

  const loadStore = async () => {
    const stores = await axios.get(
      '/client/store/getStoreList', {
      params: {
        limit: 15,
        page: 0,
        filter: {},
        sort: { _created_at: -1 },
      },
    }).then((response) => response.data.data);

    Object.values(stores).forEach((store) => {
      storeIdArray.push(store.storeId);
      storeNameArray.push(store.name);
      return values;
    });
  }

  useEffect(() => {
    if (!_.isEmpty(confirmPayment)) {
      setOpenDialog(false);
      dispatch(clearPOS());
      dispatch(getTerminal(selected));
    }
  }, [confirmPayment]);

  const handleSelected = (val, type) => {
    if (type === "date") {
      let startDate = today.format('YYYY-MM-DD');
      let endDate = "";
      if (val === "Yesterday" || val === "Today") {
        setOpenDatepicker(false);
        startDate = val === "Yesterday" ? yesterday.format('YYYY-MM-DD') : today.format('YYYY-MM-DD');
        endDate = val === "Yesterday" ? yesterday.format('YYYY-MM-DD') : today.format('YYYY-MM-DD');
      } else if (val === "Custom") {
        setOpenDatepicker(true);
        startDate = values[0].format('YYYY-MM-DD');
        endDate = values[1].format('YYYY-MM-DD');
      } else {
        setOpenDatepicker(false);
      }
      setSelected({ ...selected, "pageNo": 0, [type]: val, "startDate": startDate, "endDate": endDate });
    } else if (type === "store") {
      if (storeNameArray.indexOf(val) > -1 && storeIdArray[storeNameArray.indexOf(val)]) {
        setSelected({ ...selected, "pageNo": 0, [type]: val, "storeId": storeIdArray[storeNameArray.indexOf(val)] });
      }
    }
  };

  const handleDatePickerClose = () => {
    setSelected({ ...selected, "pageNo": 0, "date": "Custom", "startDate": values[0].format('YYYY-MM-DD'), "endDate": values[1].format('YYYY-MM-DD') });
  }

  useEffect(() => {
    // dispatch(clearPOS());
    dispatch(setPagenoTerminal(selected));
    dispatch(getTerminal(selected));
  }, [selected]);

  const handleViewClick = (e, data) => {
    setOpenDialog(true);
    setDialogData(data);
  };

  const handleSubmit = () => {
    const obj = {
      orderId: dialogData.orderId,
      payment_id: dialogData.payment_id,
    };
    dispatch(getConfirmPayment(obj));
  };

  let tableData = [];

  terminalList?.map((terminal) => {
    const terminalData = {
      storeId: terminal?.storeId,
      orderId:
        terminal?.orderId,
      orderDate: moment(terminal?.orderDate).format("ll"),
      mode: terminal?.paymentMode,
      transactionDate: moment(terminal?.transactionDate).format("llll"),
      cardNum: terminal?.paymentMode !== "Cash" ? "-" : terminal?.comments,
      amount: Number(terminal?.amount).toFixed(2),

    };
    tableData.push(terminalData);
    return terminalData;
  });

  const handleLoadmore = () => {
    setLoading(true);
    setSelected({ ...selected, "pageNo": pageNo + 1 })
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.alignStraightBetween}
        style={{ alignItems: "flex-start" }}
      >
        <HeadingTypography sx={{ mb: "2%" }}>
          Card
        </HeadingTypography>
        <div
          className={classes.alignStraight}
          style={{ alignItems: "flex-start" }}
        >
          <div style={{ marginRight: "8px" }}>
            <CollapseDrop
              header={"STORE"}
              dropDownData={storeNameArray}
              state={"store"}
              handleSelected={handleSelected}
              selectedValue={selected?.store}
              dropWidth={"175px"}
            />
          </div>

          <div style={{ marginRight: "8px" }}>
            <CollapseDrop
              header={"DATE"}
              dropDownData={dateArray}
              state={"date"}
              handleSelected={handleSelected}
              selectedValue={selected?.date}
            />
            <div>
              {
                openDatepicker ? <DatePicker
                  range
                  value={values}
                  format={'YYYY-MM-DD'}
                  onChange={setValues}
                  onClose={handleDatePickerClose} />
                  : ""
              }
            </div>
          </div>
        </div>
      </div>
      <ListTable
        dataList={tableData}
        handleViewClick={handleViewClick}
        // handleUpdateStatus={handleUpdateStatus}
        isLoading={loading}
        handleLoadmore={handleLoadmore}
        hasMore={hasMore}
        Header={[
          "Store ID",
          "Order ID",
          "Order Date ",
          "Transaction ",
          "Transaction Date/Time",
          " Card Number",
          "Actual",
          "Credited",
          "Type",
          "",
        ]}
        tableData={[
          { type: ["TEXT"], name: "storeId" },
          { type: ["TEXT"], name: "orderId" },
          { type: ["TEXT"], name: "orderDate" },
          { type: ["TEXT"], name: "mode" },
          { type: ["TEXT"], name: "transactionDate" },
          { type: ["TEXT"], name: "cardNum" },
          { type: ["PAYMENT"], name: "amount" },
          { type: ["TEXT"], name: "-" },
          { type: ["TEXT"], name: "-" },
          { type: ["PAYMENT"], name: "payment" },
        ]}
      />
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <div style={{ width: "350px", height: "180px" }}>
            <div
              className={classes.alignStraightBetween}
              style={{ marginBottom: "12px" }}
            >
              <HeadingTypography weight={"600"}>
                Confirm Payment
              </HeadingTypography>
              <div
                style={{ cursor: "pointer" }}
                onClick={props?.handleCloseUpdate}
              >
                <CloseIcon sx={{ fontSize: "12px", color: "#827e7e" }} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <HeadingTypography
                weight={"500"}
                size={"13px"}
                sx={{ marginBottom: "10px", marginRight: "5px" }}
              >
                DEPOSIT AMOUNT:
              </HeadingTypography>
              <HeadingTypography
                weight={"700"}
                size={"13px"}
                sx={{ marginBottom: "10px" }}
              >
                {dialogData?.amount}
              </HeadingTypography>
            </div>

            <HeadingTypography
              weight={"500"}
              size={"13px"}
              sx={{ marginBottom: "20px" }}
            >
              Full Amount Recieved Successfully ?{" "}
            </HeadingTypography>
            <ContinueShoppingButton
              margin={"0px"}
              onClick={() => setOpenDialog(false)}
            >
              Cancel
            </ContinueShoppingButton>
            <ContinueButton marginLeft={"10px"} onClick={handleSubmit}>
              Submit
            </ContinueButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default PosTerminal;
