import React from "react";

export const CircleTick = (props) => {
  const width = props?.width ?? "33.521";
  const height = props?.height ?? "33.521";
  const color = props?.color ?? "#03a5a5";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 33.521 33.521"
    >
      <path
        d="M13.967 0a13.967 13.967 0 1 0 13.968 13.967A13.99 13.99 0 0 0 13.967 0zm6.676 10.755-7.919 7.919a1.045 1.045 0 0 1-1.481 0l-3.952-3.952a1.047 1.047 0 0 1 1.481-1.481l3.212 3.212 7.179-7.179a1.047 1.047 0 0 1 1.481 1.481z"
        transform="translate(2.793 2.793)"
        fill={color}
        data-name="vuesax/bold/tick-circle"
      />
    </svg>
  );
};
