import React, { useEffect, useState } from "react";
import {
  ContinueButton,
  HeadingTypography,
  ListTable,
  SubLabelTypography,
  GeneralDataModal,
} from "../../../components";
import { useStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStoreReducer,
  getStoreList,
  getStorePartnerDetails,
  setPagenoStore
} from "../../../app/reducers/storeSlice";
import ViewStore from "./ViewStore";
import _ from "lodash";

const Stores = (props) => {
  const classes = useStyles();
  const [generalDialog, setGeneralDialog] = useState(false);
  const history = useNavigate();
  const dispatch = useDispatch();

  const storeList = useSelector((state) => state.storeReducer.storeList);
  const storePartner = useSelector(
    (state) => state.storeReducer.storePartnerDetails
  );
  const storesEditedSuccess = useSelector(
    (state) => state.storeReducer.storesEditedSuccess
  );
  const storesAdded = useSelector((state) => state.storeReducer.storesAdded);
  const pageNo = useSelector((state) => state.storeReducer.pageNoStore);
  const hasMore = useSelector((state) => state.storeReducer.hasMoreStore);

  const [viewStore, setViewStore] = useState(false);
  const [rowData, setRowData] = useState({});
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setLoading(false);
  }, [storeList]);

  useEffect(() => {
    dispatch(clearStoreReducer());
    dispatch(getStoreList(0));
  }, []);

  
  useEffect(() => {
    if (!_.isEmpty(storesAdded)) {
      dispatch(clearStoreReducer());
      dispatch(getStoreList(0));
    }
  }, [storesAdded]);

  useEffect(() => {
    if (!_.isEmpty(storesEditedSuccess)) {
      dispatch(clearStoreReducer());
      dispatch(getStoreList(0));
    }
  }, [storesEditedSuccess]);

  let tableData = [];

  storeList?.map((val) => {
    const storeData = {
      type: val?.storeType,
      id: val?.storeId,
      name: val?.name,
      district: val?.district,
      city: val?.city,
      pincode: val?.pincode,
      data: val,
    };
    tableData.push(storeData);

    return tableData;
  });

  const handleUser = async (e, data) => {
    dispatch(getStorePartnerDetails(data?.id));
    setGeneralDialog(true);
  };

  const handleCloseGeneralDialog = () => {
    setGeneralDialog(false);
  };

  const handleNewStore = () => {
    history("/new-store");
  };

  const handleBack = () => {
    setViewStore(false);
  };

  const handleStatusClick = (e, data) => {
    setViewStore(true);
    setRowData(data.data);
  };

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoStore(pageNo + 1));
    dispatch(getStoreList(pageNo + 1));
  };
  
  let temp = [];
  const businessPartnerModalData =
    storePartner[0]?.storeBusinessPartners[0]?.name || "-";
  const storeFieldAssistants =
    storePartner[0]?.storeFieldAssistants[0]?.name || "-";
  const storeManagers = storePartner[0]?.storeManagers[0]?.name || "-";
  const storeOptometrists = storePartner[0]?.storeOptometrists[0]?.name || "-";
  const storeSalesExecutives =
    storePartner[0]?.storeSalesExecutives[0]?.name || "-";

  const BodyComp = () => {
    return (
      <div style={{ width: "320px" }}>
        <div
          className={classes.alignStraightBetween}
          style={{ marginBottom: "12px" }}
        >
          <HeadingTypography>Products</HeadingTypography>
          <div style={{ cursor: "pointer" }} onClick={handleCloseGeneralDialog}>
            <CloseIcon sx={{ fontSize: "12px", color: "#827e7e" }} />
          </div>
        </div>
        <div className={classes.alignStraight} style={{ marginBottom: "8px" }}>
          <SubLabelTypography>STORE PARTNER:</SubLabelTypography>
          <HeadingTypography weight={500} size={"13px"}>
            {storePartner[0]?.storePartners?.map((data, index) => {
              if (index === storePartner[0].storePartners.length - 1) {
                return <span>{data?.name}</span>;
              } else {
                return <span>{data?.name},</span>;
              }
            }) || "-"}
          </HeadingTypography>
        </div>
        <div className={classes.alignStraight} style={{ marginBottom: "8px" }}>
          <SubLabelTypography>BUSINESS PARTNER:</SubLabelTypography>
          <HeadingTypography weight={500} size={"13px"}>
            {storePartner[0]?.storeBusinessPartners?.map((data, index) => {
              if (index === storePartner[0].storeBusinessPartners.length - 1) {
                return <span>{data?.name}</span>;
              } else {
                return <span>{data?.name},</span>;
              }
            }) || "-"}
          </HeadingTypography>
        </div>

        <div
          className={classes.alignStraightBetween}
          style={{ marginBottom: "12px" }}
        >
          <HeadingTypography>Employees</HeadingTypography>
        </div>
        <div className={classes.alignStraight} style={{ marginBottom: "8px" }}>
          <SubLabelTypography>STORE MANAGER:</SubLabelTypography>
          <HeadingTypography weight={500} size={"13px"}>
            {storePartner[0]?.storeManagers?.map((data, index) => {
              if (index === storePartner[0].storeManagers.length - 1) {
                return <span>{data?.name}</span>;
              } else {
                return <span>{data?.name},</span>;
              }
            }) || "-"}
          </HeadingTypography>
        </div>
        <div className={classes.alignStraight} style={{ marginBottom: "8px" }}>
          <SubLabelTypography>SALES EXECUTIVE:</SubLabelTypography>
          <HeadingTypography weight={500} size={"13px"}>
            {storePartner[0]?.storeSalesExecutives?.map((data, index) => {
              if (index === storePartner[0].storeSalesExecutives.length - 1) {
                return <span>{data?.name}</span>;
              } else {
                return <span>{data?.name},</span>;
              }
            }) || "-"}
          </HeadingTypography>
        </div>
        <div className={classes.alignStraight} style={{ marginBottom: "8px" }}>
          <SubLabelTypography>OPTOMETRIST:</SubLabelTypography>
          <HeadingTypography weight={500} size={"13px"}>
            {storePartner[0]?.storeOptometrists?.map((data, index) => {
              if (index === storePartner[0].storeOptometrists.length - 1) {
                return <span>{data?.name}</span>;
              } else {
                return <span>{data?.name},</span>;
              }
            }) || "-"}
          </HeadingTypography>
        </div>
        <div className={classes.alignStraight} style={{ marginBottom: "8px" }}>
          <SubLabelTypography>HELPER:</SubLabelTypography>
          <HeadingTypography weight={500} size={"13px"}>
            {storePartner[0]?.storeFieldAssistants?.map((data, index) => {
              if (index === storePartner[0].storeFieldAssistants.length - 1) {
                return <span>{data?.name}</span>;
              } else {
                return <span>{data?.name},</span>;
              }
            }) || "-"}
          </HeadingTypography>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={classes.root}>
        {viewStore ? (
          <ViewStore handleBack={handleBack} row={rowData} />
        ) : (
          <>
            <>
              <div
                className={classes.alignStraightBetween}
                style={{ marginBottom: "4px" }}
              >
                <HeadingTypography>Stores</HeadingTypography>
                <div style={{ padding: "0px 0px 5px 0px" }}>
                  <ContinueButton onClick={handleNewStore}>
                    New Store
                  </ContinueButton>
                </div>
              </div>
              <ListTable
                dataList={tableData}
                handleUser={handleUser}
                handleStatusClick={handleStatusClick}
                isLoading={loading}
                handleLoadmore={handleLoadmore}
                hasMore={hasMore}
                Header={[
                  "Type",
                  "ID",
                  "Name",
                  "District",
                  "City/Town/Village",
                  "Pincode",
                  "",
                  "",
                ]}
                tableData={[
                  { type: ["TEXT"], name: "type" },
                  { type: ["TEXT"], name: "id" },
                  { type: ["TEXT"], name: "name" },
                  { type: ["TEXT"], name: "district" },
                  { type: ["TEXT"], name: "city" },
                  { type: ["TEXT"], name: "pincode" },
                  { type: ["ACCOUNTS"], name: "" },
                  { type: ["VIEW_NEW"], name: "" },
                ]}
              />
            </>
            <GeneralDataModal
              generalDialog={generalDialog}
              bodyComp={<BodyComp />}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Stores;
