import {
  Autocomplete,
  Grid,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ContinueButton,
  HeadingTypography,
  SubLabelTypography,
  SuccessModal,
  BackIcon,
  ContinueShoppingButton,
} from "../../../components";
import { useStyles } from "./styles";
import { State, City } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import {
  getStoreEdit,
  getStoreEditError,
} from "../../../app/reducers/storeSlice";
import { AlertComp } from "../../../components/Alert";
import _ from "lodash";
import {
  getPartnerDropdown,
  getEmployeeDropdown,
} from "../../../app/reducers/storeSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from "react-dropzone";
import S3 from "react-aws-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

const ViewStore = (props) => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();


  const dropdown = useSelector((state) => state.storeReducer.partnerDropdown);
  const dropdowns = useSelector((state) => state.storeReducer.employeeDropdown);

  const storesEditedError = useSelector(
    (state) => state.storeReducer.storesEditedError
  );
  const storesEditedSuccess = useSelector(
    (state) => state.storeReducer.storesEditedSuccess
  );

  const [storeData, setStoreData] = useState({});
  const [success, setSuccess] = useState(false);
  const [succesSubHeader, setSuccessSubHeader] = useState("");
  const [enableEdit, setEnableEdit] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [selectedStoreType, setSelectedStoreType] = useState(
    props?.row?.storeType
  );

  const employeeRole = [
    { name: "Optometrist", value: "optometrists" },
    { name: "Sales Executive", value: "salesExecutives" },
    { name: "Field Assistant", value: "fieldAssistants" },
    { name: "Store Manager", value: "storeManagers" }];

  // const getEmployeeRole = (name) => {
  //   let returnData = "";

  //   employeeRole.map((roles) => {
  //     if (roles.value === name) {
  //       returnData = roles.name;
  //     }
  //   });
  //   return returnData;
  // };



  let tempEmployee = [];

  props.row.employeeDetails?.map((emp) => {
    const tempObj = { ...emp };
    tempEmployee.push(tempObj);
  });

  tempEmployee.push({
    employeeName: { userId: "", name: "" },
    role: { name: "" },
  });

  const [employee, setEmployee] = useState(tempEmployee);

  const [dateValue, setDateValue] = useState({
    signingDate: _.isEmpty(props.row.signingDate)
      ? ""
      : new Date(props.row.signingDate),
    activationDate: _.isEmpty(props.row.activationDate)
      ? ""
      : new Date(props.row.activationDate),
    expiringDate: _.isEmpty(props.row.signingDate)
      ? ""
      : new Date(props.row.expiringDate),
    shopEstablishment: _.isEmpty(
      props.row.shopEstablishmentLicense.validityDate
    )
      ? ""
      : new Date(props.row.shopEstablishmentLicense.validityDate),
    storeLicense: _.isEmpty(props.row.storeLicense.validityDate)
      ? ""
      : new Date(props.row.storeLicense.validityDate),
    tradeLicense: _.isEmpty(props.row.tradeLicense.validityDate)
      ? ""
      : new Date(props.row.tradeLicense.validityDate),
    pplLicense: _.isEmpty(props.row.pplLicense.validityDate)
      ? ""
      : new Date(props.row.pplLicense.validityDate),
    rentalAgreement: _.isEmpty(props.row.rentalAgreement.validityDate)
      ? ""
      : new Date(props.row.rentalAgreement.validityDate),
  });

  const [imageValue, setImageValue] = useState({
    storeLicenseImage: _.isEmpty(props?.row.storeLicense.licenseImage)
      ? ""
      : props?.row?.storeLicense.licenseImage,
    tradeLicenseImage: _.isEmpty(props?.row.tradeLicense.licenseImage)
      ? ""
      : props?.row?.tradeLicense.licenseImage,
    shopEstablishmentLicenseImage: _.isEmpty(
      props?.row.shopEstablishmentLicense.licenseImage
    )
      ? ""
      : props?.row?.shopEstablishmentLicense.licenseImage,
    pplLicenseImage: _.isEmpty(props?.row.pplLicense.licenseImage)
      ? ""
      : props?.row?.pplLicense.licenseImage,
    rentalAgreementImage: _.isEmpty(props?.row.rentalAgreement.licenseImage)
      ? ""
      : props?.row?.rentalAgreement.licenseImage,
  });

  const state = State.getStatesOfCountry("IN");
  const cities = City.getCitiesOfState("IN", selectedState);
  let businessPartner = [];
  let storePartner = [];

  const data = _.isEmpty(dropdown)
    ? ""
    : dropdown?.map((value) => {
      if (value?.userType === "Store Partner") {
        storePartner = value?.data;
      } else if (value?.userType === "Business Partner") {
        businessPartner = value?.data;
      }
    });
  const stateValue = {
    name: props.row?.state,
  };

  const cityValue = {
    name: props.row?.city,
  };

  useEffect(() => {
    dispatch(getPartnerDropdown());
    dispatch(getEmployeeDropdown());
  }, []);

  useEffect(() => {
  }, [storeData])

  const handleChange = (event, value, name) => {
    if (name === "state") {
      setSelectedState(value.isoCode);
    }

    if (name === "storePartners" || name === "businessPartners") {
      setStoreData({ ...storeData, [name]: value });
      return 1;
    }

    if (name === "storeType") {
      setStoreData({ ...storeData, [name]: value });
      setSelectedStoreType(value);
    } else {
      if (value) {
        setStoreData({ ...storeData, [name]: value.name });
      } else {
        setStoreData({ ...storeData, [name]: event.target.value });
      }
    }
  };

  const handleDateChange = (value, name) => {
    const date = new Date(value).toISOString();
    setStoreData({ ...storeData, [name]: date });
    setDateValue({ ...dateValue, [name]: value });
  };

  const handleImageChange = async (name, files) => {
    const fileWithPreview = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setStoreData({ ...storeData, [name]: fileWithPreview[0] });
    setImageValue({ ...imageValue, [name]: fileWithPreview[0] });
  };

  const handleEmployeeChange = (event, value, name, index) => {
    if (name === "employeeName") {
      employee[index][name] = { userId: value._id, name: value.name };
      setEmployee([...employee]);
    } else {
      employee[index][name] = value;
      setEmployee([...employee]);
    }
  };

  const handleAddEmployee = () => {
    employee.push({
      employeeName: { userId: "", name: "" },
      role: { name: "" },
    });
    setEmployee([...employee]);
  };

  const handleDelete = (index) => {
    const newObj = _.cloneDeep(
      {},
      employee.filter((el, i) => i !== index)
    );
    setEmployee((prev) => prev.filter((el, i) => i !== index));
  };

  const handleSuccessClose = () => {
    props?.handleBack();
  };

  const config = {
    bucketName: process.env.REACT_APP_AWS_BUCKET,
    dirName: "eyeplay",
    region: "ap-south-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  };

  const ReactS3Client = new S3(config);

  const uploadToS3 = async (file, name) => {
    const rand = Math.random();
    return await ReactS3Client.uploadFile(
      file,
      `Accounts/Store/${storeData?.name || props?.row?.name}${storeData?.storeType || props?.row?.storeType
      }${rand}`
    );
  };

  const imageUpload = async (file, name) => {
    let obj = {};
    const data = await uploadToS3(file, name);
    Object.assign(obj, { [name]: data.location });
    return obj;
  };

  const handleSubmit = async () => {
    const obj = !_.isEmpty(imageValue?.storeLicenseImage.preview)
      ? await imageUpload(imageValue?.storeLicenseImage, "storeLicenseImage")
      : "";
    const obj1 = !_.isEmpty(imageValue?.tradeLicenseImage.preview)
      ? await imageUpload(imageValue?.tradeLicenseImage, "tradeLicenseImage")
      : "";
    const obj2 = !_.isEmpty(imageValue?.shopEstablishmentLicenseImage.preview)
      ? await imageUpload(
        imageValue?.shopEstablishmentLicenseImage,
        "shopEstablishmentLicenseImage"
      )
      : "";
    const obj3 = !_.isEmpty(imageValue?.pplLicenseImage.preview)
      ? await imageUpload(imageValue?.pplLicenseImage, "pplLicenseImage")
      : "";
    const obj4 = !_.isEmpty(imageValue?.rentalAgreementImage.preview)
      ? await imageUpload(
        imageValue?.rentalAgreementImage,
        "rentalAgreementImage"
      )
      : "";

    let tempObj = {};

    employee?.map((employee) => {
      if (employee?.role?.name) {
        let hasOwnProperty = tempObj.hasOwnProperty(employee?.role?.name);

        const employeeDetail = employeeRole.filter(employeeDetail => employeeDetail.name === employee.role.name)[0].value

        if (!hasOwnProperty) {
          tempObj[employeeDetail] = []
        }

        tempObj[employeeDetail].push({
          userId: employee?.employeeName?.userId,
        });
      }
    });

    const storeSaveObj = {
      ...tempObj,
      storeLicense: {
        licenseImage: _.isEmpty(obj.storeLicenseImage)
          ? props?.row.storeLicense.licenseImage
          : obj.storeLicenseImage,
        validityDate: _.isEmpty(storeData?.storeLicense)
          ? props.row.storeLicense.validityDate
          : storeData.storeLicense,
      },
      tradeLicense: {
        licenseImage: _.isEmpty(obj1.tradeLicenseImage)
          ? props?.row.tradeLicense.licenseImage
          : obj.tradeLicenseImage,
        validityDate: _.isEmpty(storeData?.tradeLicense)
          ? props.row.tradeLicense.validityDate
          : storeData.tradeLicense,
      },
      shopEstablishmentLicense: {
        licenseImage: _.isEmpty(obj2.shopEstablishmentLicenseImage)
          ? props?.row.shopEstablishmentLicense.licenseImage
          : obj.shopEstablishmentLicenseImage,
        validityDate: _.isEmpty(storeData?.shopEstablishmentLicense)
          ? props.row.shopEstablishmentLicense.validityDate
          : storeData.shopEstablishmentLicense,
      },
      pplLicense: {
        licenseImage: _.isEmpty(obj3.pplLicenseImage)
          ? props?.row.pplLicense.licenseImage
          : obj.pplLicenseImage,
        validityDate: _.isEmpty(storeData?.pplLicense)
          ? props.row.pplLicense.validityDate
          : storeData.pplLicense,
      },
      rentalAgreement: {
        licenseImage: _.isEmpty(obj4.rentalAgreementImage)
          ? props?.row.rentalAgreement.licenseImage
          : obj.rentalAgreementImage,
        validityDate: _.isEmpty(storeData?.rentalAgreement)
          ? props.row.rentalAgreement.validityDate
          : storeData.rentalAgreement,
      },
      signingDate: _.isEmpty(storeData?.signingDate)
        ? props.row.signingDate
        : storeData.signingDate,
      expiringDate: _.isEmpty(storeData?.expiringDate)
        ? props.row.signingDate
        : storeData.expiringDate,
      activationDate: _.isEmpty(storeData?.activationDate)
        ? props.row.activationDate
        : storeData.activationDate,
      phoneNumber: _.isEmpty(storeData?.phoneNumber)
        ? props.row.phoneNumber
        : storeData.phoneNumber,
      storeType: _.isEmpty(storeData?.storeType)
        ? props.row.storeType
        : storeData.storeType,
      name: _.isEmpty(storeData?.name) ? props.row.name : storeData.name,
      state: _.isEmpty(storeData?.state) ? props.row.state : storeData.state,
      city: _.isEmpty(storeData?.city) ? props.row.city : storeData.city,
      district: _.isEmpty(storeData?.district)
        ? props.row.district
        : storeData.district,
      pincode: _.isEmpty(storeData?.pincode)
        ? props.row.pincode
        : storeData.pincode,
      addressLine1: _.isEmpty(storeData?.addressLine1)
        ? props.row.addressLine1
        : storeData.addressLine1,
      addressLine2: _.isEmpty(storeData?.addressLine2)
        ? props.row.addressLine2
        : storeData.addressLine2,
      latitude: _.isEmpty(storeData?.latitude)
        ? props.row.latitude
        : storeData.latitude,
      longitude: _.isEmpty(storeData?.longitude)
        ? props.row.longitude
        : storeData.longitude,
      landmark: _.isEmpty(storeData?.landmark)
        ? props.row.landmark
        : storeData.landmark,
    };
    let partnerObj = {
      storePartners: [],
      businessPartners: []
    }

    let tempStorePartner = {},
      tempBusinessPartner = {};

    if ((storeData.hasOwnProperty("storePartners") && !storeData?.storePartners) || (storeData.hasOwnProperty("storeEarnings") && !storeData?.storeEarnings)) {
    }else{
      if (storeData?.storePartners?._id) {
        tempStorePartner.userId = storeData?.storePartners?._id
      } else {
        if (props?.row?.storePartners && props?.row?.storePartners.length > 0) {
          tempStorePartner.userId = props?.row?.storePartners[0]._id
        }
      }
      if (storeData?.storeEarnings) {
        tempStorePartner.earningsPercentage = storeData?.storeEarnings
      } else {
        if (props?.row?.storePartners && props?.row?.storePartners.length > 0) {
          tempStorePartner.earningsPercentage = props?.row?.storePartners[0].earningsPercentage
        }
      }

      if (Object.keys(tempStorePartner).length > 0) {
        partnerObj.storePartners.push(tempStorePartner)
      }
    }

    if ((storeData.hasOwnProperty("businessPartners") && !storeData?.businessPartners) || (storeData.hasOwnProperty("businessEarnings") && !storeData?.businessEarnings)) {
    }else{
      if (storeData?.businessPartners?._id) {
       
        tempBusinessPartner.userId = storeData?.businessPartners?._id
      } else {
      
        if (props?.row?.storeBusinessPartners && props?.row?.storeBusinessPartners.length > 0) {
  
          tempBusinessPartner.userId = props?.row?.storeBusinessPartners[0]._id
        }
      }
      if (storeData?.businessEarnings) {
        tempBusinessPartner.earningsPercentage = storeData?.businessEarnings
      } else {
        if (props?.row?.storeBusinessPartners && props?.row?.storeBusinessPartners.length > 0) {
          tempBusinessPartner.earningsPercentage = props?.row?.storeBusinessPartners[0].earningsPercentage
        }
      }


      if (Object.keys(tempBusinessPartner).length > 0) {
        partnerObj.businessPartners.push(tempBusinessPartner)
      }
    }



    const submitObj =
      storeSaveObj?.storeType === "Partner Store"
        ? {
          ...storeSaveObj,
          ...partnerObj,
          storeId: props.row?.storeId,
        }
        : {
          ...storeSaveObj,
          storePartners: [],
          businessPartners: [],
          storeId: props.row?.storeId,
        };

    dispatch(getStoreEdit(submitObj));
  };

  const handleAlertClose = () => {
    dispatch(getStoreEditError(""));
  };

  useEffect(() => {
    if (!_.isEmpty(storesEditedSuccess)) {
      const subHeader = "Stores Edited Successfully";
      setSuccess(true);
    }
  }, [storesEditedSuccess]);

  return (
    <div>
      {storesEditedError ? (
        <AlertComp
          type="error"
          message={storesEditedError}
          handleAlertClose={handleAlertClose}
        />
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          aligntItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            aligntItems: "center",
          }}
        >
          <div onClick={props?.handleBack}>
            <BackIcon />
          </div>
          <HeadingTypography
            color={"#03a5a5"}
            size={"18px"}
            margin={"0px 0px 16px"}
            sx={{ padding: "0px 0px 0px 10px" }}
          >
            EDIT STORE
          </HeadingTypography>
        </div>
        <div>
          <ContinueButton onClick={() => setEnableEdit(true)}>
            Edit Store
          </ContinueButton>
        </div>
      </div>

      <Grid container alignItems="center" sx={{ width: "80%" }}>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              STORE NAME
            </SubLabelTypography>
            <TextField
              fullWidth
              disabled={!enableEdit}
              defaultValue={props?.row?.name}
              type="text"
              placeholder="Type Here"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "name")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "5%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              STORE TYPE
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={["Own Store", "Partner Store"]}
              disabled={!enableEdit}
              defaultValue={selectedStoreType}
              placeholder="Select"
              onChange={(event, newValue) => {
                handleChange(event, newValue, "storeType");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              Mobile
            </SubLabelTypography>
            <TextField
              fullWidth
              disabled={!enableEdit}
              defaultValue={props?.row?.phoneNumber}
              type="text"
              placeholder="Type Here"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
              onChange={(e) => handleChange(e, "", "phoneNumber")}
            />
          </div>
        </Grid>
      </Grid>
      {selectedStoreType === "Partner Store" && (
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                lg={7}
                sx={{ pr: "2.5%", pb: "1.5%" }}
              >
                <div>
                  <SubLabelTypography
                    color={"#2b2b2b"}
                    size={"14px"}
                    sx={{ pb: "8px" }}
                  >
                    STORE PARTNER
                  </SubLabelTypography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={storePartner}
                    disabled={!enableEdit}
                    defaultValue={props?.row?.storePartners[0]}
                    getOptionLabel={(option) => option?.name}
                    placeholder="Select"
                    onChange={(event, newValue) => {
                      handleChange(event, newValue, "storePartners");
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} placeholder="Select" />
                    )}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                sx={{ pr: "2.5%", pb: "1.5%" }}
              >
                <div>
                  <SubLabelTypography
                    color={"#2b2b2b"}
                    size={"14px"}
                    sx={{ pb: "8px" }}
                  >
                    EARNINGS(%)
                  </SubLabelTypography>
                  <TextField
                    fullWidth
                    type="text"
                    disabled={!enableEdit}
                    defaultValue={
                      props?.row?.storePartners.length > 0 ? props?.row?.storePartners[0].earningsPercentage : ""
                    }
                    placeholder=""
                    variant="outlined"
                    onChange={(e) => handleChange(e, "", "storeEarnings")}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                lg={7}
                sx={{ pr: "2.5%", pb: "1.5%" }}
              >
                <div>
                  <SubLabelTypography
                    color={"#2b2b2b"}
                    size={"14px"}
                    sx={{ pb: "8px" }}
                  >
                    BUSINESS PARTNER
                  </SubLabelTypography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={businessPartner}
                    disabled={!enableEdit}
                    defaultValue={props.row.storeBusinessPartners[0]}
                    getOptionLabel={(option) => option?.name}
                    placeholder="Select"
                    onChange={(event, newValue) => {
                      handleChange(event, newValue, "businessPartners");
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} placeholder="Select" />
                    )}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                sx={{ pr: "2.5%", pb: "1.5%" }}
              >
                <div>
                  <SubLabelTypography
                    color={"#2b2b2b"}
                    size={"14px"}
                    sx={{ pb: "8px" }}
                  >
                    EARNINGS(%)
                  </SubLabelTypography>
                  <TextField
                    fullWidth
                    type="text"
                    disabled={!enableEdit}
                    defaultValue={
                      props?.row?.storeBusinessPartners.length > 0 ? props?.row?.storeBusinessPartners[0].earningsPercentage : ""
                    }
                    placeholder=""
                    variant="outlined"
                    onChange={(e) => handleChange(e, "", "businessEarnings")}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <HeadingTypography
        color={"#03a5a5"}
        size={"18px"}
        margin={"20px 0px 16px"}
      >
        ADD ADDRESS
      </HeadingTypography>
      <Grid container alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          sx={{ pr: "2.5%", pb: "1.5%" }}
        >
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              PINCODE
            </SubLabelTypography>
            <TextField
              fullWidth
              disabled={!enableEdit}
              defaultValue={props?.row?.pincode}
              type="text"
              placeholder="Type Here"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "pincode")}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          sx={{ pr: "2.5%", pb: "1.5%" }}
        >
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              STATE
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={state}
              getOptionLabel={(option) => option.name}
              disabled={!enableEdit}
              defaultValue={stateValue}
              placeholder="Select"
              onChange={(event, newValue) => {
                handleChange(event, newValue, "state");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          sx={{ pr: "2.5%", pb: "1.5%" }}
        >
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              DISTRICT
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={cities}
              getOptionLabel={(option) => option.name}
              disabled={!enableEdit}
              defaultValue={cityValue}
              placeholder="Select"
              onChange={(event, newValue) => {
                handleChange(event, newValue, "district");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          sx={{ pr: "2.5%", pb: "1.5%" }}
        >
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              CITY/TOWN/VILLAGE
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={cities}
              getOptionLabel={(option) => option.name}
              disabled={!enableEdit}
              defaultValue={cityValue}
              placeholder="Select"
              onChange={(event, newValue) => {
                handleChange(event, newValue, "city");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "2.3%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              ADDRESS 1
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="Type Here"
              variant="outlined"
              disabled={!enableEdit}
              defaultValue={props?.row?.addressLine1}
              onChange={(e) => handleChange(e, "", "addressLine1")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "2.3%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              ADDRESS 2
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="Type Here"
              variant="outlined"
              disabled={!enableEdit}
              defaultValue={props?.row?.addressLine2}
              onChange={(e) => handleChange(e, "", "addressLine2")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "2.3%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              LANDMARK
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="Type Here"
              variant="outlined"
              disabled={!enableEdit}
              defaultValue={props?.row?.landmark}
              onChange={(e) => handleChange(e, "", "landmark")}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ pr: "3%", pb: "1.5%" }}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography sx={{ pb: "8px" }}>GPS COORDINATES</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                placeholder="Latitude"
                variant="outlined"
                disabled={!enableEdit}
                defaultValue={props?.row?.latitude}
                onChange={(e) => handleChange(e, "", "latitude")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <TextField
              fullWidth
              type="text"
              placeholder="Longitude"
              variant="outlined"
              disabled={!enableEdit}
              defaultValue={props?.row?.longitude}
              onChange={(e) => handleChange(e, "", "longitude")}
            />
          </div>
        </Grid>
      </Grid>
      <div>
        <HeadingTypography
          color={"#03a5a5"}
          size={"18px"}
          margin={"0px 0px 16px"}
        >
          UPLOAD LICENSE
        </HeadingTypography>
        <Grid container>
          <Grid item xs={6} sm={6} md={2} lg={2} sx={{ pr: "3%", pb: "1.5%" }}>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              SIGNING DATE
            </SubLabelTypography>
            <DatePicker
              disabled={!enableEdit}
              selected={dateValue.signingDate}
              autoComplete="off"
              onChange={(date) => handleDateChange(date, "signingDate")}
              dateFormat="dd/MM/yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mm/yyyy"
              customInput={
                <TextField
                  fullWidth
                  type="text"
                  placeholder="dd/mm/yyyy"
                  variant="outlined"
                />
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2} sx={{ pr: "3%", pb: "1.5%" }}>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              ACTIVATION DATE
            </SubLabelTypography>
            <DatePicker
              disabled={!enableEdit}
              selected={dateValue.activationDate}
              autoComplete="off"
              onChange={(date) => handleDateChange(date, "activationDate")}
              dateFormat="dd/MM/yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mm/yyyy"
              customInput={
                <TextField
                  fullWidth
                  type="text"
                  placeholder="dd/mm/yyyy "
                  variant="outlined"
                />
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2} sx={{ pr: "3%", pb: "1.5%" }}>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              EXPIRING DATE
            </SubLabelTypography>
            <DatePicker
              disabled={!enableEdit}
              selected={dateValue.expiringDate}
              autoComplete="off"
              onChange={(date) => handleDateChange(date, "expiringDate")}
              dateFormat="dd/MM/yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mm/yyyy"
              customInput={
                <TextField
                  fullWidth
                  type="text"
                  placeholder="dd/mm/yyyy"
                  variant="outlined"
                />
              }
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.3}
            lg={2.3}
            sx={{ pr: "3%", pb: "1.5%" }}
          >
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              STORE LICENSE
            </SubLabelTypography>
            <div
              style={{
                borderRadius: "3px",
                border: "solid 1px #d8d8d8",
                backgroundColor: "#fafafa",
                padding: "20px 23px 57px 33px",
              }}
            >
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleImageChange("storeLicenseImage", acceptedFiles);
                }}
                name="storeLicense"
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <span style={{ fontSize: ".8rem" }}>
                      Drop image here, or click to select file
                    </span>
                  </div>
                )}
              </Dropzone>
              <div>
                <img
                  style={{
                    width: "140px",
                    height: "150px",
                    margin: "0",
                    display: "block",
                  }}
                  src={
                    imageValue.storeLicenseImage.preview ||
                    imageValue.storeLicenseImage
                  }
                  alt="storeLicense"
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.3}
            lg={2.3}
            sx={{ pr: "3%", pb: "1.5%" }}
          >
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              RENTAL AGREEMENT
            </SubLabelTypography>
            <div
              style={{
                borderRadius: "3px",
                border: "solid 1px #d8d8d8",
                backgroundColor: "#fafafa",
                padding: "20px 23px 57px 33px",
              }}
            >
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleImageChange("rentalAgreementImage", acceptedFiles);
                }}
                name="rentalAgreement"
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <span style={{ fontSize: ".8rem" }}>
                      Drop image here, or click to select file
                    </span>
                  </div>
                )}
              </Dropzone>
              <div>
                <img
                  style={{
                    width: "140px",
                    height: "150px",
                    margin: "0",
                    display: "block",
                  }}
                  src={
                    imageValue.rentalAgreementImage.preview ||
                    imageValue.rentalAgreementImage
                  }
                  alt="rentalAgreement"
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.3}
            lg={2.3}
            sx={{ pr: "3%", pb: "1.5%" }}
          >
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              PPL LICENSE
            </SubLabelTypography>
            <div
              style={{
                borderRadius: "3px",
                border: "solid 1px #d8d8d8",
                backgroundColor: "#fafafa",
                padding: "20px 23px 57px 33px",
              }}
            >
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleImageChange("pplLicenseImage", acceptedFiles);
                }}
                name="pplLicense"
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <span style={{ fontSize: ".8rem" }}>
                      Drop image here, or click to select file
                    </span>
                  </div>
                )}
              </Dropzone>
              <div>
                <img
                  style={{
                    width: "140px",
                    height: "150px",
                    margin: "0",
                    display: "block",
                  }}
                  src={
                    imageValue.pplLicenseImage.preview ||
                    imageValue.pplLicenseImage
                  }
                  alt="pplLicense"
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.3}
            lg={2.3}
            sx={{ pr: "3%", pb: "1.5%" }}
          >
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              TRADE LICENSE
            </SubLabelTypography>
            <div
              style={{
                borderRadius: "3px",
                border: "solid 1px #d8d8d8",
                backgroundColor: "#fafafa",
                padding: "20px 23px 57px 33px",
              }}
            >
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleImageChange("tradeLicenseImage", acceptedFiles);
                }}
                name="tradeLicense"
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <span style={{ fontSize: ".8rem" }}>
                      Drop image here, or click to select file
                    </span>
                  </div>
                )}
              </Dropzone>
              <div>
                <img
                  style={{
                    width: "140px",
                    height: "150px",
                    margin: "0",
                    display: "block",
                  }}
                  src={
                    imageValue.tradeLicenseImage.preview ||
                    imageValue.tradeLicenseImage
                  }
                  alt="tradeLicense"
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.8}
            lg={2.8}
            sx={{ pr: "3%", pb: "1.5%" }}
          >
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              SHOP ESTABLISHMENT LICENSE
            </SubLabelTypography>
            <div
              style={{
                borderRadius: "3px",
                border: "solid 1px #d8d8d8",
                backgroundColor: "#fafafa",
                padding: "20px 23px 57px 33px",
              }}
            >
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleImageChange(
                    "shopEstablishmentLicenseImage",
                    acceptedFiles
                  );
                }}
                name="shopEstablishment"
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <span style={{ fontSize: ".8rem" }}>
                      Drop image here, or click to select file
                    </span>
                  </div>
                )}
              </Dropzone>
              <div>
                <img
                  style={{
                    width: "160px",
                    height: "150px",
                    margin: "0",
                    display: "block",
                  }}
                  src={
                    imageValue.shopEstablishmentLicenseImage.preview ||
                    imageValue.shopEstablishmentLicenseImage
                  }
                  alt="shopEstablishment"
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.3}
            lg={2.3}
            sx={{ pr: "3%", pb: "1.5%" }}
          >
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              VALIDITY DATE
            </SubLabelTypography>
            <DatePicker
              disabled={!enableEdit}
              selected={dateValue.storeLicense}
              autoComplete="off"
              onChange={(date) => handleDateChange(date, "storeLicense")}
              dateFormat="dd/MM/yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mm/yyyy"
              customInput={
                <TextField
                  fullWidth
                  type="text"
                  placeholder="dd/mm/yyyy"
                  variant="outlined"
                />
              }
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.3}
            lg={2.3}
            sx={{ pr: "3%", pb: "1.5%" }}
          >
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              VALIDITY DATE
            </SubLabelTypography>
            <DatePicker
              disabled={!enableEdit}
              selected={dateValue.rentalAgreement}
              autoComplete="off"
              onChange={(date) => handleDateChange(date, "rentalAgreement")}
              dateFormat="dd/MM/yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mm/yyyy"
              customInput={
                <TextField
                  fullWidth
                  type="text"
                  placeholder="dd/mm/yyyy"
                  variant="outlined"
                />
              }
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.3}
            lg={2.3}
            sx={{ pr: "3%", pb: "1.5%" }}
          >
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              VALIDITY DATE
            </SubLabelTypography>
            <DatePicker
              disabled={!enableEdit}
              selected={dateValue.pplLicense}
              autoComplete="off"
              onChange={(date) => handleDateChange(date, "pplLicense")}
              dateFormat="dd/MM/yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mm/yyyy"
              customInput={
                <TextField
                  fullWidth
                  type="text"
                  placeholder="dd/mm/yyyy"
                  variant="outlined"
                />
              }
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.3}
            lg={2.3}
            sx={{ pr: "3%", pb: "1.5%" }}
          >
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              VALIDITY DATE
            </SubLabelTypography>
            <DatePicker
              disabled={!enableEdit}
              selected={dateValue.tradeLicense}
              autoComplete="off"
              onChange={(date) => handleDateChange(date, "tradeLicense")}
              dateFormat="dd/MM/yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mm/yyyy"
              customInput={
                <TextField
                  fullWidth
                  type="text"
                  placeholder="dd/mm/yyyy"
                  variant="outlined"
                />
              }
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.8}
            lg={2.8}
            sx={{ pr: "3%", pb: "1.5%" }}
          >
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              VALIDITY DATE
            </SubLabelTypography>
            <DatePicker
              disabled={!enableEdit}
              selected={dateValue.shopEstablishment}
              autoComplete="off"
              onChange={(date) => handleDateChange(date, "shopEstablishment")}
              dateFormat="dd/MM/yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mm/yyyy"
              customInput={
                <TextField
                  fullWidth
                  type="text"
                  placeholder="dd/mm/yyyy"
                  variant="outlined"
                />
              }
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <HeadingTypography
          color={"#03a5a5"}
          size={"18px"}
          margin={"0px 0px 16px"}
        >
          ASSIGN EMPLOYEES
        </HeadingTypography>
        {employee.map((emp, i) => (
          <Grid container alignItems="center">
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              sx={{ pr: "5%", pb: "1.5%" }}
            >
              <div>
                <SubLabelTypography
                  color={"#2b2b2b"}
                  size={"14px"}
                  sx={{ pb: "8px" }}
                >
                  ROLE
                </SubLabelTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  disabled={!enableEdit}
                  options={employeeRole}
                  value={emp?.role}
                  getOptionLabel={(option) => option.name}
                  placeholder="Select"
                  onChange={(event, newValue) => {
                    handleEmployeeChange(event, newValue, "role", i);
                  }}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} placeholder="Select" />
                  )}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              sx={{ pr: "3%", pb: "1.5%" }}
            >
              <div>
                <SubLabelTypography
                  color={"#2b2b2b"}
                  size={"14px"}
                  sx={{ pb: "8px" }}
                >
                  EMPLOYEE NAME/ID
                </SubLabelTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={dropdowns}
                  disabled={!enableEdit}
                  getOptionLabel={(option) => option.name}
                  value={emp?.employeeName}
                  placeholder="Select"
                  onChange={(event, newValue) => {
                    handleEmployeeChange(event, newValue, "employeeName", i);
                  }}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} placeholder="Select" />
                  )}
                />
              </div>
            </Grid>
            {i === employee.length - 1 ? (
              <Grid item xs={3}>
                <ContinueButton
                  disabled={!enableEdit}
                  onClick={handleAddEmployee}
                >
                  Add Employee
                </ContinueButton>
              </Grid>
            ) : (
              <div
                onClick={() => handleDelete(i)}
                style={{ cursor: "pointer" }}
              >
                <DeleteIcon />
              </div>
            )}
          </Grid>
        ))}
      </div>
      {enableEdit ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div style={{ padding: "30px 10px 0px 0px" }}>
            <ContinueShoppingButton onClick={() => setEnableEdit(false)}>
              Cancel
            </ContinueShoppingButton>
          </div>
          <div style={{ padding: "30px 0px 0px 0px" }}>
            <ContinueButton marginLeft={"0px"} onClick={handleSubmit}>
              Submit
            </ContinueButton>
          </div>
        </div>
      ) : (
        ""
      )}
      <SuccessModal
        success={success}
        successHeader={"Store Edited Successfully"}
        button={"Stores List"}
        handleSuccessClose={handleSuccessClose}
      ></SuccessModal>
    </div>
  );
};

export default ViewStore;
