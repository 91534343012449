import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useStyles } from "./styles";
import StoreDetails from "./StoreDetails";
import LicenseUpload from "./LicenseUpload";
import AssignEmployees from "./AssignEmployees";
import { useNavigate } from "react-router-dom";
import { SuccessModal } from "../../../components";
import { AlertComp } from "../../../components/Alert";
import { useDispatch, useSelector } from "react-redux";
import {
  setStoreDetails,
  setEmployeeDetails,
  setUploadLicense,
  getStoreAdd,
  setStoreAddError,
} from "../../../app/reducers/storeSlice";
import _ from "lodash";
import S3 from "react-aws-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

const steps = ["STORE DETAILS", "UPLOAD LICENSE", "ASSIGN EMPLOYEES"];

export default function NewStoreStepper() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const storesAdded = useSelector((state) => state.storeReducer.storesAdded);
  const storesAddedError = useSelector(
    (state) => state.storeReducer.storesAddedError
  );

  const storeDetails = useSelector((state) => state.storeReducer.storeDetails);
  const licenseDetails = useSelector(
    (state) => state.storeReducer.licenseDetails
  );
  const employeeDetails = useSelector(
    (state) => state.storeReducer.employeeDetails
  );

  const previousStoreDetails = _.isEmpty(storeDetails) ? {} : storeDetails;
  const previousLicenseDetails = _.isEmpty(licenseDetails)
    ? {}
    : licenseDetails;
  const previousEmployeeDetails = _.isEmpty(employeeDetails)
    ? [{ employeeName: { userId: "", name: "" }, role: { name: "" } }]
    : employeeDetails;

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const [success, setSuccess] = useState(false);
  const [succesSubHeader, setSuccessSubHeader] = useState("");

  const [storeValue, setStoreValue] = useState(previousStoreDetails);
  const [licenseValue, setLicenseValue] = useState(previousLicenseDetails);
  const [licenseDatePicker, setLicenseDatePicker] = useState({});
  const [employeeValue, setEmployeeValue] = useState(previousEmployeeDetails);

  const history = useNavigate();

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const config = {
    bucketName: process.env.REACT_APP_AWS_BUCKET, //"oicappsv3-dev",
    dirName: "eyeplay",
    region: "ap-south-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  };

  const ReactS3Client = new S3(config);

  const uploadToS3 = async (file, name) => {
    return await ReactS3Client.uploadFile(
      file,
      `Accounts/Store/${storeValue?.storeName}${storeValue?.storeType}${storeValue?.latitude}${name}`
    );
  };

  const imageUpload = async (file, name) => {
    let obj = {};
    const data = await uploadToS3(file, name);
    Object.assign(obj, { [name]: data.location });
    return obj;
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      dispatch(setStoreDetails(storeValue));
    } else if (activeStep === 1) {
      dispatch(setUploadLicense(licenseValue));
    } else if (activeStep === 2) {
      dispatch(setEmployeeDetails(employeeValue));
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);

    if (activeStep === steps.length - 1) {
      const obj = await imageUpload(
        licenseValue?.storeLicenseImage,
        "storeLicenseImage"
      );
      const obj1 = await imageUpload(
        licenseValue?.rentalAgreementImage,
        "rentalAgreementImage"
      );
      const obj2 = await imageUpload(
        licenseValue?.pplLicenseImage,
        "pplLicenseImage"
      );
      const obj3 = await imageUpload(
        licenseValue?.tradeLicenseImage,
        "tradeLicenseImage"
      );
      const obj4 = await imageUpload(
        licenseValue?.shopEstablishmentImage,
        "shopEstablishmentImage"
      );

      let tempObj = {};
 
      employeeValue?.map((employee) => {
        let result = tempObj.hasOwnProperty(employee?.role?.name);
        if (result) {
          tempObj[employee?.role?.name].push({
            userId: employee?.employeeName?.userId,
          });
        } else {
          tempObj = {
            ...tempObj,
            [employee?.role?.name]: [
              {
                userId: employee?.employeeName?.userId,
              },
            ],
          };
        }
      });

      const storeSaveObj = {
        storeLicense: {
          licenseImage: obj.storeLicenseImage,
          validityDate: licenseValue?.validityDateStockLicense,
        },
        rentalAgreement: {
          licenseImage: obj1.rentalAgreementImage,
          validityDate: licenseValue?.rentalAgreement,
        },
        pplLicense: {
          licenseImage: obj2.pplLicenseImage,
          validityDate: licenseValue?.pplLicense,
        },
        tradeLicense: {
          licenseImage: obj3.tradeLicenseImage,
          validityDate: licenseValue?.tradeLicense,
        },
        shopEstablishmentLicense: {
          licenseImage: obj4.shopEstablishmentImage,
          validityDate: licenseValue?.shopEstablishment,
        },
        signingDate: licenseValue?.signingDate,
        expiringDate: licenseValue?.expiringDate,
        activationDate: licenseValue?.activationDate,
        phoneNumber: storeValue?.phoneNumber,
        storeType: storeValue?.storeType,
        name: storeValue?.name,
        state: storeValue?.state?.name,
        city: storeValue?.city?.name,
        district: storeValue?.district?.name,
        pincode: storeValue?.pincode,
        addressLine1: storeValue?.addressLine1,
        addressLine2: storeValue?.addressLine2,
        latitude: storeValue?.latitude,
        longitude: storeValue?.longitude,
        landmark: storeValue?.landmark,
        ...tempObj,
      };

      const partnerObj = {
        storePartners: [
          {
            userId: storeValue?.storePartners?._id,
            earningsPercentage: storeValue?.storeEarnings,
          },
        ],
        businessPartners: [
          {
            userId: storeValue?.businessPartners?._id,
            earningsPercentage: storeValue?.businessEarnings,
          },
        ],
      };
      const submitObj =
        storeValue?.storeType === "Partner Store"
          ? {
              ...storeSaveObj,
              ...partnerObj,
            }
          : storeSaveObj;
      dispatch(getStoreAdd(submitObj));
    }
  };

  const handleSuccessClose = () => {
    history("/stores");
  };

  const handleBack = () => {
    if (activeStep === 0) {
      dispatch(setStoreDetails(storeValue));
    } else if (activeStep === 1) {
      dispatch(setUploadLicense(licenseValue));
    } else if (activeStep === 2) {
      dispatch(setUploadLicense(employeeValue));
    }

    if (activeStep === 0) {
      history("/stores");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleStoreChange = (event, value, name) => {
    if (value) {
      setStoreValue({ ...storeValue, [name]: value });
    } else {
      setStoreValue({ ...storeValue, [name]: event.target.value });
    }
  };

  const handleLicenseChange = (value, name) => {
    const date = new Date(value).toISOString();
    setLicenseValue({ ...licenseValue, [name]: date });
    setLicenseDatePicker({ ...licenseDatePicker, [name]: value });
  };

  const handleLicenseImage = async (name, files) => {
    const fileWithPreview = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setLicenseValue({ ...licenseValue, [name]: fileWithPreview[0] });
  };

  const handleEmployeeChange = (event, value, name, index) => {
    if (name === "employeeName") {
      employeeValue[index][name] = { userId: value._id, name: value.name };
      setEmployeeValue([...employeeValue]);
    } else {
      employeeValue[index][name] = { name: value.value };
      setEmployeeValue([...employeeValue]);
    }
  };

  const handleAddEmployee = () => {
    employeeValue.push({
      employeeName: { userId: "", name: "" },
      role: { name: "" },
    });
    setEmployeeValue([...employeeValue]);
  };

  const deleteEmployee = (index) => {
    const newObj = _.cloneDeep(
      {},
      employeeValue.filter((el, i) => i !== index)
    );
    setEmployeeValue((prev) => prev.filter((el, i) => i !== index));
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (!_.isEmpty(storesAdded)) {
        setSuccess(true);
        setSuccessSubHeader(storeValue?.name);
    }
  }, [storesAdded]);

  const handleAlertClose = () => {
    dispatch(setStoreAddError(""));
  };

  const getForm = (step) => {
    switch (step) {
      case 0: {
        return (
          <StoreDetails
            handleChange={handleStoreChange}
            storeDetails={storeValue}
          />
        );
      }
      case 1: {
        return (
          <LicenseUpload
            handleChange={handleLicenseChange}
            licenseDetails={licenseValue}
            licenseDatePicker={licenseDatePicker}
            handleImage={handleLicenseImage}
          />
        );
      }
      case 2: {
        return (
          <AssignEmployees
            handleChange={handleEmployeeChange}
            employeeDetails={employeeValue}
            handleAddEmployee={handleAddEmployee}
            deleteEmployee={deleteEmployee}
          />
        );
      }
      default: {
        return <StoreDetails />;
      }
    }
  };

  return (
    <div className={classes.root}>
      {storesAddedError ? (
        <AlertComp
          type="error"
          message={storesAddedError}
          handleAlertClose={handleAlertClose}
        />
      ) : (
        ""
      )}
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      active: classes.icon,
                      completed: classes.icon,
                      // text:   classes.text,
                    },
                  }}
                  {...labelProps}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>{getForm(activeStep)}</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
      <SuccessModal
        success={success}
        successHeader={"Store Added Successfully"}
        succesSubHeader={succesSubHeader}
        button={"New Store"}
        handleSuccessClose={handleSuccessClose}
      ></SuccessModal>
    </div>
  );
}
