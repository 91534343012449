import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    alignStraightBetween:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between"
    },
    alignStraight:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-start"
    },
    root:{
        padding: "90px 24px 40px 26px",
        overflow: "hidden",
    },
    paperWidth:{
        maxWidth:"800px"
    },
    radio:{
        color:"#03a5a5 !important"
    },
})