import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
  getEmployeeList,
  setEmployeeList,
  getSaveEmployee,
  setSaveEmployee,
  getStoreEmployeeDetails,
  setStoreEmployeeDetails,
  setSaveEmployeeError,
  getEditEmployee,
  getEditEmployeeSuccess,
  getEditEmployeeError,
} from "../reducers/employeeSlice";

async function fetchEmployeesAPI(payload) {
  const userData = await axios
    .get("/client/user/employees", {
      params: {
        limit: 15,
        page: payload,
        filter: {},
        sort: { _created_at: -1 },
      },
    })
    .then((response) => response.data.data);
  return userData;
}

export function* fetchEmployees(action) {
  try {
    const response = yield call(fetchEmployeesAPI, action.payload);
    yield put(setEmployeeList(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function saveEmployeeAPI(payload) {
  const userData = await axios
    .post("/client/user/createuser", payload)
    .then((response) => response);
  return userData;
}
export function* saveEmployee(action) {
  try {
    const { payload } = action;
    const response = yield call(saveEmployeeAPI, payload);
    yield put(setSaveEmployee(response.data.data));
  } catch (error) {
    yield put(setSaveEmployeeError(error.response.data.message));
  }
}

async function editEmployeeAPI(payload) {
  const userData = await axios
    .post("/client/user/updateemployee", payload)
    .then((response) => response);
  return userData;
}
export function* editEmployee(action) {
  try {
    const { payload } = action;
    const response = yield call(editEmployeeAPI, payload);
    yield put(getEditEmployeeSuccess(response.data.data));
  } catch (error) {
    yield put(getEditEmployeeError(error.response.data.message));
  }
}

async function fetchStorePartnerDetailsAPI(payload) {
  const userData = await axios
    .get("/client/user/storesbyusertype", {
      params: {
        user_id: payload?.id,
        userType: payload?.type,
      },
    })
    .then((response) => response.data.data);
  return userData;
}
export function* fetchStorePartnerDetails(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchStorePartnerDetailsAPI, payload);
    yield put(setStoreEmployeeDetails(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* employeeSaga() {
  yield takeLatest(getEmployeeList.type, fetchEmployees);
  yield takeLatest(getSaveEmployee.type, saveEmployee);
  yield takeLatest(getEditEmployee.type, editEmployee);
  yield takeLatest(getStoreEmployeeDetails.type, fetchStorePartnerDetails);
}
