import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  partnerSaved: {},
  partnerEdited: {},
  partnerSavedError: "",
  partnerEditedError: "",
  partnerList: [],
  partnerStoreDetails: [],
  pageNoPartner: 0,
  hasMorePartner: true,
};

export const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    getPartnerList() {},
    setPartnerList(state, action) {
      return Object.assign({}, state, {
        partnerList: state.pageNoPartner > 0 ? [...state.partnerList, ...action.payload] : isEmpty(action.payload) ? [] : action.payload,
        hasMorePartner: action.payload.length < 15 ? false : true 
      });
    },

    setPagenoPartner(state, action) {
      return Object.assign({}, state, {
        pageNoPartner: action.payload,
      });
    },

    setSavePartner(state, action) {
      return Object.assign({}, state, {
        partnerSaved: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    getSavePartner() {},
    setSavePartnerError(state, action) {
      return Object.assign({}, state, {
        partnerSavedError: Array.isArray(action.payload) ? action.payload[0].msg : action.payload,
      });
    },
    
    getEditPartner() {},
    getEditPartnerSuccess(state, action) {
      return Object.assign({}, state, {
        partnerEdited: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    getEditPartnerError(state, action) {
      return Object.assign({}, state, {
        partnerEditedError: Array.isArray(action.payload) ? action.payload[0].msg : action.payload,
      });
    },

    setStorePartnerDetails(state, action) {
      return Object.assign({}, state, {
        partnerStoreDetails: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getStorePartnerDetails() {},

    clearPartner(state, action) {
      return Object.assign({}, state, initialState);
    },

  },
});

export const {
  getEditPartner,
  getEditPartnerSuccess,
  getEditPartnerError,
  setPartnerList,
  getPartnerList,
  getSavePartner,
  setSavePartner,
  getStorePartnerDetails,
  setStorePartnerDetails,
  clearPartner,
  setSavePartnerError,
  setPagenoPartner
} = partnerSlice.actions;

export default partnerSlice.reducer;
