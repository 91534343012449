import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  businessPartnerList: [],
  businessPartnerOrder: [],
  updatePayment: {},
  pageNoBusinessPartner: 0,
  hasMoreBusinessPartner: true,
};

export const businessPartnerSlice = createSlice({
  name: "businessPartner",
  initialState,
  reducers: {
    getBusinessPartnerList() {},
    setBusinessPartnerList(state, action) {
      return Object.assign({}, state, {
        businessPartnerList:
          state.pageNoBusinessPartner > 0
            ? [...state.businessPartnerList, ...action.payload]
            : isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreBusinessPartner: action.payload.length < 15 ? false : true,
      });
    },    
    setPagenoBusinessPartner(state, action) {
      return Object.assign({}, state, {
        pageNoBusinessPartner: action.payload,
      });
    },
    getBusinessPartnerOrders() {},
    setBusinessPartnerOrders(state, action) {
      return Object.assign({}, state, {
        businessPartnerOrder: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getPayBusinessPartner() {},
    setPayBusinessPartner(state, action) {
      return Object.assign({}, state, {
        updatePayment: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    clearBusinessPartner(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  getBusinessPartnerOrders,
  setBusinessPartnerOrders,
  getBusinessPartnerList,
  setBusinessPartnerList,
  getPayBusinessPartner,
  setPayBusinessPartner,
  setPagenoBusinessPartner,
  clearBusinessPartner,
} = businessPartnerSlice.actions;

export default businessPartnerSlice.reducer;
