import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  couponSaved: {},
  couponsList: [],
  pageNoCoupons: 0,
  hasMoreCoupons: true,
};

export const couponSlice = createSlice({
  name: "coupons",
  initialState,
  reducers: {
    getCouponsList() {},
    setCouponsList(state, action) {
      return Object.assign({}, state, {
        couponsList:
          state.pageNoCoupons > 0
            ? [...state.couponsList, ...action.payload]
            : isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreCoupons: action.payload.length < 15 ? false : true,
      });
    },
    setPagenoCoupons(state, action) {
      return Object.assign({}, state, {
        pageNoCoupons: action.payload,
      });
    },
    setSaveCoupons(state, action) {
      return Object.assign({}, state, {
        couponSaved: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    getSaveCoupons() {},

    clearCoupons(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  setSaveCoupons,
  getSaveCoupons,
  getCouponsList,
  setCouponsList,
  clearCoupons,
  setPagenoCoupons,
} = couponSlice.actions;

export default couponSlice.reducer;
