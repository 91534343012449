import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeadTypography = styled(Typography)(({theme}) => ({
    fontSize:"18px",
    fontWeight:600,
    color:"#242424",
    margin:"30px 0px"
}))

export const LabelTypography = styled(Typography)(({theme}) => ({
    fontSize:"12px",
}))