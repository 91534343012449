import {
  Autocomplete,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ContinueButton,
  HeadingTypography,
  SubLabelTypography,
  SuccessModal,
  BackIcon,
  ContinueShoppingButton,
} from "../../../components";
import { useStyles } from "./styles";
import { State, City } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import {
  getEditPartner,
  getEditPartnerError,
} from "../../../app/reducers/partnerSlice";
import { AlertComp } from "../../../components/Alert";
import _ from "lodash";

const ViewPartner = (props) => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();

  const [partner, setPartner] = useState({});
  const [success, setSuccess] = useState(false);
  const [showGstin, setShowGstin] = useState(false);
  const [succesSubHeader, setSuccessSubHeader] = useState("");

  const [enableEdit, setEnableEdit] = useState(false);

  const [selectedState, setSelectedState] = useState("");
  const state = State.getStatesOfCountry("IN");
  const cities = City.getCitiesOfState("IN", selectedState);

  const partnerEditedError = useSelector(
    (state) => state.partnerReducer.partnerEditedError
  );
  const partnerEdited = useSelector(
    (state) => state.partnerReducer.partnerEdited
  );


  useEffect(() => {
    if (!_.isEmpty(partnerEdited)) {
      const subHeader = "Partner Edited Successfully";
      setSuccess(true);
    }
  }, [partnerEdited]);

  const handleChange = (event, value, name) => {
    if (name === "state") {
      setSelectedState(value.isoCode);
    }
    if (value) {
      setPartner({ ...partner, [name]: value.name });
    } else {
      setPartner({ ...partner, [name]: event.target.value });
    }
  };
  const handleSubmit = () => {
    const obj = {
      _id: props.row?._id,
      ...partner,
    };
    dispatch(getEditPartner(obj));
  };
  const handleSuccessClose = () => {
    props?.handleBack();
  };

  const userType = {
    name: props.row?.userType,
  };

  const stateValue = {
    name: props.row?.state,
  };

  const cityValue = {
    name: props.row?.city,
  };

  const invoiceValue = {
    name: props.row?.invoiceType,
  };

 
  const handleAlertClose = () => {
    dispatch(getEditPartnerError(""));
  };

  return (
    <div>
      {partnerEditedError ? (
        <AlertComp
          type="error"
          message={partnerEditedError}
          handleAlertClose={handleAlertClose}
        />
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          aligntItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            aligntItems: "center",
          }}
        >
          <div onClick={props?.handleBack}>
            <BackIcon />
          </div>
          <HeadingTypography
            color={"#03a5a5"}
            size={"18px"}
            margin={"0px 0px 16px"}
            sx={{ padding: "0px 0px 0px 10px" }}
          >
            EDIT PARTNER
          </HeadingTypography>
        </div>
        <div>
          <ContinueButton onClick={() => setEnableEdit(true)}>
            Edit partner
          </ContinueButton>
        </div>
      </div>

      <Grid container alignItems="center" sx={{ width: "80%" }}>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "5%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              PARTNER TYPE
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={[
                { label: "Business Partner", name: "Business Partner" },
                { label: "Store Partner", name: "Store Partner" },
              ]}
              getOptionLabel={(option) => option.name}
              defaultValue={userType}
              disabled={!enableEdit}
              placeholder="Select"
              onChange={(event, newValue) => {
                handleChange(event, newValue, "userType");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container alignItems="center" width={"80%"}>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              NAME
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="Type Here"
              defaultValue={props.row?.name}
              disabled={!enableEdit}
              variant="outlined"
              onChange={(e) => handleChange(e, "", "name")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              MOBILE NUMBER
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="Type Here"
              variant="outlined"
              defaultValue={props.row?.phoneNumber}
              disabled={!enableEdit}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
              onChange={(e) => handleChange(e, "", "phoneNumber")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              EMAIL
            </SubLabelTypography>
            <TextField
              fullWidth
              defaultValue={props.row?.email}
              disabled={!enableEdit}
              type="text"
              placeholder="example@gmail.com"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "email")}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container alignItems="center" width={"80%"}>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              STATE
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={state}
              placeholder="Select"
              getOptionLabel={(option) => option.name}
              defaultValue={stateValue}
              disabled={!enableEdit}
              onChange={(event, newValue) => {
                handleChange(event, newValue, "state");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              CITY
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={cities}
              defaultValue={cityValue}
              disabled={!enableEdit}
              getOptionLabel={(option) => option.name}
              placeholder="Select"
              onChange={(event, newValue) => {
                handleChange(event, newValue, "city");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              ADDRESS
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="Type Here"
              defaultValue={props?.row?.address}
              disabled={!enableEdit}
              variant="outlined"
              onChange={(e) => handleChange(e, "", "address")}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container alignItems="center" width={"80%"}>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              PAN
            </SubLabelTypography>
            <TextField
              fullWidth
              defaultValue={props?.row?.panNumber}
              disabled={!enableEdit}
              type="text"
              placeholder=""
              variant="outlined"
              onChange={(e) => handleChange(e, "", "panNumber")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              TAN
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder=""
              defaultValue={props?.row?.tanNumber}
              disabled={!enableEdit}
              variant="outlined"
              onChange={(e) => handleChange(e, "", "tanNumber")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              INVOICE TYPE
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={[
                { name: "Automatic" },
                { name: "Manual" },
              ]}
              getOptionLabel={(option) => option.name}
              placeholder="Select"
              defaultValue={invoiceValue}
              disabled={!enableEdit}
              onChange={(event, newValue) => {
                handleChange(event, newValue, "invoiceType");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
      </Grid>

      <div className={classes.alignStraight} style={{ marginBottom: "20px" }}>
        {props.row.gstNumber && (
          <div>
            <SubLabelTypography
            color={"#2b2b2b"}
            size={"14px"}
            sx={{ pb: "8px" }}
          >
            GSTIN
          </SubLabelTypography>
          <TextField
            fullWidth
            type="text"
            defaultValue={props?.row?.gstNumber}
            disabled={!enableEdit}
            placeholder=""
            variant="outlined"
            onChange={(e) => handleChange(e, "", "gstNumber")}
          />
          </div>
        )}
      </div>
      {enableEdit ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div style={{ padding: "30px 10px 0px 0px" }}>
            <ContinueShoppingButton onClick={() => setEnableEdit(false)}>
              Cancel
            </ContinueShoppingButton>
          </div>
          <div style={{ padding: "30px 0px 0px 0px" }}>
            <ContinueButton marginLeft={"0px"} onClick={handleSubmit}>
              Submit
            </ContinueButton>
          </div>
        </div>
      ) : (
        ""
      )}
      <SuccessModal
        success={success}
        successHeader={"Partner Edited Successfully"}
        button={"Partner List"}
        handleSuccessClose={handleSuccessClose}
      ></SuccessModal>
    </div>
  );
};

export default ViewPartner;
