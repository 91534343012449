import React from "react";

export const FrameIcon = (props) => {
  return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.199"
        height="19.199"
        viewBox="0 0 19.199 19.199"
      >
        <g data-name="vuesax/outline/frame">
          <path
            d="M4.28 8.3h-.064a.434.434 0 0 0-.144 0 4.116 4.116 0 1 1 .232 0zm-.128-7.1a2.949 2.949 0 0 0-.112 5.9 1.32 1.32 0 0 1 .256 0 2.95 2.95 0 0 0-.144-5.9z"
            transform="translate(5.447 1)"
            fill="#827e7e"
          />
          <path
            data-name="Vector"
            d="M6.056 8.09a7.9 7.9 0 0 1-4.336-1.2A3.433 3.433 0 0 1 0 4.05a3.468 3.468 0 0 1 1.72-2.856 8.47 8.47 0 0 1 8.671 0 3.451 3.451 0 0 1 1.72 2.84 3.468 3.468 0 0 1-1.72 2.856 7.921 7.921 0 0 1-4.335 1.2zM2.384 2.2A2.3 2.3 0 0 0 1.2 4.058 2.3 2.3 0 0 0 2.384 5.9a7.229 7.229 0 0 0 7.344 0 2.3 2.3 0 0 0 1.184-1.856A2.3 2.3 0 0 0 9.727 2.2a7.266 7.266 0 0 0-7.343 0z"
            transform="translate(3.679 9.949)"
            fill="#827e7e"
          />
        </g>
      </svg>
  );
};
