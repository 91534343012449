import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    appBarFooter: {
        backgroundColor: "#fff !important",
        borderTop: "1px solid #e4e4e4",
        height: "64px",
        boxShadow:"none !important",
      },
})
