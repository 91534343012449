import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginSuccessMessage: {},
  userDetails: {},
  loginErrors: "",
  userError: "",
};

export const adminLoginSlice = createSlice({
  name: "adminLogin",
  initialState,
  reducers: {
    getCurrentUser() { },
    getCurrentUserSuccess(state, action) {
      return Object.assign({}, state, {
        userDetails: action.payload,
      });
    },
    login() { },
    loginSuccess(state, action) {
      return Object.assign({}, state, {
        loginSuccessMessage: action.payload,
      });
    },
    loginError(state, action) {
      return Object.assign({}, state, {
        loginErrors: Array.isArray(action.payload) ? action.payload[0].msg : action.payload,
      });
    },

    userErrors(state, action) {
      return Object.assign({}, state, {
        userError: Array.isArray(action.payload) ? action.payload[0].msg : action.payload,
      });
    },

    clearLogin(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  login,
  loginSuccess,
  loginError,
  userErrors,
  getCurrentUser,
  getCurrentUserSuccess,
  clearLogin
} = adminLoginSlice.actions;

export default adminLoginSlice.reducer;
