import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  employeeSaved: {},
  employeeSavedError: "",
  employeeEdited: {},
  employeeEditedError:"",
  employeeList: [],
  employeeStoreDetails: [],
  hasMoreEmployee: true,
  pageNoEmployee: 0,
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    getEmployeeList() {},
    setEmployeeList(state, action) {
      return Object.assign({}, state, {
        employeeList: state.pageNoEmployee > 0 ? [...state.employeeList, ...action.payload] : isEmpty(action.payload) ? [] : action.payload,
        hasMoreEmployee: action.payload.length < 15 ? false : true 
      });
    },

    setPagenoEmployee(state, action) {
      return Object.assign({}, state, {
        pageNoEmployee: action.payload,
      });
    },

    getSaveEmployee() {},
    setSaveEmployee(state, action) {
      return Object.assign({}, state, {
        employeeSaved: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setSaveEmployeeError(state, action) {
      return Object.assign({}, state, {
        employeeSavedError: Array.isArray(action.payload) ? action.payload[0].msg : action.payload,
      });
    },

    getEditEmployee() {},
    getEditEmployeeSuccess(state, action) {
      return Object.assign({}, state, {
        employeeEdited: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    getEditEmployeeError(state, action) {
      return Object.assign({}, state, {
        employeeEditedError: Array.isArray(action.payload) ? action.payload[0].msg : action.payload,
      });
    },

    setStoreEmployeeDetails(state, action) {
      return Object.assign({}, state, {
        employeeStoreDetails: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getStoreEmployeeDetails() {},

    clearStoreEmployee(state, action) {
      return Object.assign({}, state, initialState);
    },

  },
});

export const {
  setSaveEmployee,
  setSaveEmployeeError,
  getSaveEmployee,
  getEditEmployee,
  getEditEmployeeSuccess,
  setPagenoEmployee,
  getEditEmployeeError,
  setEmployeeList,
  getEmployeeList,
  setStoreEmployeeDetails,
  getStoreEmployeeDetails,
  clearStoreEmployee
} = employeeSlice.actions;

export default employeeSlice.reducer;
