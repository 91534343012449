import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
    setStoreOrdersList,
    getStoreOrder,
    getStoreOrderDetails,
    setStoreOrderDetails
} from "../reducers/storeOrderSlice";

async function fetchStoreOrderListAPI(payload) {
    const userData = await axios
        .get('/client/storeorder/storeorderadmin', {
            params: {
                limit: 15,
                page: payload,
                filter: {},
                sort: {
                    orderDate: -1,
                    orderId: -1
                },
                search: ""
            }
        })
        .then((response) => response.data.data);
    return userData;
}

async function fetchStoreOrderDetailsAPI(payload) {
    const userData = await axios
        .get('/client/storeorder/storeorderadmindetails', {
            params: {
                orderId: payload.orderId
            }
        })
        .then((response) => response.data.data);
    return userData;
}

export function* fetchStoreOrder(action) {
    try {
        const response = yield call(fetchStoreOrderListAPI, action.payload);
        yield put(setStoreOrdersList(response))
    } catch (error) {
        yield put({ type: "ERRORS", payload: error });
    }
}

export function* fetchStoreOrderDetails(action) {
    try {
        const response = yield call(fetchStoreOrderDetailsAPI, action.payload);
        yield put(setStoreOrderDetails(response))
    } catch (error) {
        yield put({ type: "ERRORS", payload: error });
    }
}

export function* storeOrderSaga() {
    yield takeLatest(getStoreOrder.type, fetchStoreOrder);
    yield takeLatest(getStoreOrderDetails.type, fetchStoreOrderDetails);
}
