import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  confirmPaymentCash: {},
  cashDepositList: [],
  pageNoCash: 0,
  hasMoreCash: true,
};

export const cashDepositSlice = createSlice({
  name: "cashDeposit",
  initialState,
  reducers: {
    getCashDeposit() { },
    setCashDeposit(state, action) {
      return Object.assign({}, state, {
        cashDepositList:
          state.pageNoCash > 0
            ? [...state.cashDepositList, ...action.payload]
            : isEmpty(action.payload)
              ? []
              : action.payload,
        hasMoreCash: action.payload.length < 15 ? false : true,
      });
    },
    setPagenoCash(state, action) {
      return Object.assign({}, state, {
        pageNoCash: action.payload,
      });
    },
    getConfirmPaymentCash() { },
    setConfirmPaymentCash(state, action) {
      return Object.assign({}, state, {
        confirmPaymentCash: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    clearCash(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  getCashDeposit,
  setCashDeposit,
  getConfirmPaymentCash,
  setConfirmPaymentCash,
  clearCash,
  setPagenoCash,
} = cashDepositSlice.actions;

export default cashDepositSlice.reducer;
