import React, { useState } from "react";
import { HeadingTypography, SubLabelTypography } from "../../../components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Grid, TextField } from "@mui/material";
import Dropzone from "react-dropzone";
window.Buffer = window.Buffer || require("buffer").Buffer;

const LicenseUpload = (props) => {

  const { licenseDetails, licenseDatePicker } = props;

 
  const handleDateChange = (newValue, name) => {
    props?.handleChange(newValue, name);
  };

  const handleImageChange = async (name,acceptedFiles) => {
    await props?.handleImage(name, acceptedFiles);
  }


  return (
    <div>
      <HeadingTypography
        color={"#03a5a5"}
        size={"18px"}
        margin={"0px 0px 16px"}
      >
        UPLOAD LICENSE
      </HeadingTypography>
      <Grid container>
        <Grid item xs={6} sm={6} md={2} lg={2} sx={{ pr: "3%", pb: "1.5%" }}>
          <SubLabelTypography
            color={"#2b2b2b"}
            size={"14px"}
            sx={{ pb: "8px" }}
          >
            SIGNING DATE
          </SubLabelTypography>
          <DatePicker
            selected={licenseDatePicker?.signingDate}
            autoComplete="off"
            onChange={(date) => handleDateChange(date, "signingDate")}
            dateFormat="dd/MM/yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="dd/mm/yyyy"
            customInput={
              <TextField
                fullWidth
                type="text"
                placeholder="dd/mm/yyyy"
                variant="outlined"
              />
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} sx={{ pr: "3%", pb: "1.5%" }}>
          <SubLabelTypography
            color={"#2b2b2b"}
            size={"14px"}
            sx={{ pb: "8px" }}
          >
            ACTIVATION DATE
          </SubLabelTypography>
          <DatePicker
            selected={licenseDatePicker?.activationDate}
            autoComplete="off"
            onChange={(date) => handleDateChange(date, "activationDate")}
            dateFormat="dd/MM/yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="dd/mm/yyyy"
            customInput={
                <TextField
                  fullWidth
                  type="text"
                  placeholder="dd/mm/yyyy "
                  variant="outlined"
                />
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2} sx={{ pr: "3%", pb: "1.5%" }}>
          <SubLabelTypography
            color={"#2b2b2b"}
            size={"14px"}
            sx={{ pb: "8px" }}
          >
            EXPIRING DATE
          </SubLabelTypography>
          <DatePicker
            selected={licenseDatePicker?.expiringDate}
            autoComplete="off"
            onChange={(date) => handleDateChange(date, "expiringDate")}
            dateFormat="dd/MM/yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="dd/mm/yyyy"
            customInput={
                <TextField
                  fullWidth
                  type="text"
                  placeholder="dd/mm/yyyy"
                  variant="outlined"
                />
            }
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} sm={6} md={2.3} lg={2.3} sx={{ pr: "3%", pb: "1.5%" }}>
          <SubLabelTypography
            color={"#2b2b2b"}
            size={"14px"}
            sx={{ pb: "8px" }}
          >
            STORE LICENSE
          </SubLabelTypography>
          <div
                    style={{
                      borderRadius: "3px",
                      border: "solid 1px #d8d8d8",
                      backgroundColor: "#fafafa",
                      padding: "20px 23px 57px 33px",
                    }}
                  >
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleImageChange(
                          "storeLicenseImage",
                          acceptedFiles
                        );
                      }}
                      name="storeLicense"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <span style={{ fontSize: ".8rem" }}>
                            Drop image here, or click to select file
                          </span>
                        </div>
                      )}
                    </Dropzone>
                    <div>
                      <img
                        style={{
                          width: "140px",
                          height: "150px",
                          margin: "0",
                          display: "block",
                        }}
                        src={licenseDetails?.storeLicenseImage?.preview}
                        alt="storeLicense"
                      />
                    </div>
                  </div>
        </Grid>
        <Grid item xs={6} sm={6} md={2.3} lg={2.3} sx={{ pr: "3%", pb: "1.5%" }}>
          <SubLabelTypography
            color={"#2b2b2b"}
            size={"14px"}
            sx={{ pb: "8px" }}
          >
            RENTAL AGREEMENT
          </SubLabelTypography>
          <div
                    style={{
                      borderRadius: "3px",
                      border: "solid 1px #d8d8d8",
                      backgroundColor: "#fafafa",
                      padding: "20px 23px 57px 33px",
                    }}
                  >
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleImageChange(
                          "rentalAgreementImage",
                          acceptedFiles
                        );
                      }}
                      name="rentalAgreement"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <span style={{ fontSize: ".8rem" }}>
                            Drop image here, or click to select file
                          </span>
                        </div>
                      )}
                    </Dropzone>
                    <div>
                      <img
                        style={{
                          width: "140px",
                          height: "150px",
                          margin: "0",
                          display: "block",
                        }}
                        src={licenseDetails?.rentalAgreementImage?.preview}
                        alt="rentalAgreement"
                      />
                    </div>
                  </div>
        </Grid>
        <Grid item xs={6} sm={6} md={2.3} lg={2.3} sx={{ pr: "3%", pb: "1.5%" }}>
          <SubLabelTypography
            color={"#2b2b2b"}
            size={"14px"}
            sx={{ pb: "8px" }}
          >
            PPL LICENSE
          </SubLabelTypography>
          <div
                    style={{
                      borderRadius: "3px",
                      border: "solid 1px #d8d8d8",
                      backgroundColor: "#fafafa",
                      padding: "20px 23px 57px 33px",
                    }}
                  >
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleImageChange(
                          "pplLicenseImage",
                          acceptedFiles
                        );
                      }}
                      name="pplLicense"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <span style={{ fontSize: ".8rem" }}>
                            Drop image here, or click to select file
                          </span>
                        </div>
                      )}
                    </Dropzone>
                    <div>
                      <img
                        style={{
                          width: "140px",
                          height: "150px",
                          margin: "0",
                          display: "block",
                        }}
                        src={licenseDetails?.pplLicenseImage?.preview}
                        alt="pplLicense"
                      />
                    </div>
                  </div>
        </Grid>
        <Grid item xs={6} sm={6} md={2.3} lg={2.3} sx={{ pr: "3%", pb: "1.5%" }}>
          <SubLabelTypography
            color={"#2b2b2b"}
            size={"14px"}
            sx={{ pb: "8px" }}
          >
            TRADE LICENSE
          </SubLabelTypography>
          <div
                    style={{
                      borderRadius: "3px",
                      border: "solid 1px #d8d8d8",
                      backgroundColor: "#fafafa",
                      padding: "20px 23px 57px 33px",
                    }}
                  >
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleImageChange(
                          "tradeLicenseImage",
                          acceptedFiles
                        );
                      }}
                      name="tradeLicense"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <span style={{ fontSize: ".8rem" }}>
                            Drop image here, or click to select file
                          </span>
                        </div>
                      )}
                    </Dropzone>
                    <div>
                      <img
                        style={{
                          width: "140px",
                          height: "150px",
                          margin: "0",
                          display: "block",
                        }}
                        src={licenseDetails?.tradeLicenseImage?.preview}
                        alt="tradeLicense"
                      />
                    </div>
                  </div>
        </Grid>
        <Grid item xs={6} sm={6} md={2.8} lg={2.8} sx={{ pr: "3%", pb: "1.5%" }}>
          <SubLabelTypography
            color={"#2b2b2b"}
            size={"14px"}
            sx={{ pb: "8px" }}
          >
            SHOP ESTABLISHMENT LICENSE
          </SubLabelTypography>
          <div
                    style={{
                      borderRadius: "3px",
                      border: "solid 1px #d8d8d8",
                      backgroundColor: "#fafafa",
                      padding: "20px 23px 57px 33px",
                    }}
                  >
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleImageChange(
                          "shopEstablishmentImage",
                          acceptedFiles
                        );
                      }}
                      name="shopEstablishment"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <span style={{ fontSize: ".8rem" }}>
                            Drop image here, or click to select file
                          </span>
                        </div>
                      )}
                    </Dropzone>
                    <div>
                      <img
                        style={{
                          width: "160px",
                          height: "150px",
                          margin: "0",
                          display: "block",
                        }}
                        src={licenseDetails?.shopEstablishmentImage?.preview}
                        alt="shopEstablishment"
                      />
                    </div>
                  </div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} sm={6} md={2.3} lg={2.3} sx={{ pr: "3%", pb: "1.5%" }}>
          <SubLabelTypography
            color={"#2b2b2b"}
            size={"14px"}
            sx={{ pb: "8px" }}
          >
            VALIDITY DATE
          </SubLabelTypography>
          <DatePicker
            selected={licenseDatePicker?.validityDateStockLicense}
            autoComplete="off"
            onChange={(date) => handleDateChange(date, "validityDateStockLicense")}
            dateFormat="dd/MM/yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="dd/mm/yyyy"
            customInput={
              <TextField
                fullWidth
                type="text"
                placeholder="dd/mm/yyyy"
                variant="outlined"
              />
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={2.3} lg={2.3} sx={{ pr: "3%", pb: "1.5%" }}>
          <SubLabelTypography
            color={"#2b2b2b"}
            size={"14px"}
            sx={{ pb: "8px" }}
          >
            VALIDITY DATE
          </SubLabelTypography>
          <DatePicker
            selected={licenseDatePicker?.rentalAgreement}
            autoComplete="off"
            onChange={(date) => handleDateChange(date, "rentalAgreement")}
            dateFormat="dd/MM/yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="dd/mm/yyyy"
            customInput={
              <TextField
                fullWidth
                type="text"
                placeholder="dd/mm/yyyy"
                variant="outlined"
              />
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={2.3} lg={2.3} sx={{ pr: "3%", pb: "1.5%" }}>
          <SubLabelTypography
            color={"#2b2b2b"}
            size={"14px"}
            sx={{ pb: "8px" }}
          >
            VALIDITY DATE
          </SubLabelTypography>
          <DatePicker
            selected={licenseDatePicker?.pplLicense}
            autoComplete="off"
            onChange={(date) => handleDateChange(date, "pplLicense")}
            dateFormat="dd/MM/yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="dd/mm/yyyy"
            customInput={
              <TextField
                fullWidth
                type="text"
                placeholder="dd/mm/yyyy"
                variant="outlined"
              />
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={2.3} lg={2.3} sx={{ pr: "3%", pb: "1.5%" }}>
          <SubLabelTypography
            color={"#2b2b2b"}
            size={"14px"}
            sx={{ pb: "8px" }}
          >
            VALIDITY DATE
          </SubLabelTypography>
          <DatePicker
            selected={licenseDatePicker?.tradeLicense}
            autoComplete="off"
            onChange={(date) => handleDateChange(date, "tradeLicense")}
            dateFormat="dd/MM/yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="dd/mm/yyyy"
            customInput={
              <TextField
                fullWidth
                type="text"
                placeholder="dd/mm/yyyy"
                variant="outlined"
              />
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={2.8} lg={2.8} sx={{ pr: "3%", pb: "1.5%" }}>
          <SubLabelTypography
            color={"#2b2b2b"}
            size={"14px"}
            sx={{ pb: "8px" }}
          >
            VALIDITY DATE
          </SubLabelTypography>
          <DatePicker
            selected={licenseDatePicker?.shopEstablishment}
            autoComplete="off"
            onChange={(date) => handleDateChange(date, "shopEstablishment")}
            dateFormat="dd/MM/yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="dd/mm/yyyy"
            customInput={
              <TextField
                fullWidth
                type="text"
                placeholder="dd/mm/yyyy"
                variant="outlined"
              />
            }
          />
        </Grid>
      </Grid>


    </div>
  );
};

export default LicenseUpload;
