import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  storePartnerList: [],
  storePartnerOrder: [],
  updatePayment: {},
  pageNoStorePartner: 0,
  hasMoreStorePartner: true,
};

export const storePartnerSlice = createSlice({
  name: "storePartner",
  initialState,
  reducers: {
    getStorePartnerList() {},
    setStorePartnerList(state, action) {
      return Object.assign({}, state, {
        storePartnerList:
          state.pageNoStorePartner > 0
            ? [...state.storePartnerList, ...action.payload]
            : isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreStorePartner: action.payload.length < 15 ? false : true,
      });
    },
    setPagenoStorePartner(state, action) {
      return Object.assign({}, state, {
        pageNoStorePartner: action.payload,
      });
    },

    setStorePartnerOrders(state, action) {
      return Object.assign({}, state, {
        storePartnerOrder: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getStorePartnerOrders() {},
    getPayStorePartner() {},
    setPayStorePartner(state, action) {
      return Object.assign({}, state, {
        updatePayment: isEmpty(action.payload) ? [] : action.payload,
      });
    },

    clearStorePartner(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  getStorePartnerOrders,
  setStorePartnerOrders,
  getStorePartnerList,
  setStorePartnerList,
  getPayStorePartner,
  setPayStorePartner,
  clearStorePartner,
  setPagenoStorePartner,
} = storePartnerSlice.actions;

export default storePartnerSlice.reducer;
