import { spawn } from 'redux-saga/effects';
import { storeSaga } from './storeSaga';
import { businessPartnerSaga } from './businessPartnerSaga';
import { cashDepositSaga } from './cashDepositSaga';
import { couponSaga } from './couponSaga';
import { indentsSaga } from './indentsSaga';
import { posTerminalSaga } from './posTerminalSaga';
import { storePartnerSaga } from './storePartnerSaga';
import { employeeSaga } from './employeeSaga';
import { partnerSaga } from './partnerSaga';
import { adminLoginSaga } from './adminLoginSaga';
import { storeOrderSaga } from './storeOrderSaga';
import { upiSaga } from './upiSaga';


export default function* rootSaga() {
    yield spawn(storeSaga);
    yield spawn(employeeSaga);
    yield spawn(partnerSaga);
    yield spawn(couponSaga);
    yield spawn(businessPartnerSaga);
    yield spawn(storePartnerSaga);
    yield spawn(cashDepositSaga);
    yield spawn(posTerminalSaga);
    yield spawn(indentsSaga);
    yield spawn(storeOrderSaga);
    yield spawn(adminLoginSaga);
    yield spawn(upiSaga);
  }