import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  confirmPayment: {},
  terminalList: [],
  pageNoTerminal: 0,
  hasMoreTerminal: true,
};

export const posTerminalSlice = createSlice({
  name: "posTerminal",
  initialState,
  reducers: {
    getTerminal() {},
    setTerminal(state, action) {
      return Object.assign({}, state, {
        terminalList:
          state.pageNoTerminal > 0
            ? [...state.terminalList, ...action.payload]
            : isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreTerminal: action.payload.length < 15 ? false : true,
      });
    },
    setPagenoTerminal(state, action) {
      return Object.assign({}, state, {
        pageNoTerminal: action.payload.pageNo,
      });
    },

    getConfirmPayment() {},
    setConfirmPayment(state, action) {
      return Object.assign({}, state, {
        confirmPayment: isEmpty(action.payload) ? {} : action.payload,
      });
    },

    clearPOS(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});   

export const {
  getTerminal,
  setTerminal,
  getConfirmPayment,
  setConfirmPayment,
  clearPOS,
  setPagenoTerminal
} = posTerminalSlice.actions;

export default posTerminalSlice.reducer;
