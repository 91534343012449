import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
    getCashDeposit,
    setCashDeposit,
    getConfirmPaymentCash,
    setConfirmPaymentCash,
} from "../reducers/cashDepositSlice";

async function fetchCashAPI(payload) {
  const userData = await axios
    .get('/client/payment/cashdeposit', {
      params: {
        limit: 15,
        page: payload,
        filter: {},
        sort: { 
          _created_at: -1,
          _id: -1
        },
        search: {   }
      }
    })
    .then((response) => response.data.data);
  return userData;
}

export function* fetchCash(action) {
  try {
    const response = yield call(fetchCashAPI, action.payload);
    yield put(setCashDeposit(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}


async function saveCashAPI(payload) {
  const userData = await axios
    .post('/client/payment/confirmcashdeposit', payload)
    .then((response) => response.data.data);
  return userData;
}
export function* saveUpi(action) {
  try {
    const { payload } = action;
    const response = yield call(saveCashAPI, payload);
    yield put(setConfirmPaymentCash(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}


export function* cashDepositSaga() {
  yield takeLatest(getCashDeposit.type, fetchCash);
  yield takeLatest(getConfirmPaymentCash.type, saveUpi);
}
