import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    HeadingTypography,
    ListTable,
} from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import {
    getStoreOrder,
    setPagenoStoreOrder
} from "../../../app/reducers/storeOrderSlice";
import _ from "lodash";

function StoreOrders(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useNavigate();

    const [loading, setLoading] = useState(false);

    const storeOrderList = useSelector(
        (state) => state.storeOrderReducer.storeOrderList
    );

    const pageNo = useSelector(
        (state) => state.storeOrderReducer.pageNoStoreOrder
    );

    const hasMore = useSelector(
        (state) => state.storeOrderReducer.hasMoreStoreOrder
    );

    const tableData = storeOrderList.map(order => {
        return {
            ...order,
            orderIdDate: order.orderId ,
            subTotal:Number(order.subTotal).toFixed(2),
            discount:Number(order.discount).toFixed(2),
            taxable:Number(order.taxable).toFixed(2),
            totalGst:Number(order.totalGst).toFixed(2),
        }
    })

    useEffect(() => {
        setLoading(false);
    }, [storeOrderList]);

    useEffect(() => {
        dispatch(getStoreOrder(0));
    }, []);

    const handleViewClick = (e, data) => {
  
        history("/store-order/" + data.orderId);
    }

    const handleLoadmore = () => {
        setLoading(true);
        dispatch(setPagenoStoreOrder(pageNo + 1));
        dispatch(getStoreOrder(pageNo + 1));
    };

    return (
        <div className={classes.root}>
            <div
                className={classes.alignStraightBetween}
                style={{ alignItems: "flex-start" }}
            >
                <HeadingTypography sx={{ mb: "2%" }}>
                    Store Orders
                </HeadingTypography>
            </div>
            <ListTable
                dataList={tableData}
                handleViewClick={handleViewClick}
                isLoading={loading}
                handleLoadmore={handleLoadmore}
                hasMore={hasMore}
                Header={[
                    "Store ID",
                    "Order ID",
                    "Order Date",
                    "Sub Total",
                    "Discount",
                    "Taxable",
                    "Total Gst",
                    "Total Amount",
                    ""
                ]}
                tableData={[
                    { type: ["TEXT"], name: "storeId" },
                    { type: ["TEXT"], name: "orderIdDate" },
                    { type: ["TEXT"], name: "orderDate" },
                    { type: ["TEXT"], name: "subTotal" },
                    { type: ["TEXT"], name: "discount" },
                    { type: ["TEXT"], name: "taxable" },
                    { type: ["TEXT"], name: "totalGst" },
                    { type: ["TEXT"], name: "inclGst" },
                    { type: ["VIEW"], name: "view" }
                ]}
            />
        </div>
    );
}

export default StoreOrders;
