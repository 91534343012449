import { Autocomplete, Grid, TextField, Typography, InputAdornment } from "@mui/material";
import React, { useState, useEffect } from "react";
import { HeadingTypography, SubLabelTypography } from "../../../components";
import { State, City }  from 'country-state-city';
import { useDispatch, useSelector } from "react-redux";
import { getPartnerDropdown } from "../../../app/reducers/storeSlice";
import _ from "lodash";

const StoreDetails = (props) => {
  const dispatch = useDispatch();

  const { storeDetails } = props;

  const [selectedState, setSelectedState] = useState("");

  const state =  State.getStatesOfCountry("IN");
  const cities = City.getCitiesOfState("IN", selectedState);


  const dropdown = useSelector((state) => state.storeReducer.partnerDropdown)

  let businessPartner = [];
  let storePartner = [];

    const data = _.isEmpty(dropdown) ? "" : 
    dropdown?.map(( value ) => {
    if(value?.userType === "Store Partner"){
      storePartner = value?.data;
    }
    else if(value?.userType === "Business Partner"){
      businessPartner = value?.data;
    }
  })
  useEffect(() => {
    dispatch(getPartnerDropdown())
  }, [dispatch])

  
  

  const handleChange = (event, value, name) => {
    if(name === "state"){
      setSelectedState(value.isoCode);
    }
    props?.handleChange(event, value, name)
  }

  return (
    <div>
      <HeadingTypography
        color={"#03a5a5"}
        size={"18px"}
        margin={"0px 0px 16px"}
      >
        NEW STORE
      </HeadingTypography>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              STORE NAME
            </SubLabelTypography>
            <TextField
              fullWidth
              value={storeDetails?.name}
              type="text"
              placeholder="Type Here"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "name")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "5%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              STORE TYPE
            </SubLabelTypography>
            <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={[ "Own Store", "Partner Store"]}
                  defaultValue={storeDetails?.storeType}
                  placeholder="Select"
                  onChange={(event, newValue) => {
                    handleChange(event, newValue, "storeType");
                  }}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} placeholder="Select" />
                  )}
                />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              Mobile
            </SubLabelTypography>
            <TextField
              fullWidth
              value={storeDetails?.phoneNumber}
              type="text"
              placeholder="Type Here"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
              onChange={(e) => handleChange(e, "", "phoneNumber")}
            />
          </div>
        </Grid>
      </Grid>

      {/* Store type: partner */}
      {storeDetails?.storeType === "Partner Store" &&
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              sx={{ pr: "2.5%", pb: "1.5%" }}
            >
              <div>
                <SubLabelTypography
                  color={"#2b2b2b"}
                  size={"14px"}
                  sx={{ pb: "8px" }}
                >
                  STORE PARTNER
                </SubLabelTypography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={storePartner}
                  defaultValue={storeDetails?.storePartner}
                  getOptionLabel={ (option) => option?.name }
                  placeholder="Select"
                  onChange={(event, newValue) => {
                    handleChange(event, newValue, "storePartners");
                  }}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} placeholder="Select" />
                  )}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              sx={{ pr: "2.5%", pb: "1.5%" }}
            >
              <div>
                <SubLabelTypography
                  color={"#2b2b2b"}
                  size={"14px"}
                  sx={{ pb: "8px" }}
                >
                  EARNINGS(%)
                </SubLabelTypography>
                <TextField
                  fullWidth
                  type="text"
                  defaultValue={storeDetails?.storeEarnings}
                  placeholder=""
                  variant="outlined"
                  onChange={(e) => handleChange(e, "", "storeEarnings")}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              sx={{ pr: "2.5%", pb: "1.5%" }}
            >
              <div>
                <SubLabelTypography
                  color={"#2b2b2b"}
                  size={"14px"}
                  sx={{ pb: "8px" }}
                >
                  BUSINESS PARTNER
                </SubLabelTypography>
                  <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={businessPartner}
                  defaultValue={storeDetails?.businessPartner}
                  getOptionLabel={ (option) => option?.name }
                  placeholder="Select"
                  onChange={(event, newValue) => {
                    handleChange(event, newValue, "businessPartners");
                  }}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} placeholder="Select" />
                  )}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              sx={{ pr: "2.5%", pb: "1.5%" }}
            >
              <div>
                <SubLabelTypography
                  color={"#2b2b2b"}
                  size={"14px"}
                  sx={{ pb: "8px" }}
                >
                  EARNINGS(%)
                </SubLabelTypography>
               <TextField
                  fullWidth
                  type="text"
                  defaultValue={storeDetails?.businessEarnings}
                  placeholder=""
                  variant="outlined"
                  onChange={(e) => handleChange(e, "", "businessEarnings")}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
       }
      <HeadingTypography
        color={"#03a5a5"}
        size={"18px"}
        margin={"20px 0px 16px"}
      >
        ADD ADDRESS
      </HeadingTypography>
      <Grid container alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          sx={{ pr: "2.5%", pb: "1.5%" }}
        >
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              PINCODE
            </SubLabelTypography>
            <TextField
              fullWidth
              defaultValue={storeDetails?.pincode}
              type="text"
              placeholder="Type Here"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "pincode")}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          sx={{ pr: "2.5%", pb: "1.5%" }}
        >
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              STATE
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={state}
              getOptionLabel={ (option) => option.name }
              defaultValue={storeDetails?.state}
              placeholder="Select"
              onChange={(event, newValue) => {
                handleChange(event, newValue, "state");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          sx={{ pr: "2.5%", pb: "1.5%" }}
        >
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              DISTRICT
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={cities}
              getOptionLabel={(option) => option.name}
              defaultValue={storeDetails?.district}
              placeholder="Select"
              onChange={(event, newValue) => {
                handleChange(event, newValue, "district");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          sx={{ pr: "2.5%", pb: "1.5%" }}
        >
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              CITY/TOWN/VILLAGE
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={cities}
              getOptionLabel={(option) => option.name}
              defaultValue={storeDetails?.city}
              placeholder="Select"
              onChange={(event, newValue) => {
                handleChange(event, newValue, "city");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "2.3%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              ADDRESS 1
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="Type Here"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "addressLine1")}
              defaultValue={storeDetails?.address1}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "2.3%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              ADDRESS 2
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="Type Here"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "addressLine2")}
              defaultValue={storeDetails?.address2}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "2.3%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              LANDMARK
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="Type Here"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "landmark")}
              defaultValue={storeDetails?.landmark}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ pr: "3%", pb: "1.5%" }}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography sx={{ pb: "8px" }}>GPS COORDINATES</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="text"
                placeholder="Latitude"
                variant="outlined"
                onChange={(e) => handleChange(e, "", "latitude")}
                defaultValue={storeDetails?.latitude}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <TextField
              fullWidth
              type="text"
              placeholder="Longitude"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "longitude")}
              defaultValue={storeDetails?.longitude}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default StoreDetails;
