import React from "react";
import {
  ContinueButton,
  ListTable,
  HeadingTypography,
} from "../../../components";
import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";

const tableData = [
  {
    id: "47347bf",
    name: "vijay",
    mobile: "9876543210",
    email: "Vijay@gmail.com",
  },
];

const Vendors = (props) => {
  const classes = useStyles();
  const history = useNavigate();

  const handleNewVendor = () => {
    history("/new-vendors");
  };
 
  return (
    <div className={classes.root}>
      <>
        <div
          className={classes.alignStraightBetween}
          style={{ marginBottom: "4px" }}
        >
          <HeadingTypography>Vendors</HeadingTypography>
          <div style={{ padding: "0px 0px 5px 0px" }}>
            <ContinueButton onClick={handleNewVendor}>
              New Vendor
            </ContinueButton>
          </div>
        </div>
        <ListTable
          dataList={tableData}
          Header={["ID", "Name", "Mobile Number", "Email ID"]}
          tableData={[
            { type: ["TEXT"], name: "id" },
            { type: ["TEXT"], name: "name" },
            { type: ["TEXT"], name: "mobile" },
            { type: ["TEXT"], name: "email" },
          ]}
        />
      </>
    </div>
  );
};

export default Vendors;
