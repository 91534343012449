import React from "react";

export const OrderRuppeeIcon = (props) => {
  const color = props?.color ?? "#03a5a5";
  const width = props?.width ?? "20";
  const height = props?.height ?? "20";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 31 31"
    >
      <g data-name="Group 1845" transform="translate(-1261 -167)">
        <rect
          data-name="Rectangle 689"
          width="31"
          height="31"
          rx="3"
          transform="translate(1261 167)"
        //   style="opacity:.1;fill:#03a5a5"
        opacity={0.1}
        fill={color}
        />
        <path
          data-name="Path 2936"
          d="M102.042 0h-8.517v1.692h3a2.543 2.543 0 0 1 2.393 1.692h-5.4v1.693h5.4a2.543 2.543 0 0 1-2.393 1.692h-3v1.692l4.959 4.959 1.2-1.2-3.766-3.759h.611a4.238 4.238 0 0 0 4.145-3.384h1.368V3.384h-1.368a4.213 4.213 0 0 0-.763-1.692h2.131z"
          transform="translate(1179.475 176)"
        //   style="fill:#03a5a5"
        fill={color}
        />
      </g>
    </svg>
  );
};
