import React from "react";
import { Grid } from "@mui/material";
import { DrawerComponent } from "../../components/ResponsiceDrawer";

const withDrawer = (Component) => (props) => {
  return (
    <Grid container>
      <Grid item md={2} lg={2}>
        <DrawerComponent />
      </Grid>
      <Grid item md={10} lg={10} >
        <Component {...props}>{props.children}</Component>
      </Grid>
    </Grid>
  );
};

export default withDrawer;
