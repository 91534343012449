import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
  login,
  loginSuccess,
  loginError,
  userErrors,
  getCurrentUser,
  getCurrentUserSuccess
} from "../reducers/adminLoginSlice";
import { API_URL } from "../../utils/constants";

function getLoginDataAPI(payload) {
  const apiURL = process.env.REACT_APP_API_URL + "/v1/accounts/auth/accounts/verify";
  const products = axios.post(apiURL, payload).then((response) => response);
  return products;
}

export function* getLoginData(action) {
  try {
    const { payload } = action;
    const response = yield call(getLoginDataAPI, payload);
    localStorage.setItem("eyePlay-auth-token", response.data.data.tokens.token);
    localStorage.setItem(
      "eyePlay-refresh-token",
      response.data.data.tokens.refreshToken
    );
    yield put(loginSuccess(response.data.data));
    yield put(getCurrentUser());
  } catch (error) {
    yield put(loginError(error.response.data.message));
  }
}

function getUserAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  };
  const apiURL = `${API_URL}/client/user/currentuserinfo`;
  const products = axios
    .get(apiURL, { headers: headers })
    .then((response) => response.data.data);
  return products;
}

export function* getUser(action) {
  try {
    const { payload } = action;
    const response = yield call(getUserAPI, payload);
    localStorage.setItem("user-name", response.name);
    localStorage.setItem("user-role", response.userType);
    yield put(getCurrentUserSuccess(response));
  } catch (error) {
    yield put(userErrors(error.response.data));
  }
}

export function* adminLoginSaga() {
  yield takeLatest(login.type, getLoginData);
  yield takeLatest(getCurrentUser.type, getUser);
}
