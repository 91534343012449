import React from "react";

export const PartnersStoreIcon = (props) => {
  const width = props?.width ?? "24";
  const height = props?.height ?? "24";
  const color = props?.color ?? "#03a5a5";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g data-name="vuesax/bold/shop">
        <path
          d="M20.789 7.02 20.5 4.25C20.079 1.23 18.709 0 15.779 0H5.019c-2.94 0-4.3 1.23-4.73 4.28l-.27 2.75a4.11 4.11 0 0 0 .82 2.92 4.01 4.01 0 0 0 3.23 1.55A4.093 4.093 0 0 0 7.3 9.86a3.815 3.815 0 0 0 6.24.04 4.1 4.1 0 0 0 3.2 1.6 3.982 3.982 0 0 0 3.28-1.63 4.072 4.072 0 0 0 .769-2.85z"
          transform="translate(1.571 1.25)"
        //   style="fill:#03a5a5"
        fill={color}
        />
        <path
          data-name="Vector"
          d="M2.23.035A2.5 2.5 0 0 0 0 2.525v2.74a.491.491 0 0 0 .49.49h4.77a.491.491 0 0 0 .49-.49v-2.39C5.76.785 4.53-.2 2.23.035z"
          transform="translate(9.12 16.625)"
        //   style="fill:#03a5a5"
        fill={color}
        />
        <path
          data-name="Vector"
          d="M18.74 1.82V4.8a5 5 0 0 1-5 5 .491.491 0 0 1-.49-.49V6.92a3.79 3.79 0 0 0-1.15-2.96 3.881 3.881 0 0 0-2.71-.91 6.854 6.854 0 0 0-.77.04 3.485 3.485 0 0 0-3.13 3.48v2.74A.491.491 0 0 1 5 9.8a5 5 0 0 1-5-5V1.84A1 1 0 0 1 1.34.9a4.671 4.671 0 0 0 .82.2 2.325 2.325 0 0 0 .37.04 3.866 3.866 0 0 0 .48.03A5.081 5.081 0 0 0 6.21 0a4.852 4.852 0 0 0 3.16 1.17A4.788 4.788 0 0 0 12.52.02a5.052 5.052 0 0 0 3.16 1.15 4.578 4.578 0 0 0 .53-.03c.12-.01.23-.02.34-.04a4.818 4.818 0 0 0 .87-.22 1 1 0 0 1 1.32.94z"
          transform="translate(2.63 12.58)"
        //   style="fill:#03a5a5"
        fill={color}
        />
      </g>
    </svg>
  );
};
