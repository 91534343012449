import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import {
  HeadingTypography,
  ListTable,
  UpdateStatus,
} from "../../../components";
import { useStyles } from "./styles";
import {
  getIndentsList,
  getUpdatePaymentIndent,
  setPagenoIndents,
  clearIndents,
} from "../../../app/reducers/indentsSlice";
import IndentOrders from "./IndentOrders";

function Indents(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const indentList = useSelector((state) => state.indentsReducer.indentsList);
  const paymentUpdated = useSelector(
    (state) => state.indentsReducer.paymentUpdated
  );
  const pageNo = useSelector((state) => state.indentsReducer.pageNoIndents);
  const hasMore = useSelector((state) => state.indentsReducer.hasMoreIndents);
  const intendsOrder = useSelector(
    (state) => state.indentsReducer.intendsOrder
  );



  const history = useNavigate();
  const [updateStatus, setUpdateStatus] = useState(false);
  const [dateValue, setDateValue] = useState({});
  const [order, setOrder] = useState(false);
  const [state, setState] = useState({});
  const [indentData, setIndentData] = useState([]);
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [indentList]);

  useEffect(() => {
    dispatch(clearIndents());
    dispatch(getIndentsList(0));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(paymentUpdated)) {
      setUpdateStatus(false);      
      dispatch(clearIndents());
      dispatch(getIndentsList(0));
    }
  }, [paymentUpdated]);

  const handleDateChange = (newValue, name) => {
    const date = new Date(newValue).toISOString();
    setDateValue({ ...dateValue, [name]: newValue });
    setState({ ...state, [name]: date });
  };

  const handleChange = (e, type, name) => {
    if (type === "text") {
      setState({ ...state, [name]: e.target.value });
    } else {
      setState({ ...state, [name]: { label: type.label, value: type.value } });
    }
  };

  let tableData = [];

  indentList?.map((indent) => {
    const indentData = {
      id: indent?._id ,
      date: moment(indent?.intendDate).format("ll"),
      orders: indent?.totalOrders,
      amount: indent?.inclGst,
      transaction: !_.isEmpty(indent?.warehousePayment)
        ? indent?.warehousePayment?.transactionId +
          "/" +
          moment(indent?.warehousePayment?.transactionDate).format("ll")
        : "-",
      status: !_.isEmpty(indent?.warehousePayment) ? "PAID" : "PENDING",
      data: indent,
    };
    tableData.push(indentData);

    return tableData;
  });

  const handleViewClick = (e, data) => {

    setOrder(true);
    setDetails(data.data);
    // dispatch(getIndentsOrders(data.data?._id));
  };

  const handleUpdateStatus = (e, data) => {
    setIndentData(data);
    setUpdateStatus(true);
  };

  const handleCloseUpdate = () => {
    setUpdateStatus(false);
  };

  const handleOrdersClose = () => {
    setOrder(false);
  };

  const handleUpdateSubmit = () => {
    const obj = {
      transactionAmount: indentData?.data?.inclGst.toString(),
      intendId: indentData?.data?._id.toString(),
      ...state,
    };
    dispatch(getUpdatePaymentIndent(obj));
  };

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoIndents(pageNo + 1));
    dispatch(getIndentsList(pageNo + 1));
  };

  return (
    <>
      {order ? (
        <IndentOrders
          handleOrdersClose={handleOrdersClose}
          details={details}
        />
      ) : (
        <div className={classes.root}>
          <HeadingTypography sx={{ mb: "2%" }}>
            Indents Payment
          </HeadingTypography>
          <ListTable
            dataList={tableData}
            handleViewClick={handleViewClick}
            handleUpdateStatus={handleUpdateStatus}
            isLoading={loading}
            handleLoadmore={handleLoadmore}
            hasMore={hasMore}
            Header={[
              "Indent ID",
              "Indent Date",
              "Total Orders",
              "Payable Amount",
              "Transaction ID/Date",
              "Status",
              "",
              "",
              // "",
            ]}
            tableData={[
              { type: ["TEXT"], name: "id" },
              { type: ["TEXT"], name: "date" },
              { type: ["TEXT"], name: "orders" },
              { type: ["TEXT"], name: "amount" },
              { type: ["TEXT"], name: "transaction" },
              { type: ["STATUS"], name: "status" },
              { type: ["VIEW_ORDERS"], name: "" },
              { type: ["UPDATE_STATUS"], name: "" },
            ]}
          />
          <UpdateStatus
            updateStatus={updateStatus}
            handleCloseUpdate={handleCloseUpdate}
            handleDateChange={handleDateChange}
            dateValue={dateValue}
            handleChange={handleChange}
            handleSubmitUpdate={handleUpdateSubmit}
          />
        </div>
      )}
    </>
  );
}

export default Indents;
