import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
    setIndentsList,
    getIndentsList,
    getIndentsOrders,
    setIndentsOrders,
    getUpdatePaymentIndent,
    setUpdatePaymentIndent
} from "../reducers/indentsSlice";

async function fetchIndentsAPI(payload) {
  const userData = await axios
    .get('/client/intends/warehousepaymentlist', {
      params: {
        limit: 15,
        page: payload,
        filter: {},
        sort: {
          intendDate: -1,
          _id: -1
        },
      }
    })
    .then((response) => response.data.data);
  return userData;
}
export function* fetchIndents(action) {
  try {
    const response = yield call(fetchIndentsAPI, action.payload);
    yield put(setIndentsList(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function fetchIndentsOrdersAPI(payload) {
    const userData = await axios
      .get('/client/intends/fetchintenddetailsadmin', {
        params: {
          intendId: payload
        }
      })
      .then((response) => response.data.data);
    return userData;
  }
  
  export function* fetchIndentsOrders(action) {
    try {
      const { payload } = action;
      const response = yield call(fetchIndentsOrdersAPI, payload);
      yield put(setIndentsOrders(response));
    } catch (error) {
      yield put({ type: "ERRORS", payload: error });
    }
  }

  async function updatePaymentAPI(payload) {
    const userData = await axios
      .post('/client/intends/paywarehouse', payload)
      .then((response) => response);
    return userData;
  }
  
  export function* updatePayment(action) {
    try {
      const { payload } = action;
      const response = yield call(updatePaymentAPI, payload);
      yield put(setUpdatePaymentIndent(response));
    } catch (error) {
      yield put({ type: "ERRORS", payload: error });
    }
  }

export function* indentsSaga() {
  yield takeLatest(getIndentsList.type, fetchIndents);
  yield takeLatest(getIndentsOrders.type, fetchIndentsOrders);
  yield takeLatest(getUpdatePaymentIndent.type, updatePayment);
}
