import { combineReducers } from 'redux'
import storeReducer from './storeSlice';
import businessPartnerReducer from './businessPartnerSlice';
import cashDepositReducer from './cashDepositSlice';
import couponReducer from './couponSlice';
import employeeReducer from './employeeSlice';
import indentsReducer from './indentsSlice';
import partnerReducer from './partnerSlice';
import posTerminalReducer from './posTerminalSlice';
import storePartnerReducer from './storePartnerSlice';
import vendorReducer from './vendorSlice';
import adminReducer from './adminLoginSlice';
import storeOrderReducer from './storeOrderSlice';
import   upiReducer from './upiSlice';

export default combineReducers({
    storeReducer,
    businessPartnerReducer,
    cashDepositReducer,
    couponReducer,
    employeeReducer,
    indentsReducer,
    partnerReducer,
    posTerminalReducer,
    storePartnerReducer,
    vendorReducer,
    adminReducer,
    storeOrderReducer,
    upiReducer,
});