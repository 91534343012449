import React from "react";

export const CallIcon = (props) => {
  return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.199"
        height="19.199"
        viewBox="0 0 19.199 19.199"
      >
        <path
          d="M12.959 17.2a7.318 7.318 0 0 1-2.84-.632 15.019 15.019 0 0 1-2.872-1.648 24.97 24.97 0 0 1-2.7-2.3 23.9 23.9 0 0 1-2.3-2.688A14.928 14.928 0 0 1 .624 7.088 7.407 7.407 0 0 1 0 4.232a4.821 4.821 0 0 1 .328-1.768A4.273 4.273 0 0 1 1.4.912 2.905 2.905 0 0 1 3.472 0a2.163 2.163 0 0 1 .9.2 1.831 1.831 0 0 1 .768.648L7 3.464a3.1 3.1 0 0 1 .384.68 1.8 1.8 0 0 1 .16.712 1.7 1.7 0 0 1-.256.88 3.2 3.2 0 0 1-.536.68l-.544.568a.39.39 0 0 0 .024.056 6.152 6.152 0 0 0 .656.888c.392.448.76.856 1.128 1.232.472.464.864.832 1.232 1.136a5.21 5.21 0 0 0 .928.664l-.016.04.584-.576a2.988 2.988 0 0 1 .72-.552 1.689 1.689 0 0 1 1.56-.088 3.642 3.642 0 0 1 .672.376l2.656 1.888a1.719 1.719 0 0 1 .64.76 2.315 2.315 0 0 1 .176.864 2.668 2.668 0 0 1-.256 1.128 3.726 3.726 0 0 1-.64.96 4.1 4.1 0 0 1-1.528 1.1 4.723 4.723 0 0 1-1.785.34zm-9.487-16a1.753 1.753 0 0 0-1.24.576A3.069 3.069 0 0 0 1.448 2.9 3.5 3.5 0 0 0 1.2 4.232a6.167 6.167 0 0 0 .528 2.384 13.353 13.353 0 0 0 1.5 2.608A22.131 22.131 0 0 0 5.4 11.767a22.524 22.524 0 0 0 2.552 2.176 13 13 0 0 0 2.632 1.512 4.771 4.771 0 0 0 3.7.28 2.953 2.953 0 0 0 1.1-.792 2.594 2.594 0 0 0 .448-.672 1.417 1.417 0 0 0 .144-.616.955.955 0 0 0-.088-.4.605.605 0 0 0-.224-.24l-2.656-1.888a2.293 2.293 0 0 0-.44-.248.421.421 0 0 0-.52.024 1.824 1.824 0 0 0-.464.36l-.608.6a1.14 1.14 0 0 1-1.16.24l-.216-.1a6.819 6.819 0 0 1-1.137-.803 23.64 23.64 0 0 1-1.3-1.208c-.392-.4-.784-.824-1.192-1.3a6.825 6.825 0 0 1-.816-1.128l-.1-.24a1.493 1.493 0 0 1-.064-.4 1.027 1.027 0 0 1 .3-.744l.6-.624a2.316 2.316 0 0 0 .36-.448.513.513 0 0 0 .088-.272.744.744 0 0 0-.064-.256 2.3 2.3 0 0 0-.256-.424l-1.851-2.62a.737.737 0 0 0-.3-.248 1 1 0 0 0-.396-.088zm6.688 9.807-.128.544.216-.56a.1.1 0 0 0-.089.016z"
          transform="translate(1 1)"
          fill="#827e7e"
          data-name="vuesax/outline/call"
        />
      </svg>
  );
};
