import {
  Autocomplete,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ContinueButton,
  HeadingTypography,
  SubLabelTypography,
  SuccessModal,
  BackIcon,
} from "../../../components";
import { State, City }  from 'country-state-city';
import { getSaveEmployee } from "../../../app/reducers/employeeSlice";
import Dropzone from "react-dropzone";
import S3 from "react-aws-s3";
import { useDispatch } from "react-redux";
window.Buffer = window.Buffer || require("buffer").Buffer;


const employeeRole = [
  { name: "Optometrist"},
  { name: "Sales Executive"},
  { name: "Field Assistant"},
  {name: "Store Manager"}];

const NewEmployee = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [employee, setEmployee] = useState({});
  const [success, setSuccess] = useState(false);
  const [succesSubHeader, setSuccessSubHeader] = useState("");


  const [selectedState, setSelectedState] = useState("");
  const state =  State.getStatesOfCountry("IN");
  const cities = City.getCitiesOfState("IN", selectedState);

  
  const config = {
    bucketName: process.env.REACT_APP_AWS_BUCKET, //"oicappsv3-dev",
    dirName: "eyeplay",
    region: "ap-south-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  };

  const ReactS3Client = new S3(config);

  const uploadToS3 = async (file, name) => {
    return await ReactS3Client.uploadFile(
      file,
      `Accounts/Employees/${employee?.aadhar}${employee?.pan}${name}`
    );
  };

  const imageUpload = async (file, name) => {
    let obj = {};
    const data = await uploadToS3(file, name);
    Object.assign(obj, { [name]: data.location });
    return obj;
  };  

  const handleChange = (event, value, name) => {
    if(name === "state"){
      setSelectedState(value.isoCode);
    }
    if (value) {
      setEmployee({ ...employee, [name]: value.name });
    } else {
      setEmployee({ ...employee, [name]: event.target.value });
    }
  };

  const handleImageChange = (name, files) => {
    const fileWithPreview = files.map((file) =>
    Object.assign(file, {
      preview: URL.createObjectURL(file),
    })
  );

    setEmployee({ ...employee, [name]: fileWithPreview[0]});    
  };

  const handleSubmit = async () => {
    const obj = await imageUpload(employee?.employeePhoto, "employeePhoto");
    const obj1 = await imageUpload(employee?.certificates, "certificates");
    const obj2 = await imageUpload(employee?.idProof, "idProof");
    const submitObject = {
      ...employee,
      employeePhoto: obj.employeePhoto,
      certificates: obj1.certificates,
      idProof: obj2.idProof
    }
    const data = dispatch(getSaveEmployee(submitObject));
    if(data){
      const subHeader = employee?.name + "/"+ employee?.userType;
      setSuccessSubHeader(subHeader);
      setSuccess(true);
    }

  };
  const handleSuccessClose = () => {
    props?.handleBack();
  };



  return (
    <div>
       <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          aligntItems: "center",
        }}
      >
        <div onClick={props?.handleBack}>
          <BackIcon />
        </div>
        <HeadingTypography
        color={"#03a5a5"}
        size={"18px"}
        margin={"0px 0px 16px"}
        sx={{ padding: "0px 0px 0px 10px" }}
      >
        NEW EMPLOYEES
      </HeadingTypography>
      </div>
     

      <Grid container alignItems="center" sx={{ width: "80%" }}>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "5%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              ROLE
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={employeeRole}
              getOptionLabel={ (option) => option.name }
              placeholder="Select"
              onChange={(event, newValue) => {
                handleChange(event, newValue, "userType");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container alignItems="center" width={"80%"}>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              NAME
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="Type Here"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "name")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              MOBILE NUMBER
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="Type Here"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
              onChange={(e) => handleChange(e, "", "phoneNumber")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              EMAIL
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="example@gmail.com"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "email")}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container alignItems="center" width={"80%"}>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              STATE
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={state}
              placeholder="Select"
              getOptionLabel={ (option) => option.name }
              onChange={(event, newValue) => {
                handleChange(event, newValue, "state");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              CITY
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={cities}
              getOptionLabel={ (option) => option.name }
              placeholder="Select"
              onChange={(event, newValue) => {
                handleChange(event, newValue, "city");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              ADDRESS
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="Type Here"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "address")}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container alignItems="center" width={"80%"}>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              AADHAR NUMBER
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder=""
              variant="outlined"
              onChange={(e) => handleChange(e, "", "aadhar")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              PAN
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder=""
              variant="outlined"
              onChange={(e) => handleChange(e, "", "pan")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              PASSWORD
            </SubLabelTypography>
            <TextField
              fullWidth
              type="password"
              placeholder=""
              variant="outlined"
              onChange={(e) => handleChange(e, "", "password")}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container alignItems="center">
        <Grid item xs={6} sm={6} md={3} lg={3} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              EMPLOYEE PHOTO
            </SubLabelTypography>
            <div
                    style={{
                      borderRadius: "3px",
                      border: "solid 1px #d8d8d8",
                      backgroundColor: "#fafafa",
                      padding: "20px 23px 57px 33px",
                    }}
                  >
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleImageChange(
                          "employeePhoto",
                          acceptedFiles
                        );
                      }}
                      name="employeePhoto"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <span style={{ fontSize: ".8rem" }}>
                            Drop image here, or click to select file
                          </span>
                        </div>
                      )}
                    </Dropzone>
                    <div>
                      <img
                        style={{
                          width: "200px",
                          height: "150px",
                          margin: "0",
                          display: "block",
                        }}
                        src={employee?.employeePhoto?.preview}
                        alt="employeePhoto"
                      />
                    </div>
                  </div>
          </div>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              CERTIFICATES
            </SubLabelTypography>
            <div
                    style={{
                      borderRadius: "3px",
                      border: "solid 1px #d8d8d8",
                      backgroundColor: "#fafafa",
                      padding: "20px 23px 57px 33px",
                    }}
                  >
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleImageChange(
                          "certificates",
                          acceptedFiles
                        );
                      }}
                      name="certificates"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <span style={{ fontSize: ".8rem" }}>
                            Drop image here, or click to select file
                          </span>
                        </div>
                      )}
                    </Dropzone>
                    <div>
                      <img
                        style={{
                          width: "200px",
                          height: "150px",
                          margin: "0",
                          display: "block",
                        }}
                        src={employee?.certificates?.preview}
                        alt="certificates"
                      />
                    </div>
                  </div>
          </div>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              ID PROOF
            </SubLabelTypography>
            <div
                    style={{
                      borderRadius: "3px",
                      border: "solid 1px #d8d8d8",
                      backgroundColor: "#fafafa",
                      padding: "20px 23px 57px 33px",
                    }}
                  >
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleImageChange(
                          "idProof",
                          acceptedFiles
                        );
                      }}
                      name="idProof"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <span style={{ fontSize: ".8rem" }}>
                            Drop image here, or click to select file
                          </span>
                        </div>
                      )}
                    </Dropzone>
                    <div>
                      <img
                        style={{
                          width: "200px",
                          height: "150px",
                          margin: "0",
                          display: "block",
                        }}
                        src={employee?.idProof?.preview}
                        alt="idProof"
                      />
                    </div>
                  </div>
          </div>
        </Grid>
      </Grid>     
      <ContinueButton  onClick={handleSubmit}>
        Submit
      </ContinueButton>
      <SuccessModal
        success={success}
        successHeader={"Employee added successfully"}
        succesSubHeader={succesSubHeader}
        button={"Go to Employees"}
        handleSuccessClose={handleSuccessClose}
      ></SuccessModal>
    </div>
  );
};

export default NewEmployee;
