import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  storeDetails: {},
  licenseDetails: {},
  employeeDetails: [],
  storeList: [],
  employeeDropdown: [],
  partnerDropdown: {},
  storesAdded: {},
  storesAddedError: "",
  storesEditedSuccess: {},
  storesEditedError: "",
  storePartnerDetails: [],
  pageNoStore: 0,
  hasMoreStore: true,
};

export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    getStoreList() {},
    setStoreList(state, action) {
      return Object.assign({}, state, {
        storeList:
          state.pageNoStore > 0
            ? [...state.storeList, ...action.payload]
            : isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreStore: action.payload.length < 15 ? false : true,
      });
    },
    setPagenoStore(state, action) {
      return Object.assign({}, state, {
        pageNoStore: action.payload,
      });
    },

    getStoreAdd() {},
    setStoreAdd(state, action) {
      return Object.assign({}, state, {
        storesAdded: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setStoreAddError(state, action) {
      return Object.assign({}, state, {
        storesAddedError: Array.isArray(action.payload) ? action.payload[0].msg : action.payload,
      });
    },

    getStoreEdit() {},
    getStoreEditSuccess(state, action) {
      return Object.assign({}, state, {
        storesEditedSuccess: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    getStoreEditError(state, action) {
      return Object.assign({}, state, {
        storesEditedError: Array.isArray(action.payload) ? action.payload[0].msg : action.payload,
      });
    },

    setStoreDetails(state, action) {
      return Object.assign({}, state, {
        storeDetails: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setUploadLicense(state, action) {
      return Object.assign({}, state, {
        licenseDetails: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setEmployeeDetails(state, action) {
      return Object.assign({}, state, {
        employeeDetails: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getEmployeeDropdown() {},
    setEmployeeDropdown(state, action) {
      return Object.assign({}, state, {
        employeeDropdown: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getPartnerDropdown() {},
    setPartnerDropdown(state, action) {
      return Object.assign({}, state, {
        partnerDropdown: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    getStorePartnerDetails() {},
    setStorePartnerDetails(state, action) {
      return Object.assign({}, state, {
        storePartnerDetails: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    clearStoreReducer(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  setStoreDetails,
  setUploadLicense,
  setEmployeeDetails,
  setStoreList,
  getStoreList,
  getEmployeeDropdown,
  setEmployeeDropdown,
  setPartnerDropdown,
  getPartnerDropdown,
  setStoreAdd,
  getStoreAdd,
  getStorePartnerDetails,
  setStorePartnerDetails,
  clearStoreReducer,
  setPagenoStore,
  setStoreAddError,
  getStoreEdit,
  getStoreEditSuccess,
  getStoreEditError
} = storeSlice.actions;

export default storeSlice.reducer;
