import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import { getUpi, setUpi } from "../reducers/upiSlice";

async function upiAPI(payload) {
  const userData = await axios
    .get('/client/payment/posterminal', {
      params: {
        limit: 15,
        page: payload.pageNo,
        payload: {},
        paymentType: payload.paymentType,
        store: payload.store,
        date: payload.date,
        startDate: payload.startDate,
        endDate: payload.endDate,
        storeId: payload.storeId,
        filter: {},
        sort: { 
          transactionDate: -1,
          orderId: -1
        },
        search: {   }
      }
    })
    .then((response) => response.data.data);
  return userData;
}

export function* fetchUpi(action) {
  try {
    const response = yield call(upiAPI, action.payload);
    yield put(setUpi(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* upiSaga() {
  yield takeLatest(getUpi.type, fetchUpi);
}
