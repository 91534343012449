import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  partnerSaved: {},
  partnerList: [],
  partnerStoreDetails: []
};

export const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    getPartnerList() {},
    setPartnerList(state, action) {
      return Object.assign({}, state, {
        partnerList: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    setSavePartner(state, action) {
      return Object.assign({}, state, {
        partnerSaved: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    getSavePartner() {},
    setStorePartnerDetails(state, action) {
      return Object.assign({}, state, {
        partnerStoreDetails: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getStorePartnerDetails() {},
  },
});

export const {
  setPartnerList,
  getPartnerList,
  getSavePartner,
  setSavePartner,
  getStorePartnerDetails,
  setStorePartnerDetails
} = vendorSlice.actions;

export default vendorSlice.reducer;
