import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    alignStraightBetween:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between"
    },
    root:{
        padding: "90px 24px 40px 26px",
        overflow: "hidden",
    },
}) 