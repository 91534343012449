import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { HeadingTypography, ListTable } from "../../../components";
const useStyles = makeStyles({

    root: {
        padding: "90px 24px 40px 26px",
        overflow: "hidden",
    },
});
const B2binvoice = () => {
    const history = useNavigate();
    const classes = useStyles();
    let tableData = [{ invoiceIdDate: "EP-EEP-ORD-0085", subTotal: 3220, discound: "", totalGst: 3220, totalgst: 386.4, grandTotal: 3606.40 }];
    const handleViewClick = (e, data) => {
        history("/invoiceorder");
    };
    return (
        <div className={classes.root}>
            <HeadingTypography sx={{ mb: "2%" }}>
                B2b Invoice
            </HeadingTypography>
            <div>
                <ListTable
                    dataList={tableData}
                    handleViewClick={handleViewClick}
                    Header={["Invoice ID", "Sub Total", "GST Amount", "Grand Total", "", ""]}
                    paddingLeft="100px"
                    tableData={[
                        { type: ["TEXT"], name: "invoiceIdDate" },
                        { type: ["TEXT"], name: "subTotal" },
                        { type: ["TEXT"], name: "totalGst" },
                        { type: ["TEXT"], name: "grandTotal" },
                        { type: ["DOWNLOAD"], name: "" },
                        { type: ["VIEW"], name: "" },
                    ]}
                />
            </div>
        </div>
    )
}
export default B2binvoice;