import React, { useEffect, useState } from "react";
import {
  ContinueButton,
  HeadingTypography,
  SubLabelTypography,
} from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./styles";
import { Grid, TextField, Autocomplete } from "@mui/material";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { getSaveCoupons } from "../../app/reducers/couponSlice";
import _ from "lodash";

function NewCoupons(props) {
  const classes = useStyles();
  const [coupons, setCoupons] = useState({});
  const dispatch = useDispatch();

  const [dateValue, setDateValue] = useState({});
  const couponSaved = useSelector((state) => state.couponReducer.couponSaved);

  useEffect(() => {
    if (!_.isEmpty(couponSaved)) {
      props?.handleCouponsDialogClose();
    }
  }, [couponSaved]);

  const handleChange = (event, value, name) => {
    if (value) {
      setCoupons({ ...coupons, [name]: value?.value });
    } else {
      setCoupons({ ...coupons, [name]: event.target.value });
    }
  };

  const handleDateChange = (newValue, name) => {
    const date = new Date(newValue).toISOString();
    setDateValue({ ...dateValue, [name]: newValue });
    setCoupons({ ...coupons, [name]: date });
  };

  const handleSubmit = () => {
    dispatch(getSaveCoupons(coupons));
  };

  return (
    <div style={{ width: "700px" }}>
      <div
        className={classes.alignStraightBetween}
        style={{ marginBottom: "12px" }}
      >
        <HeadingTypography>New Coupons</HeadingTypography>
        <div
          style={{ cursor: "pointer" }}
          onClick={props?.handleCouponsDialogClose}
        >
          <CloseIcon sx={{ fontSize: "12px", color: "#827e7e" }} />
        </div>
      </div>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              COUPON CODE
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="Type Here"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "couponCode")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              DISCOUNT VALUE
            </SubLabelTypography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={[
                { label: "Percent(%)", value: "Percentage" },
                { label: "Amount", value: "Amount" },
              ]}
              placeholder="Select"
              onChange={(event, newValue) => {
                handleChange(event, newValue, "discountType");
              }}
              renderInput={(params) => (
                <TextField fullWidth {...params} placeholder="Select" />
              )}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} sx={{ pr: "3%", pb: "1.5%" }}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              DISCOUNT(&#8377;)
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="0.00"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "discountValue")}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container alignItems="center" sx={{ mt: "1.5%" }}>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              ORDER AMOUNT RANGE (&#8377;)
            </SubLabelTypography>
          </div>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div className={classes.alignStraight}>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px", mr: "8px" }}
            >
              MIN
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="0.00"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "minOrderAmount")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
          <div className={classes.alignStraight}>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px", mr: "8px" }}
            >
              MAX
            </SubLabelTypography>
            <TextField
              fullWidth
              type="text"
              placeholder="0.00"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "maxOrderAmount")}
            />
          </div>
        </Grid>
        {coupons?.discountType === "Percentage" ? (
          <Grid item xs={12} sm={4} md={4} lg={4} sx={{ pr: "3%", pb: "1.5%" }}>
            <div className={classes.alignStraight}>
              <SubLabelTypography
                color={"#2b2b2b"}
                size={"14px"}
                sx={{ pb: "8px", mr: "8px" }}
              >
                MAX DISCOUNT
              </SubLabelTypography>
              <TextField
                fullWidth
                type="text"
                placeholder="0.00"
                variant="outlined"
                onChange={(e) => handleChange(e, "", "maxDiscount")}
              />
            </div>
          </Grid>
        ) : (
          ""
        )}
      </Grid>

      <Grid container alignItems="center" sx={{ mt: "1.5%" }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              VALIDITY DATE RANGE
            </SubLabelTypography>
          </div>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={6} sm={6} md={5} lg={5} sx={{ pr: "3%", pb: "1.5%" }}>
          <div className={classes.alignStraight}>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px", mr: "8px" }}
            >
              STARTS
            </SubLabelTypography>
            <DatePicker
              selected={dateValue?.startDate}
              autoComplete="off"
              onChange={(date) => handleDateChange(date, "startDate")}
              dateFormat="dd/MM/yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mm/yyyy"
              customInput={
                <TextField
                  fullWidth
                  type="text"
                  placeholder="dd/mm/yyyy "
                  variant="outlined"
                />
              }
            />
          </div>
        </Grid>
        <Grid item xs={6} sm={6} md={5} lg={5} sx={{ pr: "3%", pb: "1.5%" }}>
          <div className={classes.alignStraight}>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px", mr: "8px" }}
            >
              ENDS
            </SubLabelTypography>
            <DatePicker
              selected={dateValue?.endDate}
              autoComplete="off"
              onChange={(date) => handleDateChange(date, "endDate")}
              dateFormat="dd/MM/yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="dd/mm/yyyy"
              customInput={
                <TextField
                  fullWidth
                  type="text"
                  placeholder="dd/mm/yyyy "
                  variant="outlined"
                />
              }
            />
          </div>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          lg={10}
          sx={{ pr: "3%", pb: "1.5%", mt: "8px" }}
        >
          <div>
            <SubLabelTypography
              color={"#2b2b2b"}
              size={"14px"}
              sx={{ pb: "8px" }}
            >
              DESCRIPTION
            </SubLabelTypography>
            <TextField
              multiline
              rows={4}
              fullWidth
              type="text"
              placeholder="Type Here"
              variant="outlined"
              onChange={(e) => handleChange(e, "", "description")}
            />
          </div>
        </Grid>
      </Grid>
      <ContinueButton
        onClick={handleSubmit}
        sx={{ mt: "8px", mb: "8px" }}
        marginLeft={"0px"}
      >
        Submit
      </ContinueButton>
    </div>
  );
}

export default NewCoupons;
