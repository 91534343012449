import { call, put, takeLatest } from "redux-saga/effects";
import axios from "../../axiosInstance";
import {
  setBusinessPartnerOrders,
  getBusinessPartnerList,
  setBusinessPartnerList,
  getPayBusinessPartner,
  setPayBusinessPartner,
} from "../reducers/businessPartnerSlice";

async function fetchStorePartnerListAPI(payload) {
  const userData = await axios
    .get('/client/partner/businesspartnerpaymentlist', {
      params: {
        limit: 15,
        page: payload,
        filter: {},
        sort: {
          paid: 1,
          orderDate: -1
        },
        search: "",
      }
    })
    .then((response) => response.data.data);
  return userData;
}

export function* fetchStorePartnerList(action) {
  try {
    const response = yield call(fetchStorePartnerListAPI, action.payload);
    yield put(setBusinessPartnerList(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function payStorePartnerAPI(payload) {
  const userData = await axios
    .post('/client/partner/paybusinesspartner', payload)
    .then((response) => response);
  return userData;
}

export function* payStorePartner(action) {
  try {
    const { payload } = action;
    const response = yield call(payStorePartnerAPI, payload);
    yield put(setPayBusinessPartner(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function fetchStorePartnerOrdersAPI(payload) {
  const userData = await axios
    .get('/client/couponcode/fetch', {
      params: {
        limit: 10,
        page: 0,
        filter: {},
        sort: {
          _created_at: -1
        },
        search: "",
      }
    })
    .then((response) => response.data.data);
  return userData;
}

export function* fetchStorePartnerOrders(action) {
  try {
    const response = yield call(fetchStorePartnerOrdersAPI, action.payload);
    yield put(setBusinessPartnerOrders(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* businessPartnerSaga() {
  yield takeLatest(getBusinessPartnerList.type, fetchStorePartnerList);
  yield takeLatest(getPayBusinessPartner.type, payStorePartner);
}
