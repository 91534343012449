import React, { useState, useEffect } from "react";
import {
  ContinueButton,
  GeneralDataModal,
  ListTable,
  HeadingTypography,
} from "../../../components";
import { useStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import NewPartners from "./NewPartners";
import { useDispatch, useSelector } from "react-redux";
import {
  getPartnerList,
  getStorePartnerDetails,
  clearPartner,setPagenoPartner
} from "../../../app/reducers/partnerSlice";
import _ from "lodash";
import ViewPartner from "./ViewPartner";

const Partners = (props) => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();

  const [generalDialog, setGeneralDialog] = useState(false);
  const [openPartner, setOpenPartner] = useState(false);
  const [viewPartner, setViewPartner] = useState(false);
  const [rowData, setRowData] = useState({});
  const [loading, setLoading] = useState(false);

  const partnerList = useSelector((state) => state.partnerReducer.partnerList);
  const partnerStoreDetails = useSelector(
    (state) => state.partnerReducer.partnerStoreDetails
  );
  const partnerSaved = useSelector(
    (state) => state.partnerReducer.partnerSaved
  );
  const partnerEdited = useSelector(
    (state) => state.partnerReducer.partnerEdited
  );
  const pageNo = useSelector((state) => state.partnerReducer.pageNoPartner);
  const hasMore = useSelector((state) => state.partnerReducer.hasMorePartner);
  
  useEffect(() => {
    setLoading(false);
  }, [partnerList]);

  const handleNewPartner = () => {
    setOpenPartner(true);
  };

  useEffect(() => {
    if (!_.isEmpty(partnerSaved)) {
      dispatch(clearPartner());
      dispatch(getPartnerList(0));
    }
  }, [partnerSaved]);

  useEffect(() => {
    if (!_.isEmpty(partnerEdited)) {
      dispatch(clearPartner());
      dispatch(getPartnerList(0));
    }
  }, [partnerEdited]);

  useEffect(() => {
    dispatch(clearPartner());
    dispatch(getPartnerList(0));
  }, []);

  
  const handleBack = () => {
    setOpenPartner(false);
    setViewPartner(false);
  };

  const handlePartnerStore = (e, data) => {
    const obj = {
      id: data.data?._id,
      type: data.data?.userType,
    };
    dispatch(getStorePartnerDetails(obj));
    setGeneralDialog(true);
  };

  const handleCloseGeneralDialog = () => {
    setGeneralDialog(false);
  };

  const handleStatusClick = (e, data) => {
    setViewPartner(true);
    setRowData(data.data);
  };

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoPartner(pageNo + 1));
    dispatch(getPartnerList(pageNo + 1));
  };

  let tableData = [];

  partnerList?.map((partner) => {
    const partnerData = {
      type: partner?.userType,
      id: partner?._id,
      name: partner?.name,
      mobile: partner?.phoneNumber,
      email: partner?.email,
      data: partner,
    };
    tableData.push(partnerData);
    return partnerData;
  });

  let secondaryTable = [];

  const data = _.isEmpty(partnerStoreDetails)
    ? []
    : partnerStoreDetails?.map((data) => {
        const psDetails = {
          id: data?.storeId,
          name: data?.name,
          district: data?.district,
          city: data?.city,
          pincode: data?.pincode,
        };
        secondaryTable.push(psDetails);
        return psDetails;
      });

  const BodyComp = () => {
    return (
      <div style={{ width: "600px" }}>
        <div
          className={classes.alignStraightBetween}
          style={{ marginBottom: "12px" }}
        >
          <HeadingTypography>Stores</HeadingTypography>
          <div style={{ cursor: "pointer" }} onClick={handleCloseGeneralDialog}>
            <CloseIcon sx={{ fontSize: "12px", color: "#827e7e" }} />
          </div>
        </div>
        <ListTable
          dataList={secondaryTable}
          Header={["ID", "Name", "District", "City/Town/Village", "Pincode"]}
          tableData={[
            { type: ["TEXT"], name: "id" },
            { type: ["TEXT"], name: "name" },
            { type: ["TEXT"], name: "district" },
            { type: ["TEXT"], name: "city" },
            { type: ["TEXT"], name: "pincode" },
          ]}
        />
      </div>
    );
  };
  return (
    <div className={classes.root}>
      {openPartner ? (
        <NewPartners handleBack={handleBack} />
      ) : viewPartner ? (
        <ViewPartner handleBack={handleBack} row={rowData} />
      ) : (
        <>
          <>
            <div
              className={classes.alignStraightBetween}
              style={{ marginBottom: "4px" }}
            >
              <HeadingTypography>Partners</HeadingTypography>
              <div style={{ padding: "0px 0px 5px 0px" }}>
                <ContinueButton onClick={handleNewPartner}>
                  New Partner
                </ContinueButton>
              </div>
            </div>
            <ListTable
              dataList={tableData}
              handlePartnerStore={handlePartnerStore}
              handleStatusClick={handleStatusClick}
              isLoading={loading}
              handleLoadmore={handleLoadmore}
              hasMore={hasMore}
              Header={[
                "Type",
                "ID",
                "Name",
                "Mobile Number",
                "Email ID",
                "",
                "",
              ]}
              tableData={[
                { type: ["TEXT"], name: "type" },
                { type: ["TEXT"], name: "id" },
                { type: ["TEXT"], name: "name" },
                { type: ["TEXT"], name: "mobile" },
                { type: ["TEXT"], name: "email" },
                { type: ["PARTNER_STORE"], name: "" },
                { type: ["VIEW_NEW"], name: "" },
              ]}
            />
          </>
          <GeneralDataModal
            generalDialog={generalDialog}
            paperWidth={classes.paperWidth}
            bodyComp={<BodyComp />}
          />
        </>
      )}
    </div>
  );
};

export default Partners;
