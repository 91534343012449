import React, { useState, useEffect } from 'react'
import {
    Grid,
    Box,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Divider
} from "@mui/material";
import { BackIcon, HeadingTypography } from '../../../components'
import _ from "lodash";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { FrameIcon } from "../../../components/svg/FrameIcon";
import { SmsIcon } from "../../../components/svg/SmsIcon";
import { CallIcon } from "../../../components/svg/CallIcon";
import CheckoutBox from './Checkout/CheckoutBox'
import { useStyles } from "./styles";
import ViewDetail from "./Checkout/ViewDetail"
import {
    getStoreOrderDetails
} from "../../../app/reducers/storeOrderSlice";

export default function StoreOrderDetails(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    let params = useParams();
    const history = useNavigate();

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        td: {
            border: 0,
        },
    }));
    const generateStatusBullet = (data) => {
        switch (data) {
            case "Delivered": {
                return "#b7e6cf";
            }
            case "Shipped": {
                return "#d9ebff";
            }
            case "QC": {
                return "#d1efef";
            }
            case "QC Failed": {
                return "#ffb1b2";
            }
            case "Created": {
                return "#d1efef";
            }
            case "Intend Created": {
                return "#ffeacd";
            }
            case "Packed": {
                return "#d1efef";
            }
            case "Completed": {
                return "#d9ebff";
            }
            case "QC Passed": {
                return "#b7e6cf";
            }
            default: {
                return "#d1efef";
            }
        }
    };
    const statusColor = (data) => {
        switch (data) {
            case "Delivered": {
                return "#1daf67";
            }
            case "Shipped": {
                return "#0077ff";
            }
            case "Packed": {
                return "#03a5a5";
            }
            case "QC": {
                return "#03a5a5";
            }
            case "QC Failed": {
                return "#ff0000";
            }
            case "Created": {
                return "#03a5a5";
            }
            case "Intend Created": {
                return "#ffb95a";
            }
            case "Completed": {
                return "#0077ff";
            }
            case "QC Passed": {
                return "#1daf67";
            }
            default: {
                return "#03a5a5";
            }
        }
    };

    const storeOrderDetails = useSelector(
        (state) => state.storeOrderReducer.singleOrderDetails
    );



    useEffect(() => {
        dispatch(getStoreOrderDetails({
            orderId: params.orderId
        }));
    }, [])
    const [viewDetail, setViewDetail] = useState(false);
    const [viewDetailData, setViewDetailData] = useState({});


    const handleViewDetails = (event, product) => {
        setViewDetail(true);
        setViewDetailData(product);
    };

    const handleViewClose = () => {
        setViewDetail(false);
    };

    return (
        <div className={classes.root}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    aligntItems: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        aligntItems: "center",
                    }}
                >
                    <div onClick={() => history("/store-orders")}>
                        <BackIcon />
                    </div>
                    <HeadingTypography
                        color={"#03a5a5"}
                        size={"18px"}
                        margin={"0px 0px 16px"}
                        sx={{ padding: "0px 0px 0px 10px" }}
                    >
                        ORDER DETAILS
                    </HeadingTypography>
                </div>
            </div>
            <Grid container sx={{ pb: "70px" }}>
                <Grid item xs={12} md={8} lg={8}>
                    <CheckoutBox header="Consumer Details">
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                p: "10px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    paddingRight: "25px",
                                }}
                            >
                                <FrameIcon />
                                <Typography sx={{ fontWeight: 500 }}>
                                    {storeOrderDetails?.customerDetails?.name}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    paddingRight: "25px",
                                }}
                            >
                                <CallIcon />
                                <Typography sx={{ fontWeight: 500 }}>
                                    {storeOrderDetails?.customerDetails?.phoneNumber}
                                </Typography>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    paddingRight: "25px",
                                }}
                            >
                                <SmsIcon />
                                <Typography sx={{ fontWeight: 500 }}>
                                    {storeOrderDetails?.customerDetails?.emailId}
                                </Typography>
                            </div>
                        </Box>
                    </CheckoutBox>
                    <CheckoutBox header="Product Summary">
                        {storeOrderDetails?.products?.map((product) => (
                            <>
                                <TableContainer component={Box}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 650 }} width={"400px"}>
                                                    Product Description
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: 650 }} align="left">
                                                    Unit Cost(&#8377;)
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: 650 }} align="left">
                                                    Qty
                                                </TableCell>
                                                <TableCell sx={{ fontWeight: 650 }} align="left">
                                                    Total Cost(&#8377;)
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        {
                                            <TableBody>
                                                <StyledTableRow>
                                                    <TableCell sx={{ pt: "10px", pb: "0px" }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    height: "24px",
                                                                    backgroundColor: generateStatusBullet(product.status),
                                                                    display: "inline-block",
                                                                    padding: "4px 3px 4px 0px",
                                                                    borderRadius: "4px",
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        color: statusColor("QC Passed"),
                                                                        fontSize: "11px",
                                                                        fontWeight: 500,
                                                                        // lineHeight: 1.14,
                                                                        letterSpacing: "0.42px",
                                                                        textAlign: "center",
                                                                        padding: "2px 2px 0px 4px ",
                                                                    }}
                                                                >
                                                                    {product.status}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </StyledTableRow>
                                                <StyledTableRow key="123">
                                                    <TableCell align="left">{product.name}</TableCell>
                                                    <TableCell align="left">
                                                        {parseInt(product.price).toFixed(2)}
                                                    </TableCell>
                                                    <TableCell align="left">1</TableCell>
                                                    <TableCell align="left">
                                                        {(product.price * 1).toFixed(2)}
                                                    </TableCell>
                                                </StyledTableRow>
                                                {!_.isEmpty(product.lensItems) ? (
                                                    <StyledTableRow key={product.lensItems.lensId}>
                                                        <TableCell align="left">
                                                            {product.lensItems.lensCoating}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {product.lensItems.price}
                                                        </TableCell>
                                                        <TableCell align="left">1</TableCell>
                                                        <TableCell align="left">
                                                            {(product.lensItems.price * 1).toFixed(
                                                                2
                                                            )}
                                                        </TableCell>
                                                    </StyledTableRow>
                                                ) : (
                                                    ""
                                                )}
                                            </TableBody>
                                        }
                                    </Table>
                                </TableContainer>
                                <Divider />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: "20px",
                                            textAlign: "center",
                                            color: "#ff6f00",
                                        }}
                                        onClick={(event) => handleViewDetails(event, product)}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "10pt",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                            }}
                                        >
                                            View Prescription &amp; Frame Size
                                        </Typography>
                                    </div>
                                </div>
                                <Divider />
                            </>
                        ))}
                    </CheckoutBox>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                    <Grid item>
                        <CheckoutBox header="Payment Summary">
                            <div style={{ padding: "30px 20px" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "20px",
                                    }}
                                >
                                    <Typography>Subtotal</Typography>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Typography>
                                            &#8377; {parseInt(storeOrderDetails?.subTotal).toFixed(2)}
                                        </Typography>
                                    </div>
                                </div>
                                {storeOrderDetails?.couponValue ? (
                                    <>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                marginBottom: "0px",
                                            }}
                                        >
                                            <Typography>Coupon amount</Typography>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Typography>
                                                    &#8377; {parseInt(storeOrderDetails?.couponValue).toFixed(2)}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                marginBottom: "20px",
                                            }}
                                        >
                                            <Typography sx={{ fontSize: "0.8rem" }}>
                                                ({storeOrderDetails?.couponCode})
                                            </Typography>
                                        </div>{" "}
                                    </>
                                ) : (
                                    " "
                                )}
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "20px",
                                    }}
                                >
                                    <Typography>GST</Typography>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Typography>
                                            &#8377; {storeOrderDetails?.totalGst}
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "0px",
                                    }}
                                >
                                    <Typography>Amount Paid </Typography>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Typography>
                                            &#8377; {parseInt(storeOrderDetails?.paidAmount).toFixed(2)} ({
                                                storeOrderDetails?.payment && _.lowerCase(storeOrderDetails?.payment[0]?.mode)
                                            })
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "20px",
                                    }}
                                >
                                    <Typography sx={{ fontSize: "0.8rem" }}>
                                        {storeOrderDetails?.payment && storeOrderDetails?.payment[0]?.comments}
                                    </Typography>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography>Total Amount</Typography>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Typography>&#8377; {storeOrderDetails?.inclGst}</Typography>
                                    </div>
                                </div>
                            </div>
                            <Divider />
                            <div style={{ padding: "30px 20px" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography>Balance</Typography>
                                    {
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Typography>
                                                &#8377; {(storeOrderDetails?.inclGst - storeOrderDetails?.paidAmount).toFixed(2)}
                                            </Typography>
                                        </div>
                                    }
                                </div>
                            </div>
                        </CheckoutBox>
                    </Grid>
                </Grid>
            </Grid>
            <ViewDetail
                open={viewDetail}
                handleViewClose={handleViewClose}
                data={viewDetailData}
            />
        </div>
    )
}
